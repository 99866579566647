import React, {useState} from 'react';
import firebase from '../Firebase/firebase.js';
import Popup from 'reactjs-popup';

import AboutPageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
//import AboutSpark from '../../assets/images/image-2.png';
//import AboutGivingBack from '../../assets/images/giving-back-image.png';
import CityImage from '../../assets/images/location-pin.png';
import CodingRobotics from '../../assets/images/landing-page-coding-robotics.png';
import WhyCodewiz from '../../assets/images/landing-page-why-codewiz.png';

import FranchiseHeaderImg from '../../assets/images/FranchiseeHomePage.jpg';
//import MidBkgImage from '../../assets/images/landing-page-mid bkg.png';

import Button from '../../components/button/button.component';
import Review from '../../components/review/review.component';
import TeamMember from '../../components/team-member/team-member.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
//import TeamSlider from '../../components/slick-slider/teams-slider.component';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';
import JackImg from '../../assets/images/headshot3.png';
import AvashaImg from '../../assets/images/headshot4.png';
import LearningFramework from '../../assets/images/codes-chart-transparent.png';

import GoogleMapWestfordComponent from '../../components/google-map/google-map.component-westford';
import GoogleMapReadingComponent from '../../components/google-map/google-map.component-reading';
import GoogleMapWestboroughComponent from '../../components/google-map/google-map.component-westborough';
import GoogleMapArlingtonComponent from '../../components/google-map/google-map.component-arlington';
import GoogleMapJerseyComponent from '../../components/google-map/google-map.component-jersey';
import GoogleMapMedfordComponent from '../../components/google-map/google-map.component-medford';
import GoogleMapNashuaComponent from '../../components/google-map/google-map.component-nashua';

import GoogleMapNeedhamComponent from '../../components/google-map/google-map.component-needham';
import GoogleMapLawrencevilleComponent from '../google-map/google-map.component-lawrenceville';
import GoogleMapPlanoComponent from '../../components/google-map/google-map.component-plano';
import GoogleMapStjohnsComponent from '../../components/google-map/google-map.component-stjohns';
import GoogleMapCypressComponent from '../google-map/google-map.component-cypress.jsx';
import GoogleMapColleyvilleComponent from '../google-map/google-map.component-colleyville.jsx';
import GoogleMapRutherfordComponent from '../google-map/google-map.component-rutherford.jsx';
import GoogleMapDurhamComponent from '../google-map/google-map.component-durham.jsx';
import GoogleMapFishhawkComponent from '../google-map/google-map.component-fishhawk.jsx';
import GoogleMapKatyComponent from '../google-map/google-map.component-katy.jsx';
import GoogleMapKellereastComponent from '../google-map/google-map.component-kellereast.jsx';
import GoogleMapQuincyComponent from '../google-map/google-map.component-quincy.jsx';

import './franchisee.styles.scss';


class Franchisee extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.name,
            data: {},
            team: props.team,
            map: props.name + "-map",
            brochure: "https://getform.io/f/c7aeb3d8-3132-4a1d-8241-6142b5b305e8",
            locationemail: props.name + "@thecodewiz.com",
            zoho: props.owner,
            isOpen : false
            // email: "esther@thecodewiz.com"
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal () {
        this.setState({isOpen: true})
    }

    closeModal () {
        this.setState({isOpen: false})
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        // const [open, setOpen] = useState(false);
        // const closeModal = () => setOpen(false);
        const formUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e"; //grandOpeningUrl
        const teamsArr = [];
        const teams = this.state.team;
        let renderdaysList = "";
        if(teams.length > 0){
            for (let i = 0; i < teams.length; i++) {
                teamsArr.push(<TeamMember id={i} key={i} name={teams[i].name} picture={teams[i].picture} title={teams[i].title}/>);
            }
        }
        const daysOpenList = this.state.data.daysopen;
        
        if(!daysOpenList){
            renderdaysList =""
        }else {
            renderdaysList = daysOpenList.map((item, index) => 
                               <div key={index}>{item}</div>
                             );
        }

        const map = [];
        if(this.state.location === "westford"){
            map.push(<GoogleMapWestfordComponent/>);
        } else if(this.state.location === "westborough"){
            map.push(<GoogleMapWestboroughComponent/>);
        } else if(this.state.location === "reading"){
            map.push(<GoogleMapReadingComponent/>);
        }else if(this.state.location === "arlington"){
            map.push(<GoogleMapArlingtonComponent/>);
        }else if(this.state.location === "jersey"){
            map.push(<GoogleMapJerseyComponent/>);
        }else if(this.state.location === "medford"){
            map.push(<GoogleMapMedfordComponent/>);
        }else if(this.state.location === "nashua"){
            map.push(<GoogleMapNashuaComponent/>);
        }else if(this.state.location === "needham"){
            map.push(<GoogleMapNeedhamComponent/>);
        }else if(this.state.location === "lawrenceville"){
            map.push(<GoogleMapLawrencevilleComponent/>);
        }else if(this.state.location === "plano"){
            map.push(<GoogleMapPlanoComponent/>);
        }else if(this.state.location === "stjohns"){
            map.push(<GoogleMapStjohnsComponent/>);
        }else if(this.state.location === "cypress"){
            map.push(<GoogleMapCypressComponent/>);
        }else if(this.state.location === "colleyville"){
            map.push(<GoogleMapColleyvilleComponent/>);
        }else if(this.state.location === "rutherford"){
            map.push(<GoogleMapRutherfordComponent/>);
        }else if(this.state.location === "durham"){
            map.push(<GoogleMapDurhamComponent/>);
        }else if(this.state.location === "fishhawk"){
            map.push(<GoogleMapFishhawkComponent/>);
        }else if(this.state.location === "katy"){
            map.push(<GoogleMapKatyComponent/>);
        }else if(this.state.location === "kellereast"){
            map.push(<GoogleMapKellereastComponent/>);
        }else if(this.state.location === "quincy"){
            map.push(<GoogleMapQuincyComponent/>);
        }

// console.log("Hours", this.state.data.friday)

        return (
            <div className="subsite franchisee-page" id={this.state.location}> 
                <div className="coming-soon-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-12 col-lg-6 center">
                            {/* <img className='coming-soon-image' aria-hidden="true" src={FoundingMemberHeaderImg} alt="Founding member header picture"/> */}

                            <h2 className="center summer-camp-video"><iframe src={this.state.data.youtubevidwelcome} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></h2>
                            
                            <br />
                            <br />
                        </div>
                        <div className="top-header col-sm-12 col-md-12 col-lg-5 "> 
                            <div className="text">
                                {/* <h2 className="header-title cw-dark-blue bungee">{this.state.data.headertext}</h2>  */}
                                <h2 className="header-title cw-dark-blue bungee">UNLOCK YOUR CHILD'S INNER GENIUS</h2>
                                <h3 className="header-text cw-dark-blue map-pin"><span><img aria-hidden="true" src={CityImage} alt="franchise city"/></span>{this.state.data.citystate}</h3> 
                                
                                <div className="landing-page-buttons">
                                    <Button className="center" color="blue" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> 
                                    <Button color="orange" link={this.state.data.parentportal} size="medium">VIEW CLASSES/CAMPS</Button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                </div>
                {/* As seen on section */}
                <br />
                <div className="as-seen-on">
                    <div className="row">
                        <div className="col-md-3 as-seen-on-div">
                            <h2 className="center cw-dark-blue">AS SEEN ON</h2>
                        </div>
                        <div className="col-md-9 as-seen-on-div">
                            <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                        </div> 
                    </div>   
                </div>
                {/* End of as seen on */}
                <div className="body-wrapper cw-dark-blue">
                    <div className="container">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row">
                            <div className="col-md-12 col-lg-6 code-create-grow">
                                <h3 className="bungee">code. create. grow.</h3>
                                <p className="">At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem-solving. Best of all, anyone can learn to code—no matter their age, tech proficiency, or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                                
                                <h3 className="bungee whycodewiztitle">why code wiz?</h3>
                                <p className=""><b>project-driven, student-steered learning </b> <br/>Children create by completing projects rather than lessons or levels<br/><br/>
                                
                                <b>really cool rewards </b><br/>We reward learning with Code Wiz gear and other awesome prizes<br/><br/>

                                <b>community connectivity </b><br/>Students can check their work online and get inspired by peer projects<br/><br/>

                                <b>progress at your fingertips </b><br/>Stay connected to your child's success with automated progress report emails<br/><br/>

                                <b>free, no-strings intro class</b><br/> See how learning to code can change your child's life-all classes are offered in person and online!</p>
                                <br/>
                                <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                            </div>
                            <div className="col-md-12 col-lg-6 form-white">
                                <h3 className="bungee cw-dark-blue">download our class schedule</h3>
                                <p></p>
                                
                                <form id="cw-contact-form" className="contact-form cw-dark-blue" action={this.state.brochure} method="POST">
                                    <p className="cw-dark-blue">Required fields are indicated with "*".</p>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                                            <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="required-label" htmlFor="inputLast">Last Name *</label>
                                            <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label className="required-label" htmlFor="inputEmail">Email *</label>
                                            <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="required-label" htmlFor="inputPhone">Phone *</label>
                                            <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                                            <select name="mode" className="form-control" id="inputMode" required>
                                                <option value="inperson">In-Person Classes</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="hidden" id="location" name="location" value={this.state.location}></input>
                                            <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                                            <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                                            <label className="form-check-label tiny-print" htmlFor="gridCheck">
                                            {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                                            <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                                            <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* <button type="submit">Send</button> */}
                                    
                                    <button type="submit" className="btn orange-button" target="_blank">SEND</button>
                                </form>

                            </div>
                        </div>
                        <div className="empty-space form-empty-space"></div>
                        <div className="row row-reverse coding-robotics">
                            <div className="col-md-12 col-lg-6 ">
                                <h3 className="bungee">coding and robotics classes in {this.state.data.citystate}</h3>
                                <p>Give your child the chance to learn life-shaping skills with fun, flexible programs offered online or at our {this.state.data.citystate} location.</p>
                                <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <img aria-hidden="true" className="midImg" src={CodingRobotics} alt="coding robotics"/>
                            </div>
                        </div>
                        <div className="empty-space-half"></div>
                        <div className="row learning-framework">
                            <div className="col-md-12 col-lg-6 center">
                                <img aria-hidden="true" src={LearningFramework} alt="learning framework"/>
                            </div>
                            <div className="col-md-12 col-lg-6 ">
                                <h4 className="bungee">Our Proprietary Educational Model</h4>
                                <p>Embark on an exciting coding journey with the CODES Model at Code Wiz—an exclusive and proprietary framework that sparks your child's fascination with coding and technology. Coding and Robotics for Kids Ages 5 - 15.</p>
                                <ul className=''>
                                    <li><b className='bungee'>Contextualize:</b> We show your child how coding connects to their interests and favorite games, making it relevant to the real world.</li>
                                    <li><b className='bungee'>Orient:</b> Step-by-step guidance helps them build essential coding skills with creativity and mastery.</li>
                                    <li><b className='bungee'>Develop:</b> They actively practice coding through milestones, enhancing problem-solving abilities and critical thinking.</li>
                                    <li><b className='bungee'>Evaluate:</b> We evaluate and boost your child's coding skills with tailored feedback, ensuring continuous improvement.</li>
                                    <li><b className='bungee'>Synthesize:</b> Skills are integrated across domains, fostering innovation and diverse project creation.</li>
                                </ul>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="membership-div light-blue-bkg ">
                    <div className='row'>
                        <div className="col-md-12 educational-model-heading">
                            <p><b></b></p>
                            <h3 className="bungee center dark-blue">Membership options</h3>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-sm-12 membership-option'>
                                    {/* <h5 className='bungee'>lite</h5> */}
                                    <h4 className='bungee'>Lite: 1 Hour/Week</h4>
                                    {/* <p><b>The bridge between basics and brilliance</b></p> */}
                                    <ol className='membership-benefits'>
                                        <li>1 Make up token per month</li>
                                        <li>Monthly progress reports</li>
                                        <li>Student Binder - WizBucks, Milestones, Learning Reflections and Badges</li>
                                        <li>Quarterly Educational articles & webinars</li>
                                        <li><b>BONUS</b> - Member Only Camp Discount</li>
                                        <li><b>BONUS</b> - 1 FREE Family Night In</li>
                                    </ol>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 membership-option'>
                                    <h4 className='bungee'>Core: 2 hours/week</h4>
                                    {/* <h4>$297/month</h4> */}
                                    {/* <p><b>The comprehensive package for serious young tech enthusiasts:</b></p> */}
                                    <ol className='membership-benefits'>
                                        <li>2 Make up tokens per month</li>
                                        <li>Monthly Progress Reports</li>
                                        <li>Student Binder - WizBucks, Milestones, Learning Reflections and Badges</li>
                                        <li>Quarterly Educational articles & webinars</li>
                                        <li><b>BONUS</b> - Member Only Camp Discount</li>
                                        <li><b>BONUS</b> - 1 FREE Family Night In</li>
                                    </ol>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 membership-option'>
                                    <h4 className='bungee'>Premium 1:1 Class</h4>
                                    {/* <h4>$447/month</h4> */}
                                    {/* <p><b>For the child who dreams big and parents who support even bigger dreams.</b></p> */}
                                    <ol className='membership-benefits'>
                                        <li>Benefit from undivided focus in our elite 60 minute, 1:1 sessions. Individualized learning</li>
                                        <li>1 Make up token per month</li>
                                        <li>Daily Progress Reports</li>
                                        <li>Student Binder - WizBucks, Milestones, Learning Reflections and Badges</li>
                                        <li>Quarterly Educational articles & webinars</li>
                                        <li><b>BONUS</b> - Member Only Camp Discount</li>
                                        <li><b>BONUS</b> - 1 FREE Family Night In</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='center'><br/><Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button></div>
                    <div className="empty-space-half bw-0"></div> 
                </div>
                <div className="col-md-12 wizza-heading center dark-blue">
                    <h3 className="bungee"> From Wizling to Wizza: Inspiring Success Stories!</h3>
                </div>
                <div className="review-container-dotted wizzas-div">
                    <div className="empty-space-half bw-0"></div> 
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12 box-shadow-div wizza'>
                            <div className='wizza-img'><img src={JackImg} /></div>
                            <div className=''>
                                <h5 className='bungee'><b>Jack Whitman</b></h5>
                                <h5><span className='cw-orange'>Courses I Teach:</span> Scratch, Roblox, Minecraft, Python, FLL Jr, FLL Competitive and Non-Competitive</h5>
                                <p>In mid-2017, I saw a Sign for the grand opening of a new business called Code Wiz. Thoroughly fascinated and captivated by programming as a kid, I was there on the very first day. For three years, I attended Code Wiz camps and classes year-round, even winning one of their Codeathon coding competitions. I always joked about becoming an employee at Code Wiz the day I turned 14... and that's exactly what happened!</p>
                                <p>During the lockdown, I made short inforrnational technology videos on Code Wiz's YouTube channel, and I have been working as a coach for about two years. Helping kids build, collaborate, and solve problems leads them to their goals and fulfills one of ours: empowering creativity through technology.</p>
                                
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12 box-shadow-div wizza mid'>
                            <div className='wizza-img'><img src={AvashaImg} /></div>
                            <div className=''>
                                <h5 className='bungee'><b>Avashya Singh</b></h5>
                                <h5><span className='cw-orange'>Courses I Teach:</span> Minecraft, Roblox</h5>
                                <p>I was a student at Code Wiz from 2015-2018. I enjoyed all of the classes and the projects I got to work on throughout those 3 years and learned a lot about coding and computer science. I went back to Code Wiz in 2022 as a volunteer as I needed some more hours for school and I wanted to see if they needed some help.</p>
                                <p>After doing some volunteering, I asked if they were looking to hire and ended up getting a job! Now I teach Minecraft Coding and Roblox Game Design to new Wizlings!</p>
                                
                            </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-6 col-sm-12 box-shadow-div wizza'>
                            <img src={CharlotteImg} />
                            <div className=''>
                                <h5><span className='cw-orange'>Courses I Teach:</span> Scratch, Minecraft, Roblox, Python</h5>
                                <p>When I was 9 through 11 years of age, I attended Code Wiz. I was blown away by how passionate and diverse the staffing community was, and found the courses to be very engaging. I felt very included, and Code Wiz kick-started my passion for computer science.</p>
                                <p>Last summer I wanted to give back by inspiring other children and contnuing to build the supportive and accepting experience I had. In the future, I see myself working in a tech position and continuing to promote inclusion and diversity.</p>
                                <h5 className='bungee'><b>Charlotte Lokere</b></h5>
                            </div>
                        </div> */}
                    </div>
                    <div className="empty-space-half bw-0"></div> 
                </div>
                <div className="container contact-info-contact-page">
                    <div className="mid-layer">
                        <h3 className="bungee center cw-dark-blue">location</h3>
                        <div className="row">
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>ADDRESS:</h5>
                                <p>{this.state.data.addressstreet}<br/> {this.state.data.city}, {this.state.data.state} {this.state.data.zip}</p>
                                <p>{this.state.data.addressDetails}</p>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-phone fa-5x cw-orange"></i></div>
                                <h5>CONTACT:</h5>
                                <p><a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a> <br/>
                                <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a></p>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>HOURS:</h5>
                                <p> 
                                    {renderdaysList} 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 center"><Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button></div>

                {/* <!-- Location Map --> */}
                {map}

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 center" style={{display: 'none'}}>
                            <h3 className="bungee cw-orange">reviews</h3>
                            <h4 className="bungee cw-dark-blue">Ecstatic students <br/> happy parents</h4>
                            <section className="pt-5 pb-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6 text-right">
                                            <a className="btn btn-primary mb-3 mr-1" href="#parentreviews" role="button" data-slide="prev">
                                                <i className="fa fa-arrow-left"></i>
                                            </a>
                                            <a className="btn btn-primary mb-3 " href="#parentreviews" role="button" data-slide="next">
                                                <i className="fa fa-arrow-right"></i>
                                            </a>
                                        </div>
                                        <div className="col-12">
                                            <div id="parentreviews" className="carousel slide" data-ride="carousel">
                                
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <div className="row">
                                                            <Review review="text text text 1" author="Satisfied Parent"/>
                                                            <Review review="text text text 2" author="Satisfied Parent 2"/>
                                                        </div>
                                                    </div>
                                                    <div className="carousel-item">
                                                        <div className="row">
                                                            <Review review="text text text 3" author="Satisfied Parent 3"/>
                                                            <Review review="text text text 4" author="Satisfied Parent 4"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h3 className="bungee cw-dark-blue center">Our Team</h3>
                    <h5 className="center">Meet the {this.state.data.city} Team</h5>
                    <div className="row team-row">
                        <section className="pt-5 pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <!-- <h3 class="mb-3">Carousel cards title </h3> --> */}
                                    </div>
                                    {/* <div className="col-6 text-right">
                                        <a className="btn btn-primary mb-3 mr-1" href="#teamCarousel" role="button" data-slide="prev">
                                            <i className="fa fa-arrow-left"></i>
                                        </a>
                                        <a className="btn btn-primary mb-3 " href="#teamCarousel" role="button" data-slide="next">
                                            <i className="fa fa-arrow-right"></i>
                                        </a>
                                    </div> */}
                                    <div className="col-12">
                                        {/* <div id="teamCarousel" className="carousel slide mobile-version" data-ride="carousel"> */}
                                        <div id="teamCarousel" className="carousel slide" data-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="row">
                                                        {teamsArr}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        {/* <div className="desktop-version">
                                            <TeamSlider teams={this.state.team}/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {/* <Container>
                    <Link href="#"
                        tooltip="Email Us"
                        icon="fa fa-envelope-o" />
                    <Link href="#"
                        tooltip="Try A free class"
                        icon="fa fa-paper-plane-o" />
                        
                    <Button
                        color="blue"
                        size="small"
                        tooltip="The big plus button!"
                        icon="fas fa-plus"
                        rotate={true}
                        onClick={() => alert('FAB Rocks!')}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                </Container> */}
                {/* <a href={this.state.data.tryfreeclass} class="float">
                    <i class="fa fa-plus my-float"></i>
                    TRY A FREE CLASS
                </a> */}
                <FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email} facebookId={this.state.data.facebookId}/>
                
            </div>
        );
    }
}

export default Franchisee
