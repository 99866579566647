import React from 'react';
import {Switch, Route, Redirect, Link} from 'react-router-dom';

import './App.css';

import HomePage from './pages/homepage/homepage.component';
import WestfordPage from './pages/franchisees/westford/westford.component';
import WestfordGSPage from './pages/franchisees/westford/westford-new.component';
import WestfordGSPCoursesPage from './pages/franchisees/westford/westford-gso-courses.component';

import WestfordAboutPage from './pages/franchisees/westford/about-us';
import WestfordBirthdayPartiesPage from './pages/franchisees/westford/birthday-parties';
//import WestfordCampsPage from './pages/westford/camps';
import WestfordCompetitionsPage from './pages/franchisees/westford/competitions';
import WestfordContactPage from './pages/franchisees/westford/contact-us';
import WestfordFaqsPage from './pages/franchisees/westford/faqs';
import WestfordFindACoursePage from './pages/franchisees/westford/classes';
import WestfordProgramsPage from './pages/franchisees/westford/programs';
import WestfordGirlsScoutPage from './pages/franchisees/westford/girls-scout';
import WestfordTechnovationPage from './pages/franchisees/westford/technovation';
// import WestfordFllPage from './pages/westford/fll';
import WestfordLocationsPage from './pages/franchisees/westford/locations';
import WestfordAcslPage from './pages/franchisees/westford/acsl-competition';
import WestfordVirtualRoboticsPage from './pages/franchisees/westford/virtual-robotics';
import WestfordWelcomePage from './pages/franchisees/westford/welcome-message';
import WestfordSummerPage from './pages/franchisees/westford/summer';
// import WestfordRobloxPage from './pages/westford/roblox';
import WestfordOnlineFallCodingPage from './pages/franchisees/westford/online-fall-coding';
//import WestfordOnlineCodingPage from './pages/westford/online-coding';
import WestfordLegoLeaguePage from './pages/franchisees/westford/lego-league';
import WestfordFallClassesPage from './pages/franchisees/westford/fall-classes';
import WestfordBlogPage from './pages/franchisees/westford/blog';
import WestfordPrivacyPolicyPage from './pages/franchisees/westford/privacy-policy';
import WestfordThankYouPage from './pages/franchisees/westford/thank-you';
import WestfordTutorDoctorPage from './pages/franchisees/westford/tutordoctor';
import WestfordInPersonCamp from './pages/franchisees/westford/in-person-brochure';
import WestfordInPersonClasses from './pages/franchisees/westford/in-person-classes';
import WestfordInPersonClassesGoogleAds from './pages/franchisees/westford/in-person-classes-google-ads';
// import WestfordPricingPage from './pages/franchisees/westford/pricing';

import RentASpacePage from './pages/rent-a-space';


// import JerseyPage from './pages/franchisees/jerseycity/jerseycity.component';

// import JerseyAboutPage from './pages/franchisees/jerseycity/about-us';
// import JerseyBirthdayPartiesPage from './pages/franchisees/jerseycity/birthday-parties';
// //import JerseyCampsPage from './pages/jerseycity/camps';
// import JerseyCompetitionsPage from './pages/franchisees/jerseycity/competitions';
// import JerseyContactPage from './pages/franchisees/jerseycity/contact-us';
// import JerseyFaqsPage from './pages/franchisees/jerseycity/faqs';
// import JerseyFindACoursePage from './pages/franchisees/jerseycity/classes';
// import JerseyProgramsPage from './pages/franchisees/jerseycity/programs';
// import JerseyGirlsScoutPage from './pages/franchisees/jerseycity/girls-scout';
// import JerseyTechnovationPage from './pages/franchisees/jerseycity/technovation';
// // import JerseyFllPage from './pages/jerseycity/fll';
// import JerseyLocationsPage from './pages/franchisees/jerseycity/locations';
// import JerseyAcslPage from './pages/franchisees/jerseycity/acsl-competition';
// import JerseyVirtualRoboticsPage from './pages/franchisees/jerseycity/virtual-robotics';
// import JerseyWelcomePage from './pages/franchisees/jerseycity/welcome-message';
// import JerseySummerPage from './pages/franchisees/jerseycity/summer';
// // import JerseyRobloxPage from './pages/jerseycity/roblox';
// import JerseyOnlineFallCodingPage from './pages/franchisees/jerseycity/online-fall-coding';
// //import JerseyOnlineCodingPage from './pages/jerseycity/online-coding';
// import JerseyLegoLeaguePage from './pages/franchisees/jerseycity/lego-league';
// import JerseyFallClassesPage from './pages/franchisees/jerseycity/fall-classes';
// import JerseyBlogPage from './pages/franchisees/jerseycity/blog';
// import JerseyPrivacyPolicyPage from './pages/franchisees/jerseycity/privacy-policy';
// import JerseyThankYouPage from './pages/franchisees/jerseycity/thank-you';
// import JerseyInPersonClasses from './pages/franchisees/jerseycity/in-person-classes';
// // import JerseyPricingPage from './pages/franchisees/jerseycity/pricing';

import ReadingPage from './pages/franchisees/reading/reading.component';

import ReadingAboutPage from './pages/franchisees/reading/about-us';
import ReadingBirthdayPartiesPage from './pages/franchisees/reading/birthday-parties';
//import ReadingCampsPage from './pages/reading/camps';
import ReadingCompetitionsPage from './pages/franchisees/reading/competitions';
import ReadingContactPage from './pages/franchisees/reading/contact-us';
import ReadingFaqsPage from './pages/franchisees/reading/faqs';
import ReadingFindACoursePage from './pages/franchisees/reading/classes';
import ReadingProgramsPage from './pages/franchisees/reading/programs';
import ReadingGirlsScoutPage from './pages/franchisees/reading/girls-scout';
import ReadingTechnovationPage from './pages/franchisees/reading/technovation';
// import ReadingFllPage from './pages/reading/fll';
import ReadingLocationsPage from './pages/franchisees/reading/locations';
import ReadingAcslPage from './pages/franchisees/reading/acsl-competition';
import ReadingVirtualRoboticsPage from './pages/franchisees/reading/virtual-robotics';
import ReadingWelcomePage from './pages/franchisees/reading/welcome-message';
import ReadingSummerPage from './pages/franchisees/reading/summer';
// import ReadingRobloxPage from './pages/reading/roblox';
import ReadingOnlineFallCodingPage from './pages/franchisees/reading/online-fall-coding';
//import ReadingOnlineCodingPage from './pages/reading/online-coding';
import ReadingLegoLeaguePage from './pages/franchisees/reading/lego-league';
import ReadingFallClassesPage from './pages/franchisees/reading/fall-classes';
import ReadingBlogPage from './pages/franchisees/reading/blog';
import ReadingPrivacyPolicyPage from './pages/franchisees/reading/privacy-policy';
import ReadingThankYouPage from './pages/franchisees/reading/thank-you';
import ReadingTutorDoctorPage from './pages/franchisees/reading/tutordoctor';
import ReadingInPersonClasses from './pages/franchisees/reading/in-person-classes';
// import ReadingPricingPage from './pages/franchisees/reading/pricing';

import NashuaPage from './pages/franchisees/nashua/nashua.component';

import NashuaAboutPage from './pages/franchisees/nashua/about-us';
import NashuaBirthdayPartiesPage from './pages/franchisees/nashua/birthday-parties';
//import NashuaCampsPage from './pages/nashua/camps';
import NashuaCompetitionsPage from './pages/franchisees/nashua/competitions';
import NashuaContactPage from './pages/franchisees/nashua/contact-us';
import NashuaFaqsPage from './pages/franchisees/nashua/faqs';
import NashuaFindACoursePage from './pages/franchisees/nashua/classes';
import NashuaProgramsPage from './pages/franchisees/nashua/programs';
import NashuaGirlsScoutPage from './pages/franchisees/nashua/girls-scout';
import NashuaTechnovationPage from './pages/franchisees/nashua/technovation';
// import NashuaFllPage from './pages/nashua/fll';
import NashuaLocationsPage from './pages/franchisees/nashua/locations';
import NashuaAcslPage from './pages/franchisees/nashua/acsl-competition';
import NashuaVirtualRoboticsPage from './pages/franchisees/nashua/virtual-robotics';
import NashuaWelcomePage from './pages/franchisees/nashua/welcome-message';
import NashuaSummerPage from './pages/franchisees/nashua/summer';
// import NashuaRobloxPage from './pages/nashua/roblox';
import NashuaOnlineFallCodingPage from './pages/franchisees/nashua/online-fall-coding';
//import NashuaOnlineCodingPage from './pages/nashua/online-coding';
import NashuaLegoLeaguePage from './pages/franchisees/nashua/lego-league';
import NashuaFallClassesPage from './pages/franchisees/nashua/fall-classes';
import NashuaBlogPage from './pages/franchisees/nashua/blog';
import NashuaPrivacyPolicyPage from './pages/franchisees/nashua/privacy-policy';
import NashuaThankYouPage from './pages/franchisees/nashua/thank-you';
import NashuaTutorDoctorPage from './pages/franchisees/nashua/tutordoctor';
import NashuaInPersonClasses from './pages/franchisees/nashua/in-person-classes';
import NashuaInPersonClassesGoogleAds from './pages/franchisees/nashua/in-person-classes-google-ads';
//import NashuaPricingPage from './pages/franchisees/nashua/pricing';


import ArlingtonPage from './pages/franchisees/arlington/arlington.component';

import ArlingtonAboutPage from './pages/franchisees/arlington/about-us';
import ArlingtonBirthdayPartiesPage from './pages/franchisees/arlington/birthday-parties';
//import ArlingtonCampsPage from './pages/arlington/camps';
import ArlingtonCompetitionsPage from './pages/franchisees/arlington/competitions';
import ArlingtonContactPage from './pages/franchisees/arlington/contact-us';
import ArlingtonFaqsPage from './pages/franchisees/arlington/faqs';
import ArlingtonFindACoursePage from './pages/franchisees/arlington/classes';
import ArlingtonProgramsPage from './pages/franchisees/arlington/programs';
import ArlingtonGirlsScoutPage from './pages/franchisees/arlington/girls-scout';
import ArlingtonTechnovationPage from './pages/franchisees/arlington/technovation';
// import ArlingtonFllPage from './pages/arlington/fll';
import ArlingtonLocationsPage from './pages/franchisees/arlington/locations';
import ArlingtonAcslPage from './pages/franchisees/arlington/acsl-competition';
import ArlingtonVirtualRoboticsPage from './pages/franchisees/arlington/virtual-robotics';
import ArlingtonWelcomePage from './pages/franchisees/arlington/welcome-message';
import ArlingtonSummerPage from './pages/franchisees/arlington/summer';
// import ArlingtonRobloxPage from './pages/arlington/roblox';
import ArlingtonOnlineFallCodingPage from './pages/franchisees/arlington/online-fall-coding';
//import ArlingtonOnlineCodingPage from './pages/arlington/online-coding';
import ArlingtonLegoLeaguePage from './pages/franchisees/arlington/lego-league';
import ArlingtonFallClassesPage from './pages/franchisees/arlington/fall-classes';
import ArlingtonBlogPage from './pages/franchisees/arlington/blog';
import ArlingtonPrivacyPolicyPage from './pages/franchisees/arlington/privacy-policy';
import ArlingtonThankYouPage from './pages/franchisees/arlington/thank-you';
import ArlingtonTutorDoctorPage from './pages/franchisees/arlington/tutordoctor';
import ArlingtonInPersonClasses from './pages/franchisees/arlington/in-person-classes';
import ArlingtonInPersonClassesGoogleAds from './pages/franchisees/arlington/in-person-classes-google-ads';
//import ArlingtonPricingPage from './pages/franchisees/arlington/pricing';

import WestboroughPage from './pages/franchisees/westborough/westborough.component';

import WestboroughAboutPage from './pages/franchisees/westborough/about-us';
import WestboroughBirthdayPartiesPage from './pages/franchisees/westborough/birthday-parties';
//import WestboroughCampsPage from './pages/westborough/camps';
import WestboroughCompetitionsPage from './pages/franchisees/westborough/competitions';
import WestboroughContactPage from './pages/franchisees/westborough/contact-us';
import WestboroughFaqsPage from './pages/franchisees/westborough/faqs';
import WestboroughFindACoursePage from './pages/franchisees/westborough/classes';
import WestboroughProgramsPage from './pages/franchisees/westborough/programs';
import WestboroughGirlsScoutPage from './pages/franchisees/westborough/girls-scout';
import WestboroughTechnovationPage from './pages/franchisees/westborough/technovation';
// import WestboroughFllPage from './pages/westborough/fll';
import WestboroughLocationsPage from './pages/franchisees/westborough/locations';
import WestboroughAcslPage from './pages/franchisees/westborough/acsl-competition';
import WestboroughVirtualRoboticsPage from './pages/franchisees/westborough/virtual-robotics';
import WestboroughWelcomePage from './pages/franchisees/westborough/welcome-message';
import WestboroughSummerPage from './pages/franchisees/westborough/summer';
// import WestboroughRobloxPage from './pages/westborough/roblox';
import WestboroughOnlineFallCodingPage from './pages/franchisees/westborough/online-fall-coding';
//import WestboroughOnlineCodingPage from './pages/westborough/online-coding';
import WestboroughLegoLeaguePage from './pages/franchisees/westborough/lego-league';
import WestboroughFallClassesPage from './pages/franchisees/westborough/fall-classes';
import WestboroughBlogPage from './pages/franchisees/westborough/blog';
import WestboroughPrivacyPolicyPage from './pages/franchisees/westborough/privacy-policy';
import WestboroughThankYouPage from './pages/franchisees/westborough/thank-you';
import WestboroughTutorDoctorPage from './pages/franchisees/westborough/tutordoctor';
import WestboroughInPersonCamp from './pages/franchisees/westborough/in-person-brochure';
import WestboroughInPersonClasses from './pages/franchisees/westborough/in-person-classes';
import WestboroughInPersonClassesGoogleAds from './pages/franchisees/westborough/in-person-classes-google-ads';
import FutureSuccessBlogPage from './pages/franchisees/westborough/blogs/future-success';
import VideoGamePassionPage from './pages/franchisees/westborough/blogs/video-game-passion';
import CodingInterestBlogPage from './pages/franchisees/westborough/blogs/coding-interest';
//import WestboroughPricingPage from './pages/franchisees/westford/pricing';

// Medford MA //
import MedfordPage from './pages/franchisees/medford/medford.component';

import MedfordAboutPage from './pages/franchisees/medford/about-us';
import MedfordBirthdayPartiesPage from './pages/franchisees/medford/birthday-parties';
//import MedfordCampsPage from './pages/medford/camps';
import MedfordCompetitionsPage from './pages/franchisees/medford/competitions';
import MedfordContactPage from './pages/franchisees/medford/contact-us';
import MedfordFaqsPage from './pages/franchisees/medford/faqs';
import MedfordFindACoursePage from './pages/franchisees/medford/classes';
import MedfordProgramsPage from './pages/franchisees/medford/programs';
import MedfordGirlsScoutPage from './pages/franchisees/medford/girls-scout';
import MedfordTechnovationPage from './pages/franchisees/medford/technovation';
// import MedfordFllPage from './pages/medford/fll';
import MedfordLocationsPage from './pages/franchisees/medford/locations';

import MedfordAcslPage from './pages/franchisees/medford/acsl-competition';
import MedfordVirtualRoboticsPage from './pages/franchisees/medford/virtual-robotics';
import MedfordWelcomePage from './pages/franchisees/medford/welcome-message';
import MedfordSummerPage from './pages/franchisees/medford/summer';
// import MedfordRobloxPage from './pages/medford/roblox';
import MedfordOnlineFallCodingPage from './pages/franchisees/medford/online-fall-coding';
// import MedfordOnlineCodingPage from './pages/medford/online-coding';
import MedfordLegoLeaguePage from './pages/franchisees/medford/lego-league';
import MedfordFallClassesPage from './pages/franchisees/medford/fall-classes';
import MedfordBlogPage from './pages/franchisees/medford/blog';
import MedfordPrivacyPolicyPage from './pages/franchisees/medford/privacy-policy';
import MedfordThankYouPage from './pages/franchisees/medford/thank-you';
import MedfordInPersonClasses from './pages/franchisees/medford/in-person-classes';
//import MedfordPricingPage from './pages/franchisees/medford/pricing';

// Needham MA //
import NeedhamPage from './pages/franchisees/needham/needham.component';
import TestNeedhamPage from './pages/franchisees/needham/testNeedham.component';
import NeedhamComingSoonPage from './pages/franchisees/needham/needham-coming-soon.component';
import NeedhamFoundingMemberPage from './pages/franchisees/needham/needham-founding-member.component';

import NeedhamAboutPage from './pages/franchisees/needham/about-us';
import NeedhamBirthdayPartiesPage from './pages/franchisees/needham/birthday-parties';
//import NeedhamCampsPage from './pages/needham/camps';
import NeedhamCompetitionsPage from './pages/franchisees/needham/competitions';
import NeedhamContactPage from './pages/franchisees/needham/contact-us';
import NeedhamFaqsPage from './pages/franchisees/needham/faqs';
import NeedhamFindACoursePage from './pages/franchisees/needham/classes';
import NeedhamProgramsPage from './pages/franchisees/needham/programs';
import NeedhamGirlsScoutPage from './pages/franchisees/needham/girls-scout';
import NeedhamTechnovationPage from './pages/franchisees/needham/technovation';
// import NeedhamFllPage from './pages/needham/fll';
import NeedhamLocationsPage from './pages/franchisees/needham/locations';

import NeedhamAcslPage from './pages/franchisees/needham/acsl-competition';
import NeedhamVirtualRoboticsPage from './pages/franchisees/needham/virtual-robotics';
import NeedhamWelcomePage from './pages/franchisees/needham/welcome-message';
import NeedhamSummerPage from './pages/franchisees/needham/summer';
// import NeedhamRobloxPage from './pages/needham/roblox';
import NeedhamOnlineFallCodingPage from './pages/franchisees/needham/online-fall-coding';
// import NeedhamOnlineCodingPage from './pages/needham/online-coding';
import NeedhamLegoLeaguePage from './pages/franchisees/needham/lego-league';
import NeedhamFallClassesPage from './pages/franchisees/needham/fall-classes';
import NeedhamBlogPage from './pages/franchisees/needham/blog';
import NeedhamPrivacyPolicyPage from './pages/franchisees/needham/privacy-policy';
import NeedhamThankYouPage from './pages/franchisees/needham/thank-you';
import NeedhamInPersonClasses from './pages/franchisees/needham/in-person-classes';
//import NeedhamPricingPage from './pages/needham/pricing';

// Lawrenceville MA //
import LawrencevillePage from './pages/franchisees/lawrenceville/lawrenceville.component';
import LawrencevilleComingSoonPage from './pages/franchisees/lawrenceville/lawrenceville-coming-soon.component';
import LawrencevilleFoundingMemberPage from './pages/franchisees/lawrenceville/lawrenceville-founding-member.component';


import LawrencevilleAboutPage from './pages/franchisees/lawrenceville/about-us';
import LawrencevilleBirthdayPartiesPage from './pages/franchisees/lawrenceville/birthday-parties';
//import LawrencevilleCampsPage from './pages/lawrenceville/camps';
import LawrencevilleCompetitionsPage from './pages/franchisees/lawrenceville/competitions';
import LawrencevilleContactPage from './pages/franchisees/lawrenceville/contact-us';
import LawrencevilleFaqsPage from './pages/franchisees/lawrenceville/faqs';
import LawrencevilleFindACoursePage from './pages/franchisees/lawrenceville/classes';
import LawrencevilleProgramsPage from './pages/franchisees/lawrenceville/programs';
import LawrencevilleGirlsScoutPage from './pages/franchisees/lawrenceville/girls-scout';
import LawrencevilleTechnovationPage from './pages/franchisees/lawrenceville/technovation';
// import LawrencevilleFllPage from './pages/lawrenceville/fll';
import LawrencevilleLocationsPage from './pages/franchisees/lawrenceville/locations';

import LawrencevilleAcslPage from './pages/franchisees/lawrenceville/acsl-competition';
import LawrencevilleVirtualRoboticsPage from './pages/franchisees/lawrenceville/virtual-robotics';
import LawrencevilleWelcomePage from './pages/franchisees/lawrenceville/welcome-message';
import LawrencevilleSummerPage from './pages/franchisees/lawrenceville/summer';
// import LawrencevilleRobloxPage from './pages/lawrenceville/roblox';
import LawrencevilleOnlineFallCodingPage from './pages/franchisees/lawrenceville/online-fall-coding';
// import LawrencevilleOnlineCodingPage from './pages/lawrenceville/online-coding';
import LawrencevilleLegoLeaguePage from './pages/franchisees/lawrenceville/lego-league';
import LawrencevilleFallClassesPage from './pages/franchisees/lawrenceville/fall-classes';
import LawrencevilleBlogPage from './pages/franchisees/lawrenceville/blog';
import LawrencevillePrivacyPolicyPage from './pages/franchisees/lawrenceville/privacy-policy';
import LawrencevilleThankYouPage from './pages/franchisees/lawrenceville/thank-you';
import LawrencevilleInPersonClasses from './pages/franchisees/lawrenceville/in-person-classes';
//import LawrencevillePricingPage from './pages/lawrenceville/pricing';

// Plano TX //
import PlanoPage from './pages/franchisees/plano/plano.component';
import PlanoComingSoonPage from './pages/franchisees/plano/plano-coming-soon.component';
import PlanoFoundingMemberPage from './pages/franchisees/plano/plano-founding-member.component';

import PlanoAboutPage from './pages/franchisees/plano/about-us';
import PlanoBirthdayPartiesPage from './pages/franchisees/plano/birthday-parties';
//import PlanoCampsPage from './pages/plano/camps';
import PlanoCompetitionsPage from './pages/franchisees/plano/competitions';
import PlanoContactPage from './pages/franchisees/plano/contact-us';
import PlanoFaqsPage from './pages/franchisees/plano/faqs';
import PlanoFindACoursePage from './pages/franchisees/plano/classes';
import PlanoProgramsPage from './pages/franchisees/plano/programs';
import PlanoGirlsScoutPage from './pages/franchisees/plano/girls-scout';
import PlanoTechnovationPage from './pages/franchisees/plano/technovation';
// import PlanoFllPage from './pages/plano/fll';
import PlanoLocationsPage from './pages/franchisees/plano/locations';

import PlanoAcslPage from './pages/franchisees/plano/acsl-competition';
import PlanoVirtualRoboticsPage from './pages/franchisees/plano/virtual-robotics';
import PlanoWelcomePage from './pages/franchisees/plano/welcome-message';
import PlanoSummerPage from './pages/franchisees/plano/summer';
// import PlanoRobloxPage from './pages/plano/roblox';
import PlanoOnlineFallCodingPage from './pages/franchisees/plano/online-fall-coding';
// import PlanoOnlineCodingPage from './pages/plano/online-coding';
import PlanoLegoLeaguePage from './pages/franchisees/plano/lego-league';
import PlanoFallClassesPage from './pages/franchisees/plano/fall-classes';
import PlanoBlogPage from './pages/franchisees/plano/blog';
import PlanoPrivacyPolicyPage from './pages/franchisees/plano/privacy-policy';
import PlanoThankYouPage from './pages/franchisees/plano/thank-you';
import PlanoInPersonClasses from './pages/franchisees/plano/in-person-classes';
//import PlanoPricingPage from './pages/plano/pricing';

// St. Johns FL //
import StjohnsPage from './pages/franchisees/stjohns/stjohns.component';
import StjohnsComingSoonPage from './pages/franchisees/stjohns/stjohns-coming-soon.component';
import StjohnsFoundingMemberPage from './pages/franchisees/stjohns/stjohns-founding-member.component';

import StjohnsAboutPage from './pages/franchisees/stjohns/about-us';
import StjohnsBirthdayPartiesPage from './pages/franchisees/stjohns/birthday-parties';
//import StjohnsCampsPage from './pages/stjohns/camps';
import StjohnsCompetitionsPage from './pages/franchisees/stjohns/competitions';
import StjohnsContactPage from './pages/franchisees/stjohns/contact-us';
import StjohnsFaqsPage from './pages/franchisees/stjohns/faqs';
import StjohnsFindACoursePage from './pages/franchisees/stjohns/classes';
import StjohnsProgramsPage from './pages/franchisees/stjohns/programs';
import StjohnsGirlsScoutPage from './pages/franchisees/stjohns/girls-scout';
import StjohnsTechnovationPage from './pages/franchisees/stjohns/technovation';
// import StjohnsFllPage from './pages/stjohns/fll';
import StjohnsLocationsPage from './pages/franchisees/stjohns/locations';

import StjohnsAcslPage from './pages/franchisees/stjohns/acsl-competition';
import StjohnsVirtualRoboticsPage from './pages/franchisees/stjohns/virtual-robotics';
import StjohnsWelcomePage from './pages/franchisees/stjohns/welcome-message';
import StjohnsSummerPage from './pages/franchisees/stjohns/summer';
// import StjohnsRobloxPage from './pages/stjohns/roblox';
import StjohnsOnlineFallCodingPage from './pages/franchisees/stjohns/online-fall-coding';
// import StjohnsOnlineCodingPage from './pages/stjohns/online-coding';
import StjohnsLegoLeaguePage from './pages/franchisees/stjohns/lego-league';
import StjohnsFallClassesPage from './pages/franchisees/stjohns/fall-classes';
import StjohnsBlogPage from './pages/franchisees/stjohns/blog';
import StjohnsPrivacyPolicyPage from './pages/franchisees/stjohns/privacy-policy';
import StjohnsThankYouPage from './pages/franchisees/stjohns/thank-you';
import StjohnsInPersonClasses from './pages/franchisees/stjohns/in-person-classes';
//import StjohnsPricingPage from './pages/stjohns/pricing';

// Houston, TX //
import HoustonPage from './pages/franchisees/houston/houston.component';
import HoustonComingSoonPage from './pages/franchisees/houston/houston-coming-soon.component';
import HoustonFoundingMemberPage from './pages/franchisees/houston/houston-founding-member.component';

import HoustonAboutPage from './pages/franchisees/houston/about-us';
import HoustonBirthdayPartiesPage from './pages/franchisees/houston/birthday-parties';
//import HoustonCampsPage from './pages/houston/camps';
import HoustonCompetitionsPage from './pages/franchisees/houston/competitions';
import HoustonContactPage from './pages/franchisees/houston/contact-us';
import HoustonFaqsPage from './pages/franchisees/houston/faqs';
import HoustonFindACoursePage from './pages/franchisees/houston/classes';
import HoustonProgramsPage from './pages/franchisees/houston/programs';
import HoustonGirlsScoutPage from './pages/franchisees/houston/girls-scout';
import HoustonTechnovationPage from './pages/franchisees/houston/technovation';
// import HoustonFllPage from './pages/houston/fll';
import HoustonLocationsPage from './pages/franchisees/houston/locations';

import HoustonAcslPage from './pages/franchisees/houston/acsl-competition';
import HoustonVirtualRoboticsPage from './pages/franchisees/houston/virtual-robotics';
import HoustonWelcomePage from './pages/franchisees/houston/welcome-message';
import HoustonSummerPage from './pages/franchisees/houston/summer';
// import HoustonRobloxPage from './pages/houston/roblox';
import HoustonOnlineFallCodingPage from './pages/franchisees/houston/online-fall-coding';
// import HoustonOnlineCodingPage from './pages/houston/online-coding';
import HoustonLegoLeaguePage from './pages/franchisees/houston/lego-league';
import HoustonFallClassesPage from './pages/franchisees/houston/fall-classes';
import HoustonBlogPage from './pages/franchisees/houston/blog';
import HoustonPrivacyPolicyPage from './pages/franchisees/houston/privacy-policy';
import HoustonThankYouPage from './pages/franchisees/houston/thank-you';
import HoustonInPersonClasses from './pages/franchisees/houston/in-person-classes';
//import HoustonPricingPage from './pages/houston/pricing';

// Durham, NC //
import DurhamPage from './pages/franchisees/durham/durham.component';
import DurhamComingSoonPage from './pages/franchisees/durham/durham-coming-soon.component';
import DurhamFoundingMemberPage from './pages/franchisees/durham/durham-founding-member.component';

import DurhamAboutPage from './pages/franchisees/durham/about-us';
import DurhamBirthdayPartiesPage from './pages/franchisees/durham/birthday-parties';
//import DurhamCampsPage from './pages/durham/camps';
import DurhamCompetitionsPage from './pages/franchisees/durham/competitions';
import DurhamContactPage from './pages/franchisees/durham/contact-us';
import DurhamFaqsPage from './pages/franchisees/durham/faqs';
import DurhamFindACoursePage from './pages/franchisees/durham/classes';
import DurhamProgramsPage from './pages/franchisees/durham/programs';
import DurhamGirlsScoutPage from './pages/franchisees/durham/girls-scout';
import DurhamTechnovationPage from './pages/franchisees/durham/technovation';
// import DurhamFllPage from './pages/durham/fll';
import DurhamLocationsPage from './pages/franchisees/durham/locations';

import DurhamAcslPage from './pages/franchisees/durham/acsl-competition';
import DurhamVirtualRoboticsPage from './pages/franchisees/durham/virtual-robotics';
import DurhamWelcomePage from './pages/franchisees/durham/welcome-message';
import DurhamSummerPage from './pages/franchisees/durham/summer';
// import DurhamRobloxPage from './pages/durham/roblox';
import DurhamOnlineFallCodingPage from './pages/franchisees/durham/online-fall-coding';
// import DurhamOnlineCodingPage from './pages/durham/online-coding';
import DurhamLegoLeaguePage from './pages/franchisees/durham/lego-league';
import DurhamFallClassesPage from './pages/franchisees/durham/fall-classes';
import DurhamBlogPage from './pages/franchisees/durham/blog';
import DurhamPrivacyPolicyPage from './pages/franchisees/durham/privacy-policy';
import DurhamThankYouPage from './pages/franchisees/durham/thank-you';
import DurhamInPersonClasses from './pages/franchisees/durham/in-person-classes';
//import DurhamPricingPage from './pages/durham/pricing';

// Cypress, TX //
import CypressPage from './pages/franchisees/cypress/cypress.component';
import CypressComingSoonPage from './pages/franchisees/cypress/cypress-coming-soon.component';
import CypressFoundingMemberPage from './pages/franchisees/cypress/cypress-founding-member.component';

import CypressAboutPage from './pages/franchisees/cypress/about-us';
import CypressBirthdayPartiesPage from './pages/franchisees/cypress/birthday-parties';
//import CypressCampsPage from './pages/cypress/camps';
import CypressCompetitionsPage from './pages/franchisees/cypress/competitions';
import CypressContactPage from './pages/franchisees/cypress/contact-us';
import CypressFaqsPage from './pages/franchisees/cypress/faqs';
import CypressFindACoursePage from './pages/franchisees/cypress/classes';
import CypressProgramsPage from './pages/franchisees/cypress/programs';
import CypressGirlsScoutPage from './pages/franchisees/cypress/girls-scout';
import CypressTechnovationPage from './pages/franchisees/cypress/technovation';
// import CypressFllPage from './pages/cypress/fll';
import CypressLocationsPage from './pages/franchisees/cypress/locations';

import CypressAcslPage from './pages/franchisees/cypress/acsl-competition';
import CypressVirtualRoboticsPage from './pages/franchisees/cypress/virtual-robotics';
import CypressWelcomePage from './pages/franchisees/cypress/welcome-message';
import CypressSummerPage from './pages/franchisees/cypress/summer';
// import CypressRobloxPage from './pages/cypress/roblox';
import CypressOnlineFallCodingPage from './pages/franchisees/cypress/online-fall-coding';
// import CypressOnlineCodingPage from './pages/cypress/online-coding';
import CypressLegoLeaguePage from './pages/franchisees/cypress/lego-league';
import CypressFallClassesPage from './pages/franchisees/cypress/fall-classes';
import CypressBlogPage from './pages/franchisees/cypress/blog';
import CypressPrivacyPolicyPage from './pages/franchisees/cypress/privacy-policy';
import CypressThankYouPage from './pages/franchisees/cypress/thank-you';
import CypressInPersonClasses from './pages/franchisees/cypress/in-person-classes';
//import CypressPricingPage from './pages/cypress/pricing';

// Tierrasanta, CA //
import TierrasantaPage from './pages/franchisees/tierrasanta/tierrasanta.component';
import TierrasantaComingSoonPage from './pages/franchisees/tierrasanta/tierrasanta-coming-soon.component';
import TierrasantaFoundingMemberPage from './pages/franchisees/tierrasanta/tierrasanta-founding-member.component';
// import TierrasantaV2ComingSoonPage from './pages/franchisees/tierrasanta/v2-tierrasanta-coming-soon.component';

import TierrasantaAboutPage from './pages/franchisees/tierrasanta/about-us';
import TierrasantaBirthdayPartiesPage from './pages/franchisees/tierrasanta/birthday-parties';
//import TierrasantaCampsPage from './pages/tierrasanta/camps';
import TierrasantaCompetitionsPage from './pages/franchisees/tierrasanta/competitions';
import TierrasantaContactPage from './pages/franchisees/tierrasanta/contact-us';
import TierrasantaFaqsPage from './pages/franchisees/tierrasanta/faqs';
import TierrasantaFindACoursePage from './pages/franchisees/tierrasanta/classes';
import TierrasantaProgramsPage from './pages/franchisees/tierrasanta/programs';
import TierrasantaGirlsScoutPage from './pages/franchisees/tierrasanta/girls-scout';
import TierrasantaTechnovationPage from './pages/franchisees/tierrasanta/technovation';
// import TierrasantaFllPage from './pages/tierrasanta/fll';
import TierrasantaLocationsPage from './pages/franchisees/tierrasanta/locations';

import TierrasantaAcslPage from './pages/franchisees/tierrasanta/acsl-competition';
import TierrasantaVirtualRoboticsPage from './pages/franchisees/tierrasanta/virtual-robotics';
import TierrasantaWelcomePage from './pages/franchisees/tierrasanta/welcome-message';
import TierrasantaSummerPage from './pages/franchisees/tierrasanta/summer';
// import TierrasantaRobloxPage from './pages/tierrasanta/roblox';
import TierrasantaOnlineFallCodingPage from './pages/franchisees/tierrasanta/online-fall-coding';
// import TierrasantaOnlineCodingPage from './pages/tierrasanta/online-coding';
import TierrasantaLegoLeaguePage from './pages/franchisees/tierrasanta/lego-league';
import TierrasantaFallClassesPage from './pages/franchisees/tierrasanta/fall-classes';
import TierrasantaBlogPage from './pages/franchisees/tierrasanta/blog';
import TierrasantaPrivacyPolicyPage from './pages/franchisees/tierrasanta/privacy-policy';
import TierrasantaThankYouPage from './pages/franchisees/tierrasanta/thank-you';
import TierrasantaInPersonClasses from './pages/franchisees/tierrasanta/in-person-classes';
//import TierrasantaPricingPage from './pages/tierrasanta/pricing';

// Carmel, IN //
import CarmelPage from './pages/franchisees/carmel/carmel.component';
import CarmelComingSoonPage from './pages/franchisees/carmel/carmel-coming-soon.component';

import CarmelAboutPage from './pages/franchisees/carmel/about-us';
import CarmelBirthdayPartiesPage from './pages/franchisees/carmel/birthday-parties';
//import CarmelCampsPage from './pages/carmel/camps';
import CarmelCompetitionsPage from './pages/franchisees/carmel/competitions';
import CarmelContactPage from './pages/franchisees/carmel/contact-us';
import CarmelFaqsPage from './pages/franchisees/carmel/faqs';
import CarmelFindACoursePage from './pages/franchisees/carmel/classes';
import CarmelProgramsPage from './pages/franchisees/carmel/programs';
import CarmelGirlsScoutPage from './pages/franchisees/carmel/girls-scout';
import CarmelTechnovationPage from './pages/franchisees/carmel/technovation';
// import CarmelFllPage from './pages/carmel/fll';
import CarmelLocationsPage from './pages/franchisees/carmel/locations';

import CarmelAcslPage from './pages/franchisees/carmel/acsl-competition';
import CarmelVirtualRoboticsPage from './pages/franchisees/carmel/virtual-robotics';
import CarmelWelcomePage from './pages/franchisees/carmel/welcome-message';
import CarmelSummerPage from './pages/franchisees/carmel/summer';
// import CarmelRobloxPage from './pages/carmel/roblox';
import CarmelOnlineFallCodingPage from './pages/franchisees/carmel/online-fall-coding';
// import CarmelOnlineCodingPage from './pages/carmel/online-coding';
import CarmelLegoLeaguePage from './pages/franchisees/carmel/lego-league';
import CarmelFallClassesPage from './pages/franchisees/carmel/fall-classes';
import CarmelBlogPage from './pages/franchisees/carmel/blog';
import CarmelPrivacyPolicyPage from './pages/franchisees/carmel/privacy-policy';
import CarmelThankYouPage from './pages/franchisees/carmel/thank-you';
import CarmelInPersonClasses from './pages/franchisees/carmel/in-person-classes';
//import CarmelPricingPage from './pages/carmel/pricing';

// FishHawk, FL //
import FishHawkPage from './pages/franchisees/fishhawk/fishhawk.component';
import FishHawkFoundingMemberPage from './pages/franchisees/fishhawk/fishhawk-founding-member.component';

import FishHawkAboutPage from './pages/franchisees/fishhawk/about-us';
import FishHawkBirthdayPartiesPage from './pages/franchisees/fishhawk/birthday-parties';
//import FishHawkCampsPage from './pages/fishhawk/camps';
import FishHawkCompetitionsPage from './pages/franchisees/fishhawk/competitions';
import FishHawkContactPage from './pages/franchisees/fishhawk/contact-us';
import FishHawkFaqsPage from './pages/franchisees/fishhawk/faqs';
import FishHawkFindACoursePage from './pages/franchisees/fishhawk/classes';
import FishHawkProgramsPage from './pages/franchisees/fishhawk/programs';
import FishHawkGirlsScoutPage from './pages/franchisees/fishhawk/girls-scout';
import FishHawkTechnovationPage from './pages/franchisees/fishhawk/technovation';
// import FishHawkFllPage from './pages/fishhawk/fll';
import FishHawkLocationsPage from './pages/franchisees/fishhawk/locations';

import FishHawkAcslPage from './pages/franchisees/fishhawk/acsl-competition';
import FishHawkVirtualRoboticsPage from './pages/franchisees/fishhawk/virtual-robotics';
import FishHawkWelcomePage from './pages/franchisees/fishhawk/welcome-message';
import FishHawkSummerPage from './pages/franchisees/fishhawk/summer';
// import FishHawkRobloxPage from './pages/fishhawk/roblox';
import FishHawkOnlineFallCodingPage from './pages/franchisees/fishhawk/online-fall-coding';
// import FishHawkOnlineCodingPage from './pages/fishhawk/online-coding';
import FishHawkLegoLeaguePage from './pages/franchisees/fishhawk/lego-league';
import FishHawkFallClassesPage from './pages/franchisees/fishhawk/fall-classes';
import FishHawkBlogPage from './pages/franchisees/fishhawk/blog';
import FishHawkPrivacyPolicyPage from './pages/franchisees/fishhawk/privacy-policy';
import FishHawkThankYouPage from './pages/franchisees/fishhawk/thank-you';
import FishHawkInPersonClasses from './pages/franchisees/fishhawk/in-person-classes';
//import FishHawkPricingPage from './pages/fishhawk/pricing';

// Katy, TX //
import KatyPage from './pages/franchisees/katy/katy.component';
import KatyComingSoonPage from './pages/franchisees/katy/katy-coming-soon.component';
import KatyFoundingMemberPage from './pages/franchisees/katy/katy-founding-member.component';

import KatyAboutPage from './pages/franchisees/katy/about-us';
import KatyBirthdayPartiesPage from './pages/franchisees/katy/birthday-parties';
//import KatyCampsPage from './pages/katy/camps';
import KatyCompetitionsPage from './pages/franchisees/katy/competitions';
import KatyContactPage from './pages/franchisees/katy/contact-us';
import KatyFaqsPage from './pages/franchisees/katy/faqs';
import KatyFindACoursePage from './pages/franchisees/katy/classes';
import KatyProgramsPage from './pages/franchisees/katy/programs';
import KatyGirlsScoutPage from './pages/franchisees/katy/girls-scout';
import KatyTechnovationPage from './pages/franchisees/katy/technovation';
// import KatyFllPage from './pages/katy/fll';
import KatyLocationsPage from './pages/franchisees/katy/locations';

import KatyAcslPage from './pages/franchisees/katy/acsl-competition';
import KatyVirtualRoboticsPage from './pages/franchisees/katy/virtual-robotics';
import KatyWelcomePage from './pages/franchisees/katy/welcome-message';
import KatySummerPage from './pages/franchisees/katy/summer';
// import KatyRobloxPage from './pages/katy/roblox';
import KatyOnlineFallCodingPage from './pages/franchisees/katy/online-fall-coding';
// import KatyOnlineCodingPage from './pages/katy/online-coding';
import KatyLegoLeaguePage from './pages/franchisees/katy/lego-league';
import KatyFallClassesPage from './pages/franchisees/katy/fall-classes';
import KatyBlogPage from './pages/franchisees/katy/blog';
import KatyPrivacyPolicyPage from './pages/franchisees/katy/privacy-policy';
import KatyThankYouPage from './pages/franchisees/katy/thank-you';
import KatyInPersonClasses from './pages/franchisees/katy/in-person-classes';
//import KatyPricingPage from './pages/katy/pricing';

// Keller East, TX //
import KellerEastPage from './pages/franchisees/kellereast/kellereast.component';
import KellerEastComingSoonPage from './pages/franchisees/kellereast/kellereast-coming-soon.component';
import KellerEastFoundingMemberPage from './pages/franchisees/kellereast/kellereast-founding-member.component';

import KellerEastAboutPage from './pages/franchisees/kellereast/about-us';
import KellerEastBirthdayPartiesPage from './pages/franchisees/kellereast/birthday-parties';
//import KellerEastCampsPage from './pages/kellereast/camps';
import KellerEastCompetitionsPage from './pages/franchisees/kellereast/competitions';
import KellerEastContactPage from './pages/franchisees/kellereast/contact-us';
import KellerEastFaqsPage from './pages/franchisees/kellereast/faqs';
import KellerEastFindACoursePage from './pages/franchisees/kellereast/classes';
import KellerEastProgramsPage from './pages/franchisees/kellereast/programs';
import KellerEastGirlsScoutPage from './pages/franchisees/kellereast/girls-scout';
import KellerEastTechnovationPage from './pages/franchisees/kellereast/technovation';
// import KellerEastFllPage from './pages/kellereast/fll';
import KellerEastLocationsPage from './pages/franchisees/kellereast/locations';

import KellerEastAcslPage from './pages/franchisees/kellereast/acsl-competition';
import KellerEastVirtualRoboticsPage from './pages/franchisees/kellereast/virtual-robotics';
import KellerEastWelcomePage from './pages/franchisees/kellereast/welcome-message';
import KellerEastSummerPage from './pages/franchisees/kellereast/summer';
// import KellerEastRobloxPage from './pages/kellereast/roblox';
import KellerEastOnlineFallCodingPage from './pages/franchisees/kellereast/online-fall-coding';
// import KellerEastOnlineCodingPage from './pages/kellereast/online-coding';
import KellerEastLegoLeaguePage from './pages/franchisees/kellereast/lego-league';
import KellerEastFallClassesPage from './pages/franchisees/kellereast/fall-classes';
import KellerEastBlogPage from './pages/franchisees/kellereast/blog';
import KellerEastPrivacyPolicyPage from './pages/franchisees/kellereast/privacy-policy';
import KellerEastThankYouPage from './pages/franchisees/kellereast/thank-you';
import KellerEastInPersonClasses from './pages/franchisees/kellereast/in-person-classes';
//import KellerEastPricingPage from './pages/kellereast/pricing';

// Colleyville, TX //
import ColleyvillePage from './pages/franchisees/colleyville/colleyville.component';
import ColleyvilleFoundingMemberPage from './pages/franchisees/colleyville/colleyville-founding-member.component';

import ColleyvilleAboutPage from './pages/franchisees/colleyville/about-us';
import ColleyvilleBirthdayPartiesPage from './pages/franchisees/colleyville/birthday-parties';
//import ColleyvilleCampsPage from './pages/colleyville/camps';
import ColleyvilleCompetitionsPage from './pages/franchisees/colleyville/competitions';
import ColleyvilleContactPage from './pages/franchisees/colleyville/contact-us';
import ColleyvilleFaqsPage from './pages/franchisees/colleyville/faqs';
import ColleyvilleFindACoursePage from './pages/franchisees/colleyville/classes';
import ColleyvilleProgramsPage from './pages/franchisees/colleyville/programs';
import ColleyvilleGirlsScoutPage from './pages/franchisees/colleyville/girls-scout';
import ColleyvilleTechnovationPage from './pages/franchisees/colleyville/technovation';
// import ColleyvilleFllPage from './pages/colleyville/fll';
import ColleyvilleLocationsPage from './pages/franchisees/colleyville/locations';

import ColleyvilleAcslPage from './pages/franchisees/colleyville/acsl-competition';
import ColleyvilleVirtualRoboticsPage from './pages/franchisees/colleyville/virtual-robotics';
import ColleyvilleWelcomePage from './pages/franchisees/colleyville/welcome-message';
import ColleyvilleSummerPage from './pages/franchisees/colleyville/summer';
// import ColleyvilleRobloxPage from './pages/colleyville/roblox';
import ColleyvilleOnlineFallCodingPage from './pages/franchisees/colleyville/online-fall-coding';
// import ColleyvilleOnlineCodingPage from './pages/colleyville/online-coding';
import ColleyvilleLegoLeaguePage from './pages/franchisees/colleyville/lego-league';
import ColleyvilleFallClassesPage from './pages/franchisees/colleyville/fall-classes';
import ColleyvilleBlogPage from './pages/franchisees/colleyville/blog';
import ColleyvillePrivacyPolicyPage from './pages/franchisees/colleyville/privacy-policy';
import ColleyvilleThankYouPage from './pages/franchisees/colleyville/thank-you';
import ColleyvilleInPersonClasses from './pages/franchisees/colleyville/in-person-classes';
//import ColleyvillePricingPage from './pages/colleyville/pricing';

// Rutherford, NJ //
import RutherfordPage from './pages/franchisees/rutherford/rutherford.component';
import RutherfordFoundingMemberPage from './pages/franchisees/rutherford/rutherford-founding-member.component';

import RutherfordAboutPage from './pages/franchisees/rutherford/about-us';
import RutherfordBirthdayPartiesPage from './pages/franchisees/rutherford/birthday-parties';
//import RutherfordCampsPage from './pages/rutherford/camps';
import RutherfordCompetitionsPage from './pages/franchisees/rutherford/competitions';
import RutherfordContactPage from './pages/franchisees/rutherford/contact-us';
import RutherfordFaqsPage from './pages/franchisees/rutherford/faqs';
import RutherfordFindACoursePage from './pages/franchisees/rutherford/classes';
import RutherfordProgramsPage from './pages/franchisees/rutherford/programs';
import RutherfordGirlsScoutPage from './pages/franchisees/rutherford/girls-scout';
import RutherfordTechnovationPage from './pages/franchisees/rutherford/technovation';
// import RutherfordFllPage from './pages/rutherford/fll';
import RutherfordLocationsPage from './pages/franchisees/rutherford/locations';

import RutherfordAcslPage from './pages/franchisees/rutherford/acsl-competition';
import RutherfordVirtualRoboticsPage from './pages/franchisees/rutherford/virtual-robotics';
import RutherfordWelcomePage from './pages/franchisees/rutherford/welcome-message';
import RutherfordSummerPage from './pages/franchisees/rutherford/summer';
// import RutherfordRobloxPage from './pages/rutherford/roblox';
import RutherfordOnlineFallCodingPage from './pages/franchisees/rutherford/online-fall-coding';
// import RutherfordOnlineCodingPage from './pages/rutherford/online-coding';
import RutherfordLegoLeaguePage from './pages/franchisees/rutherford/lego-league';
import RutherfordFallClassesPage from './pages/franchisees/rutherford/fall-classes';
import RutherfordBlogPage from './pages/franchisees/rutherford/blog';
import RutherfordPrivacyPolicyPage from './pages/franchisees/rutherford/privacy-policy';
import RutherfordThankYouPage from './pages/franchisees/rutherford/thank-you';
import RutherfordInPersonClasses from './pages/franchisees/rutherford/in-person-classes';
//import RutherfordPricingPage from './pages/rutherford/pricing';

// Quincy, MA //
import QuincyPage from './pages/franchisees/quincy/quincy.component';
import QuincyFoundingMemberPage from './pages/franchisees/quincy/quincy-founding-member.component';

import QuincyAboutPage from './pages/franchisees/quincy/about-us';
import QuincyBirthdayPartiesPage from './pages/franchisees/quincy/birthday-parties';
//import QuincyCampsPage from './pages/quincy/camps';
import QuincyCompetitionsPage from './pages/franchisees/quincy/competitions';
import QuincyContactPage from './pages/franchisees/quincy/contact-us';
import QuincyFaqsPage from './pages/franchisees/quincy/faqs';
import QuincyFindACoursePage from './pages/franchisees/quincy/classes';
import QuincyProgramsPage from './pages/franchisees/quincy/programs';
import QuincyGirlsScoutPage from './pages/franchisees/quincy/girls-scout';
import QuincyTechnovationPage from './pages/franchisees/quincy/technovation';
// import QuincyFllPage from './pages/quincy/fll';
import QuincyLocationsPage from './pages/franchisees/quincy/locations';

import QuincyAcslPage from './pages/franchisees/quincy/acsl-competition';
import QuincyVirtualRoboticsPage from './pages/franchisees/quincy/virtual-robotics';
import QuincyWelcomePage from './pages/franchisees/quincy/welcome-message';
import QuincySummerPage from './pages/franchisees/quincy/summer';
// import QuincyRobloxPage from './pages/quincy/roblox';
import QuincyOnlineFallCodingPage from './pages/franchisees/quincy/online-fall-coding';
// import QuincyOnlineCodingPage from './pages/quincy/online-coding';
import QuincyLegoLeaguePage from './pages/franchisees/quincy/lego-league';
import QuincyFallClassesPage from './pages/franchisees/quincy/fall-classes';
import QuincyBlogPage from './pages/franchisees/quincy/blog';
import QuincyPrivacyPolicyPage from './pages/franchisees/quincy/privacy-policy';
import QuincyThankYouPage from './pages/franchisees/quincy/thank-you';
import QuincyInPersonClasses from './pages/franchisees/quincy/in-person-classes';
//import QuincyPricingPage from './pages/quincy/pricing';

// Chandler, MA //
import ChandlerPage from './pages/franchisees/chandler/chandler.component';
import ChandlerFoundingMemberPage from './pages/franchisees/chandler/chandler-founding-member.component';

import ChandlerAboutPage from './pages/franchisees/chandler/about-us';
import ChandlerBirthdayPartiesPage from './pages/franchisees/chandler/birthday-parties';
//import ChandlerCampsPage from './pages/chandler/camps';
import ChandlerCompetitionsPage from './pages/franchisees/chandler/competitions';
import ChandlerContactPage from './pages/franchisees/chandler/contact-us';
import ChandlerFaqsPage from './pages/franchisees/chandler/faqs';
import ChandlerFindACoursePage from './pages/franchisees/chandler/classes';
import ChandlerProgramsPage from './pages/franchisees/chandler/programs';
import ChandlerGirlsScoutPage from './pages/franchisees/chandler/girls-scout';
import ChandlerTechnovationPage from './pages/franchisees/chandler/technovation';
// import ChandlerFllPage from './pages/chandler/fll';
import ChandlerLocationsPage from './pages/franchisees/chandler/locations';

import ChandlerAcslPage from './pages/franchisees/chandler/acsl-competition';
import ChandlerVirtualRoboticsPage from './pages/franchisees/chandler/virtual-robotics';
import ChandlerWelcomePage from './pages/franchisees/chandler/welcome-message';
import ChandlerSummerPage from './pages/franchisees/chandler/summer';
// import ChandlerRobloxPage from './pages/chandler/roblox';
import ChandlerOnlineFallCodingPage from './pages/franchisees/chandler/online-fall-coding';
// import ChandlerOnlineCodingPage from './pages/chandler/online-coding';
import ChandlerLegoLeaguePage from './pages/franchisees/chandler/lego-league';
import ChandlerFallClassesPage from './pages/franchisees/chandler/fall-classes';
import ChandlerBlogPage from './pages/franchisees/chandler/blog';
import ChandlerPrivacyPolicyPage from './pages/franchisees/chandler/privacy-policy';
import ChandlerThankYouPage from './pages/franchisees/chandler/thank-you';
import ChandlerInPersonClasses from './pages/franchisees/chandler/in-person-classes';
//import ChandlerPricingPage from './pages/chandler/pricing';

// Owasso, OK //
import OwassoPage from './pages/franchisees/owasso/owasso.component';
import OwassoFoundingMemberPage from './pages/franchisees/owasso/owasso-founding-member.component';

import OwassoAboutPage from './pages/franchisees/owasso/about-us';
import OwassoBirthdayPartiesPage from './pages/franchisees/owasso/birthday-parties';
//import OwassoCampsPage from './pages/owasso/camps';
import OwassoCompetitionsPage from './pages/franchisees/owasso/competitions';
import OwassoContactPage from './pages/franchisees/owasso/contact-us';
import OwassoFaqsPage from './pages/franchisees/owasso/faqs';
import OwassoFindACoursePage from './pages/franchisees/owasso/classes';
import OwassoProgramsPage from './pages/franchisees/owasso/programs';
import OwassoGirlsScoutPage from './pages/franchisees/owasso/girls-scout';
import OwassoTechnovationPage from './pages/franchisees/owasso/technovation';
// import OwassoFllPage from './pages/owasso/fll';
import OwassoLocationsPage from './pages/franchisees/owasso/locations';

import OwassoAcslPage from './pages/franchisees/owasso/acsl-competition';
import OwassoVirtualRoboticsPage from './pages/franchisees/owasso/virtual-robotics';
import OwassoWelcomePage from './pages/franchisees/owasso/welcome-message';
import OwassoSummerPage from './pages/franchisees/owasso/summer';
// import OwassoRobloxPage from './pages/owasso/roblox';
import OwassoOnlineFallCodingPage from './pages/franchisees/owasso/online-fall-coding';
// import OwassoOnlineCodingPage from './pages/owasso/online-coding';
import OwassoLegoLeaguePage from './pages/franchisees/owasso/lego-league';
import OwassoFallClassesPage from './pages/franchisees/owasso/fall-classes';
import OwassoBlogPage from './pages/franchisees/owasso/blog';
import OwassoPrivacyPolicyPage from './pages/franchisees/owasso/privacy-policy';
import OwassoThankYouPage from './pages/franchisees/owasso/thank-you';
import OwassoInPersonClasses from './pages/franchisees/owasso/in-person-classes';
//import OwassoPricingPage from './pages/owasso/pricing';

// Plainsboro, NJ //
import PlainsboroPage from './pages/franchisees/plainsboro/plainsboro.component';
import PlainsboroFoundingMemberPage from './pages/franchisees/plainsboro/plainsboro-founding-member.component';

import PlainsboroAboutPage from './pages/franchisees/plainsboro/about-us';
import PlainsboroBirthdayPartiesPage from './pages/franchisees/plainsboro/birthday-parties';
//import PlainsboroCampsPage from './pages/plainsboro/camps';
import PlainsboroCompetitionsPage from './pages/franchisees/plainsboro/competitions';
import PlainsboroContactPage from './pages/franchisees/plainsboro/contact-us';
import PlainsboroFaqsPage from './pages/franchisees/plainsboro/faqs';
import PlainsboroFindACoursePage from './pages/franchisees/plainsboro/classes';
import PlainsboroProgramsPage from './pages/franchisees/plainsboro/programs';
import PlainsboroGirlsScoutPage from './pages/franchisees/plainsboro/girls-scout';
import PlainsboroTechnovationPage from './pages/franchisees/plainsboro/technovation';
// import PlainsboroFllPage from './pages/plainsboro/fll';
import PlainsboroLocationsPage from './pages/franchisees/plainsboro/locations';

import PlainsboroAcslPage from './pages/franchisees/plainsboro/acsl-competition';
import PlainsboroVirtualRoboticsPage from './pages/franchisees/plainsboro/virtual-robotics';
import PlainsboroWelcomePage from './pages/franchisees/plainsboro/welcome-message';
import PlainsboroSummerPage from './pages/franchisees/plainsboro/summer';
// import PlainsboroRobloxPage from './pages/plainsboro/roblox';
import PlainsboroOnlineFallCodingPage from './pages/franchisees/plainsboro/online-fall-coding';
// import PlainsboroOnlineCodingPage from './pages/plainsboro/online-coding';
import PlainsboroLegoLeaguePage from './pages/franchisees/plainsboro/lego-league';
import PlainsboroFallClassesPage from './pages/franchisees/plainsboro/fall-classes';
import PlainsboroBlogPage from './pages/franchisees/plainsboro/blog';
import PlainsboroPrivacyPolicyPage from './pages/franchisees/plainsboro/privacy-policy';
import PlainsboroThankYouPage from './pages/franchisees/plainsboro/thank-you';
import PlainsboroInPersonClasses from './pages/franchisees/plainsboro/in-person-classes';
//import PlainsboroPricingPage from './pages/plainsboro/pricing';

import AboutPage from './pages/about-us/about-us.component';
import ContactPage from './pages/contact/contact.component';
import ProgramsPage from './pages/programs/programs.component';
import GirlsScoutPage from './pages/girls-scout/girls-scout.component';
import BirthdayPartiesPage from './pages/birthday-parties/birthday-parties.component';
import FindACoursePage from './pages/find-a-course/find-a-course.component';
import CampsPage from './pages/camps/camps.component';
import CompetitionsPage from './pages/competitions/competitions.component';
// import TechnovationPage from './pages/technovation/technovation';
// import FLLPage from './pages/fll-old/fll';
import FAQPage from './pages/faqs/faqs';
import LocationsPage from './pages/locations/locations';
// import SummerPage from './pages/summer';
//import AcslPage from './pages/acsl-competition';
import RobloxPage from './pages/roblox';
//import OnlineFallCodingPage from './pages/online-fall-coding';
import OnlineCodingPage from './pages/online-coding';
// import FallClassesPage from './pages/fall-classes/fall-classes.component';
// import LegoLeaguePage from './pages/lego-league';
// import VirtualRoboticsPage from './pages/virtual-robotics';
import WelcomeMessagePage from './pages/welcome-message';
import BlogPage from './pages/blog/blog.component';
import NotFound from './pages/notfound';
// import Sessions from './pages/sessions.component';
//import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import PrivacyPolicyPage from './pages/privacy-policy';
import TermsAndConditionsPage from './pages/terms-and-conditions';
import ThankYouPage from './pages/thank-you';
import PricingPage from './pages/pricing';
import CodingClassesCanadaPage from './pages/coding-classes-canada';
import TutorDoctorUKPage from './pages/tutordoctor-uk';
import TutorDoctorPage from './pages/tutordoctor';
import OnlineFallClassesWestford from './pages/online-fall-classes-westford';
import OnlineFallClassesWestborough from './pages/online-fall-classes-westborough';
import OnlineFallClassesArlington from './pages/online-fall-classes-arlington';
import OnlineFallClassesMedford from './pages/online-fall-classes-medford';
import OnlineFallClassesReading from './pages/online-fall-classes-reading';
//import OnlineFallClassesJerseyCity from './pages/online-fall-classes-jerseycity';
import CodingSchoolForKidsWestford from './pages/coding-school-for-kids-westford';
import CodingSchoolForKidsWestborough from './pages/coding-school-for-kids-westborough';
import CodingSchoolForKidsArlington from './pages/coding-school-for-kids-arlington';
import CodingSchoolForKidsMedford from './pages/coding-school-for-kids-medford';
import CodingSchoolForKidsReading from './pages/coding-school-for-kids-reading';
//import CodingSchoolForKidsJerseyCity from './pages/coding-school-for-kids-jerseycity';

import RFCFrom from './pages/rfc-form/rfc-form.component';
import CovidAddendum from './pages/covid-19/covid-19-addendum.component';

//Blog Pages
import BlogTemplate from './blog-pages/blog-template.component';
import ReasonsToInvest from './blog-pages/codewiz-franchise-investment';
import ScreenTime from './blog-pages/screen-time-for-kids';
// import HomeSchooling from './blog-pages/homeschooling';
import HomeSchooling from './blog-pages/how-interactive-learning';
import AfterSchoolCoding from './blog-pages/after-school-coding-classes';
import BestAfterSchool from './blog-pages/best-after-school-coding-classes';
import CodewizCommitment from './blog-pages/codewiz-commitment';
import CodewizSchoolForKids from './blog-pages/coding-school-for-kids';
import Covid19Updates from './blog-pages/covid-19-update';
import IAmACoder from './blog-pages/i-am-a-coder';
import MassachusettsWoman from './blog-pages/massachusetts-woman';
import MinecraftEducation from './blog-pages/minecraft-an-educational-tool';
import MinecraftRoblox from './blog-pages/minecraft-and-roblox';
import RobloxEducation from './blog-pages/roblox-education';
import KidsIntroCoding from './blog-pages/when-should-kids-introduced-to-coding';
import CodingImportance from './blog-pages/why-is-coding-important';
import KeyFactors from './blog-pages/15-key-factors';
import BenefitsOfCoding from './blog-pages/benefits-of-coding-competitions';
import BenefitsOfMinecraft from './blog-pages/benefits-of-minecraft';
import CodingCompetition from './blog-pages/coding-competitions-for-kids';
import HowRobloxCodingTeach from './blog-pages/how-roblox-coding-teaches';
import WhatIsCompetitiveCoding from './blog-pages/what-is-competitive-coding';
import CodeAThon from './blog-pages/code-a-thon';
import FiveGiftIdeas from './blog-pages/5-gift-ideas';
import AgeAppropriateMinecraft from './blog-pages/age-appropriate-for-minecraft';
import BestKidsCoding from './blog-pages/best-kids-coding-languages';
import BestKidsSummer from './blog-pages/best-kids-summer-camp';
import BostonKids from './blog-pages/boston-with-kids';
import CodingExplainedMinecraft from './blog-pages/coding-explained-minecraft';
import CodingExplainRoblox from './blog-pages/coding-explained-roblox';
import CodingWithKids from './blog-pages/coding-with-kids';
import GirlWhoCodes from './blog-pages/girl-who-codes';
import HowToKeepKidsEngaged from './blog-pages/how-to-keep-kids-engaged';
import KidBirthdayParty from './blog-pages/kid-birthday-party';
import LanguagesToLearn from './blog-pages/languages-to-learn';
import LearnMath from './blog-pages/learn-math';
import MakeTheSummerCount from './blog-pages/make-the-summer-count';
import ScratchProgramming from './blog-pages/scratch-programming';
import TheCodeAdvantage from './blog-pages/the-code-advantage';
import DataPrivacyTipsForStudent from './blog-pages/data-privacy-tips-for-students';
import IncredibleWomenInTech from './blog-pages/Incredible-Women-in-Tech';
import AutismAwarenessDay from './blog-pages/autism-awareness-day';
import FreeGuyGameDev from './blog-pages/free-guy-game-dev';
import TopCodingLangs from './blog-pages/top-coding-languages-and-platforms-for-kids-2022';
import InDemandCareers from './blog-pages/in-demand-programming-careers-for-students-to-explore';
import CodingFromAYoungAge from './blog-pages/coding-from-a-young-age';
import FutureOfAi from './blog-pages/the-future-of-ai';
import MakeARobot from './blog-pages/how-to-make-a-robot';
// import LearnToCode from './blog-pages/tips-to-learning-to-code';
import ShapingChildsFuture from './blog-pages/shaping-childs-future';
import WestboroughFallBrochure from './assets/docs/Westboro-Fall-Brochure.pdf';
import WestboroughFallSchedule from './assets/docs/fall-2021-class-schedule-westborough.pdf';
import WelcomeToCodeWizBrochure from './assets/docs/welcome-to-code-wiz.pdf';

function App() {
  return (
    <div>
      {/* <Header /> */}
      <Switch>

        {/* General Pages */}
        <Route exact path='/' component={HomePage} />
        <Route exact path='/roblox' component={RobloxPage} />
        <Route exact path='/online-coding-classes-and-camps' component={OnlineCodingPage} />
        {/* <Redirect from="/virtual-robotics" to="/" /> */}
        <Route exact path='/welcome' component={WelcomeMessagePage} />
        <Route exact path='/blog' component={BlogPage} />

        <Route exact path='/about-us' component={AboutPage} />
        <Route exact path='/contact-us' component={ContactPage} />
        <Route exact path='/after-school-coding-programs' component={ProgramsPage} />
        <Route exact path='/girl-scout-badges' component={GirlsScoutPage} />
        <Route exact path='/birthday-parties' component={BirthdayPartiesPage} />
        {/* <Route exact path='/course-details' component={FindACoursePage} /> */}
        <Route exact path='/findmycourse' component={FindACoursePage} />
        <Route exact path='/camps' component={CampsPage} />
        <Route exact path='/coding-robotics-competitions-for-kids' component={CompetitionsPage} />
        <Redirect from="/kids-coding-frequently-asked-questions" to="/faqs" />
        <Route exact path='/faqs' component={FAQPage} />
        <Redirect from="/locations" to="/code-wiz-locations" />
        <Route exact path='/code-wiz-locations' component={LocationsPage} />
        <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />
        <Route exact path='/terms-and-conditions' component={TermsAndConditionsPage} />
        <Route exact path='/thank-you' component={ThankYouPage} />
        {/* <Route exact path='/westford-ma' component={WestfordPage} /> */}
        <Route exact path='/westford-ma' component={WestfordGSPage} />
        <Route exact path='/reading-ma' component={ReadingPage} />
        <Route exact path='/westborough-ma' component={WestboroughPage} />
        <Route exact path='/arlington-ma' component={ArlingtonPage} />
        <Route exact path='/medford-ma' component={MedfordPage} />
        <Route exact path='/nashua-nh' component={NashuaPage} />
        <Route exact path='/needham-ma' component={NeedhamPage} />
        <Route exact path='/plano-tx' component={PlanoPage} />
        <Route exact path='/lawrenceville-ga' component={LawrencevillePage} /> 
        <Route exact path='/stjohns-fl' component={StjohnsPage} />
        <Route exact path='/oakforest-tx' component={HoustonPage} />
        <Route exact path='/durham-nc' component={DurhamPage} /> 
        <Route exact path='/cypress-tx' component={CypressPage} />
        <Route exact path='/tierrasanta-ca' component={TierrasantaPage} /> 
        {/* <Route exact path='/carmel-in' component={CarmelComingSoonPage} /> */}
        <Route exact path='/fishhawk-fl' component={FishHawkPage} /> 
        <Route exact path='/katy-tx' component={KatyPage} /> 
        <Route exact path='/kellereast-tx' component={KellerEastPage} /> 
        <Route exact path='/quincy-ma' component={QuincyPage} />
        <Route exact path='/colleyville-tx' component={ColleyvillePage} /> 
        <Route exact path='/rutherford-nj' component={RutherfordPage} /> 
        <Route exact path='/owasso-ok' component={OwassoFoundingMemberPage} /> 
        {/* <Route exact path='/plainsboro-nj' component={PlainsboroFoundingMemberPage} />  */}
        <Route exact path='/test-zee' component={TestNeedhamPage} />

        {/* <Route exact path='/gso-courses' component={WestfordGSPCoursesPage} /> */}
        
      
        
        {/* <Route exact path='/chandler-az' component={ChandlerFoundingMemberPage} /> */}

        <Route exact path='/coding-classes-canada' component={CodingClassesCanadaPage} />
        <Route exact path='/tutordoctor-uk' component={TutorDoctorUKPage} />
        <Route exact path='/tutordoctor' component={TutorDoctorPage} />
        <Route exact path='/rent-event-space-westford-ma' component={RentASpacePage} />
        <Route exact path='/covid-19-childcare-policies-and-procedures' component={CovidAddendum} />
        {/* <Route exact path='/pricing' component={PricingPage} /> */}
        

        <Route exact path='/online-fall-classes-for-kids-in-westford-ma' component={OnlineFallClassesWestford} />
        <Route exact path='/online-fall-classes-for-kids-in-arlington-ma' component={OnlineFallClassesArlington} />
        <Route exact path='/online-fall-classes-for-kids-in-reading-ma' component={OnlineFallClassesReading} />
        <Route exact path='/online-fall-classes-for-kids-in-westborough-ma' component={OnlineFallClassesWestborough} />
        <Route exact path='/online-fall-classes-for-kids-in-medford-ma' component={OnlineFallClassesMedford} />

        <Route exact path='/coding-school-for-kids-westford-ma' component={CodingSchoolForKidsWestford} />
        <Route exact path='/coding-school-for-kids-westborough-ma' component={CodingSchoolForKidsWestborough} />
        <Route exact path='/coding-school-for-kids-reading-ma' component={CodingSchoolForKidsReading} />
        <Route exact path='/coding-school-for-kids-arlington-ma' component={CodingSchoolForKidsArlington} />
        <Route exact path='/coding-school-for-kids-medford-ma' component={CodingSchoolForKidsMedford} />

        {/* <Route exact path='/rfc' component={RFCFrom} /> */}
        

        {/* Westford MA */}
        <Route exact path='/westford-ma/about-us' component={WestfordAboutPage} />
        {/* <Route exact path='/westford-ma/birthday-parties' component={WestfordBirthdayPartiesPage} /> */}
        <Route exact path='/westford-ma/birthday-parties' component={WestfordPage} />
        <Route exact path='/westford-ma/coding-robotics-competitions-for-kids' component={WestfordCompetitionsPage} />
        <Route exact path='/westford-ma/contact-us' component={WestfordContactPage} />
        <Route exact path='/westford-ma/faqs' component={WestfordFaqsPage} />
        <Route exact path='/westford-ma/findmycourse' component={WestfordFindACoursePage} />
        <Route exact path='/westford-ma/after-school-coding-programs' component={WestfordProgramsPage} />
        <Route exact path='/westford-ma/girl-scout-badges' component={WestfordGirlsScoutPage} />
        <Route exact path='/westford-ma/technovation-challenge' component={WestfordTechnovationPage} />
        <Redirect from="/westford-ma/locations" to="/westford-ma/code-wiz-locations" />
        <Route exact path='/westford-ma/code-wiz-locations' component={WestfordLocationsPage} />
        <Route exact path='/westford-ma/acsl-competition' component={WestfordAcslPage} />
        <Route exact path='/westford-ma/virtual-robotics-competition' component={WestfordVirtualRoboticsPage} />
        <Route exact path='/westford-ma/welcome-message' component={WestfordWelcomePage} />
        <Redirect from="/westford-ma/sessions" to="/westford-ma/camps" />
        <Redirect from="/westford-ma/sessions-new" to="/westford-ma/camps" />
        <Route exact path='/westford-ma/camps' component={WestfordSummerPage} />
        <Route exact path='/westford-ma/parks-and-rec-partnerships' component={WestfordOnlineFallCodingPage} />
        <Redirect from="/westford-ma/fall-classes" to="/westford-ma/online-classes" />
        <Route exact path='/westford-ma/online-classes' component={WestfordFallClassesPage} />
        <Route exact path='/westford-ma/first-lego-league' component={WestfordLegoLeaguePage} />
        <Route exact path='/westford-ma/blog' component={WestfordBlogPage} />
        <Route exact path='/westford-ma/acsl' component={WestfordAcslPage} />
        <Route exact path='/westford-ma/privacy-policy' component={WestfordPrivacyPolicyPage} />
        <Route exact path='/westford-ma/thank-you' component={WestfordThankYouPage} />
        <Route exact path='/westford-ma/tutordoctor' component={WestfordTutorDoctorPage} />
        <Route exact path='/westford-ma/brochures/in-person-summer-camp' component={WestfordInPersonCamp} />
        <Route exact path='/westford-ma/in-person-classes' component={WestfordInPersonClasses} />
        <Route exact path='/westford-ma/in-person-classes-google' component={WestfordInPersonClassesGoogleAds} />
        {/* <Route exact path='/westford-ma/pricing' component={WestfordPricingPage} /> */}
        {/* <Route exact path="/westford-ma/brochures/in-person-summer-camp" render={() => (window.location = process.env.PUBLIC_URL + '/in-person-camps-westford.pdf')} /> */}
        {/* <Route exact path="/westford-ma/brochures/in-person-summer-camp"><Link to={`${process.env.PUBLIC_URL}/in-person-camps-westford.pdf`} /></Route> */}
        <Route exact path="/westford-ma/welcome-to-code-wiz" render={() => (window.location = process.env.PUBLIC_URL + '/in-person-camps-westford.pdf')} />

        {/* Westborough MA */}
        <Route exact path='/westborough-ma/about-us' component={WestboroughAboutPage} />
        <Route exact path='/westborough-ma/birthday-parties' component={WestboroughBirthdayPartiesPage} />
        <Route exact path='/westborough-ma/coding-robotics-competitions-for-kids' component={WestboroughCompetitionsPage} />
        <Route exact path='/westborough-ma/contact-us' component={WestboroughContactPage} />
        <Route exact path='/westborough-ma/faqs' component={WestboroughFaqsPage} />
        <Route exact path='/westborough-ma/findmycourse' component={WestboroughFindACoursePage} />
        <Route exact path='/westborough-ma/after-school-coding-programs' component={WestboroughProgramsPage} />
        <Route exact path='/westborough-ma/girl-scout-badges' component={WestboroughGirlsScoutPage} />
        <Route exact path='/westborough-ma/technovation-challenge' component={WestboroughTechnovationPage} />
        <Route exact path='/westborough-ma/code-wiz-locations' component={WestboroughLocationsPage} />
        <Route exact path='/westborough-ma/acsl-competition' component={WestboroughAcslPage} />
        <Route exact path='/westborough-ma/virtual-robotics-competition' component={WestboroughVirtualRoboticsPage} />
        <Route exact path='/westborough-ma/welcome-message' component={WestboroughWelcomePage} />
        <Redirect from="/westborough-ma/sessions" to="/westborough-ma/camps" />
        <Redirect from="/westborough-ma/sessions-new" to="/westborough-ma/camps" />
        <Route exact path='/westborough-ma/camps' component={WestboroughSummerPage} />
        <Route exact path='/westborough-ma/parks-and-rec-partnerships' component={WestboroughOnlineFallCodingPage} />
        <Redirect from="/westborough-ma/fall-classes" to="/westborough-ma/online-classes" />
        <Route exact path='/westborough-ma/online-classes' component={WestboroughFallClassesPage} />
        <Route exact path='/westborough-ma/first-lego-league' component={WestboroughLegoLeaguePage} />
        <Route exact path='/westborough-ma/blog' component={WestboroughBlogPage} />
        <Route exact path='/westborough-ma/acsl' component={WestboroughAcslPage} />
        <Route exact path='/westborough-ma/privacy-policy' component={WestboroughPrivacyPolicyPage} />
        <Route exact path='/westborough-ma/thank-you' component={WestboroughThankYouPage} />
        <Route exact path='/westborough-ma/tutordoctor' component={WestboroughTutorDoctorPage} />
        <Route exact path='/westborough-ma/brochures/in-person-summer-camp' component={WestboroughInPersonCamp} />
        <Route exact path='/westborough-ma/in-person-classes' component={WestboroughInPersonClasses} />
        <Route exact path='/westborough-ma/in-person-classes-google' component={WestboroughInPersonClassesGoogleAds} />
        <Route exact path='/westborough-ma/blogs/how-to-turn-a-passion-for-video-games-into-a-superpower' component={VideoGamePassionPage} />
        <Route exact path='/westborough-ma/blogs/how-to-set-your-child-or-teen-up-for-future-success' component={FutureSuccessBlogPage} />
        <Route exact path='/westborough-ma/blogs/how-to-get-your-child-interested-in-coding' component={CodingInterestBlogPage} />
        {/* <Route exact path='/westborough-ma/pricing' component={WestboroughPricingPage} /> */}


        {/* Reading MA */}
        <Route exact path='/reading-ma/about-us' component={ReadingAboutPage} />
        <Route exact path='/reading-ma/birthday-parties' component={ReadingBirthdayPartiesPage} />
        <Route exact path='/reading-ma/coding-robotics-competitions-for-kids' component={ReadingCompetitionsPage} />
        <Route exact path='/reading-ma/contact-us' component={ReadingContactPage} />
        <Route exact path='/reading-ma/faqs' component={ReadingFaqsPage} />
        <Route exact path='/reading-ma/findmycourse' component={ReadingFindACoursePage} />
        <Route exact path='/reading-ma/after-school-coding-programs' component={ReadingProgramsPage} />
        <Route exact path='/reading-ma/girl-scout-badges' component={ReadingGirlsScoutPage} />
        <Route exact path='/reading-ma/technovation-challenge' component={ReadingTechnovationPage} />
        <Route exact path='/reading-ma/code-wiz-locations' component={ReadingLocationsPage} />
        <Route exact path='/reading-ma/acsl-competition' component={ReadingAcslPage} />
        <Route exact path='/reading-ma/virtual-robotics-competition' component={ReadingVirtualRoboticsPage} />
        <Route exact path='/reading-ma/welcome-message' component={ReadingWelcomePage} />
        <Redirect from="/reading-ma/sessions" to="/reading-ma/camps" />
        <Redirect from="/reading-ma/sessions-new" to="/reading-ma/camps" />
        <Route exact path='/reading-ma/camps' component={ReadingSummerPage} />
        <Route exact path='/reading-ma/parks-and-rec-partnerships' component={ReadingOnlineFallCodingPage} />
        <Redirect from="/reading-ma/fall-classes" to="/reading-ma/online-classes" />
        <Route exact path='/reading-ma/online-classes' component={ReadingFallClassesPage} />
        <Route exact path='/reading-ma/first-lego-league' component={ReadingLegoLeaguePage} />
        <Route exact path='/reading-ma/blog' component={ReadingBlogPage} />
        <Route exact path='/reading-ma/acsl' component={ReadingAcslPage} />
        <Route exact path='/reading-ma/privacy-policy' component={ReadingPrivacyPolicyPage} />
        <Route exact path='/reading-ma/thank-you' component={ReadingThankYouPage} />
        <Route exact path='/reading-ma/tutordoctor' component={ReadingTutorDoctorPage} />
        <Route exact path='/reading-ma/in-person-classes' component={ReadingInPersonClasses} />
        {/* <Route exact path='/reading-ma/pricing' component={ReadingPricingPage} /> */}

        {/* Nashua NH */}

        <Route exact path='/nashua-nh/about-us' component={NashuaAboutPage} />
        <Route exact path='/nashua-nh/birthday-parties' component={NashuaBirthdayPartiesPage} />
        <Route exact path='/nashua-nh/coding-robotics-competitions-for-kids' component={NashuaCompetitionsPage} />
        <Route exact path='/nashua-nh/contact-us' component={NashuaContactPage} />
        <Route exact path='/nashua-nh/faqs' component={NashuaFaqsPage} />
        <Route exact path='/nashua-nh/findmycourse' component={NashuaFindACoursePage} />
        <Route exact path='/nashua-nh/after-school-coding-programs' component={NashuaProgramsPage} />
        <Route exact path='/nashua-nh/girl-scout-badges' component={NashuaGirlsScoutPage} />
        <Route exact path='/nashua-nh/technovation-challenge' component={NashuaTechnovationPage} />
        <Route exact path='/nashua-nh/code-wiz-locations' component={NashuaLocationsPage} />
        <Route exact path='/nashua-nh/acsl-competition' component={NashuaAcslPage} />
        <Route exact path='/nashua-nh/virtual-robotics-competition' component={NashuaVirtualRoboticsPage} />
        <Route exact path='/nashua-nh/welcome-message' component={NashuaWelcomePage} />
        <Redirect from="/nashua-nh/sessions" to="/nashua-nh/camps" />
        <Redirect from="/nashua-nh/sessions-new" to="/nashua-nh/camps" />
        <Route exact path='/nashua-nh/camps' component={NashuaSummerPage} />
        <Route exact path='/nashua-nh/parks-and-rec-partnerships' component={NashuaOnlineFallCodingPage} />
        <Redirect from="/nashua-nh/fall-classes" to="/nashua-nh/online-classes" />
        <Route exact path='/nashua-nh/online-classes' component={NashuaFallClassesPage} />
        <Route exact path='/nashua-nh/first-lego-league' component={NashuaLegoLeaguePage} />
        <Route exact path='/nashua-nh/blog' component={NashuaBlogPage} />
        <Route exact path='/nashua-nh/acsl' component={NashuaAcslPage} />
        <Route exact path='/nashua-nh/privacy-policy' component={NashuaPrivacyPolicyPage} />
        <Route exact path='/nashua-nh/thank-you' component={NashuaThankYouPage} />
        <Route exact path='/nashua-nh/tutordoctor' component={NashuaTutorDoctorPage} />
        <Route exact path='/nashua-nh/in-person-classes' component={NashuaInPersonClasses} />
        <Route exact path='/nashua-nh/in-person-classes-google' component={NashuaInPersonClassesGoogleAds} />
        {/* <Route exact path='/nashua-nh/pricing' component={NashuaPricingPage} /> */}

        {/* Arlington MA */}
        <Route exact path='/arlington-ma/about-us' component={ArlingtonAboutPage} />
        <Route exact path='/arlington-ma/birthday-parties' component={ArlingtonBirthdayPartiesPage} />
        <Route exact path='/arlington-ma/coding-robotics-competitions-for-kids' component={ArlingtonCompetitionsPage} />
        <Route exact path='/arlington-ma/contact-us' component={ArlingtonContactPage} />
        <Route exact path='/arlington-ma/faqs' component={ArlingtonFaqsPage} />
        <Route exact path='/arlington-ma/findmycourse' component={ArlingtonFindACoursePage} />
        <Route exact path='/arlington-ma/after-school-coding-programs' component={ArlingtonProgramsPage} />
        <Route exact path='/arlington-ma/girl-scout-badges' component={ArlingtonGirlsScoutPage} />
        <Route exact path='/arlington-ma/technovation-challenge' component={ArlingtonTechnovationPage} />
        <Route exact path='/arlington-ma/code-wiz-locations' component={ArlingtonLocationsPage} />
        <Route exact path='/arlington-ma/acsl-competition' component={ArlingtonAcslPage} />
        <Route exact path='/arlington-ma/virtual-robotics-competition' component={ArlingtonVirtualRoboticsPage} />
        <Route exact path='/arlington-ma/welcome-message' component={ArlingtonWelcomePage} />
        <Redirect from="/arlington-ma/sessions" to="/arlington-ma/camps" />
        <Redirect from="/arlington-ma/sessions-new" to="/arlington-ma/camps" />
        <Route exact path='/arlington-ma/camps' component={ArlingtonSummerPage} />
        <Route exact path='/arlington-ma/parks-and-rec-partnerships' component={ArlingtonOnlineFallCodingPage} />
        <Redirect from="/arlington-ma/fall-classes" to="/arlington-ma/online-classes" />
        <Route exact path='/arlington-ma/online-classes' component={ArlingtonFallClassesPage} />
        <Route exact path='/arlington-ma/first-lego-league' component={ArlingtonLegoLeaguePage} />
        <Route exact path='/arlington-ma/blog' component={ArlingtonBlogPage} /> 
        <Route exact path='/arlington-ma/acsl' component={ArlingtonAcslPage} />
        <Route exact path='/arlington-ma/privacy-policy' component={ArlingtonPrivacyPolicyPage} />
        <Route exact path='/arlington-ma/thank-you' component={ArlingtonThankYouPage} />
        <Route exact path='/arlington-ma/tutordoctor' component={ArlingtonTutorDoctorPage} />
        <Route exact path='/arlington-ma/in-person-classes' component={ArlingtonInPersonClasses} />
        <Route exact path='/arlington-ma/in-person-classes-google' component={ArlingtonInPersonClassesGoogleAds} />
        {/* <Route exact path='/arlington-ma/pricing' component={ArlingtonPricingPage} /> */}


        {/* Medford MA */}
        <Route exact path='/medford-ma/about-us' component={MedfordAboutPage} />
        <Route exact path='/medford-ma/birthday-parties' component={MedfordBirthdayPartiesPage} />
        <Route exact path='/medford-ma/coding-robotics-competitions-for-kids' component={MedfordCompetitionsPage} />
        <Route exact path='/medford-ma/contact-us' component={MedfordContactPage} />
        <Route exact path='/medford-ma/faqs' component={MedfordFaqsPage} />
        <Route exact path='/medford-ma/findmycourse' component={MedfordFindACoursePage} />
        <Route exact path='/medford-ma/after-school-coding-programs' component={MedfordProgramsPage} />
        <Route exact path='/medford-ma/girl-scout-badges' component={MedfordGirlsScoutPage} />
        <Route exact path='/medford-ma/technovation-challenge' component={MedfordTechnovationPage} />
        <Route exact path='/medford-ma/code-wiz-locations' component={MedfordLocationsPage} />
        <Route exact path='/medford-ma/acsl-competition' component={MedfordAcslPage} />
        <Route exact path='/medford-ma/virtual-robotics-competition' component={MedfordVirtualRoboticsPage} />
        <Route exact path='/medford-ma/welcome-message' component={MedfordWelcomePage} />
        <Redirect from="/medford-ma/sessions" to="/medford-ma/camps" />
        <Redirect from="/medford-ma/sessions-new" to="/medford-ma/camps" />
        <Route exact path='/medford-ma/camps' component={MedfordSummerPage} />
        <Route exact path='/medford-ma/parks-and-rec-partnerships' component={MedfordOnlineFallCodingPage} />
        <Redirect from="/medford-ma/fall-classes" to="/medford-ma/online-classes" />
        <Route exact path='/medford-ma/online-classes' component={MedfordFallClassesPage} />
        <Route exact path='/medford-ma/first-lego-league' component={MedfordLegoLeaguePage} />
        <Route exact path='/medford-ma/blog' component={MedfordBlogPage} />
        <Route exact path='/medford-ma/acsl' component={MedfordAcslPage} />
        <Route exact path='/medford-ma/privacy-policy' component={MedfordPrivacyPolicyPage} />
        <Route exact path='/medford-ma/thank-you' component={MedfordThankYouPage} />
        <Route exact path='/medford-ma/in-person-classes' component={MedfordInPersonClasses} />
        {/* <Route exact path='/medford-ma/pricing' component={MedfordPricingPage} /> */}

        {/* Needham MA */}
        <Route exact path='/needham-ma/about-us' component={NeedhamAboutPage} />
        <Route exact path='/needham-ma/birthday-parties' component={NeedhamBirthdayPartiesPage} />
        <Route exact path='/needham-ma/coding-robotics-competitions-for-kids' component={NeedhamCompetitionsPage} />
        <Route exact path='/needham-ma/contact-us' component={NeedhamContactPage} />
        <Route exact path='/needham-ma/faqs' component={NeedhamFaqsPage} />
        <Route exact path='/needham-ma/findmycourse' component={NeedhamFindACoursePage} />
        <Route exact path='/needham-ma/after-school-coding-programs' component={NeedhamProgramsPage} />
        <Route exact path='/needham-ma/girl-scout-badges' component={NeedhamGirlsScoutPage} />
        <Route exact path='/needham-ma/technovation-challenge' component={NeedhamTechnovationPage} />
        <Route exact path='/needham-ma/code-wiz-locations' component={NeedhamLocationsPage} />
        <Route exact path='/needham-ma/acsl-competition' component={NeedhamAcslPage} />
        <Route exact path='/needham-ma/virtual-robotics-competition' component={NeedhamVirtualRoboticsPage} />
        <Route exact path='/needham-ma/welcome-message' component={NeedhamWelcomePage} />
        <Redirect from="/needham-ma/sessions" to="/needham-ma/camps" />
        <Redirect from="/needham-ma/sessions-new" to="/needham-ma/camps" />
        <Route exact path='/needham-ma/camps' component={NeedhamSummerPage} />
        <Route exact path='/needham-ma/parks-and-rec-partnerships' component={NeedhamOnlineFallCodingPage} />
        <Redirect from="/needham-ma/fall-classes" to="/needham-ma/online-classes" />
        <Route exact path='/needham-ma/online-classes' component={NeedhamFallClassesPage} />
        <Route exact path='/needham-ma/first-lego-league' component={NeedhamLegoLeaguePage} />
        <Route exact path='/needham-ma/blog' component={NeedhamBlogPage} />
        <Route exact path='/needham-ma/acsl' component={NeedhamAcslPage} />
        <Route exact path='/needham-ma/privacy-policy' component={NeedhamPrivacyPolicyPage} />
        <Route exact path='/needham-ma/thank-you' component={NeedhamThankYouPage} />
        <Route exact path='/needham-ma/in-person-classes' component={NeedhamInPersonClasses} />
        {/* <Route exact path='/needham-ma/pricing' component={NeedhamPricingPage} /> */}

        {/* Lawrenceville GA */}
        <Route exact path='/lawrenceville-ga/about-us' component={LawrencevilleAboutPage} />
        <Route exact path='/lawrenceville-ga/birthday-parties' component={LawrencevilleBirthdayPartiesPage} />
        <Route exact path='/lawrenceville-ga/coding-robotics-competitions-for-kids' component={LawrencevilleCompetitionsPage} />
        <Route exact path='/lawrenceville-ga/contact-us' component={LawrencevilleContactPage} />
        <Route exact path='/lawrenceville-ga/faqs' component={LawrencevilleFaqsPage} />
        <Route exact path='/lawrenceville-ga/findmycourse' component={LawrencevilleFindACoursePage} />
        <Route exact path='/lawrenceville-ga/after-school-coding-programs' component={LawrencevilleProgramsPage} />
        <Route exact path='/lawrenceville-ga/girl-scout-badges' component={LawrencevilleGirlsScoutPage} />
        <Route exact path='/lawrenceville-ga/technovation-challenge' component={LawrencevilleTechnovationPage} />
        <Route exact path='/lawrenceville-ga/code-wiz-locations' component={LawrencevilleLocationsPage} />
        <Route exact path='/lawrenceville-ga/acsl-competition' component={LawrencevilleAcslPage} />
        <Route exact path='/lawrenceville-ga/virtual-robotics-competition' component={LawrencevilleVirtualRoboticsPage} />
        <Route exact path='/lawrenceville-ga/welcome-message' component={LawrencevilleWelcomePage} />
        <Redirect from="/lawrenceville-ga/sessions" to="/lawrenceville-ga/camps" />
        <Redirect from="/lawrenceville-ga/sessions-new" to="/lawrenceville-ga/camps" />
        <Route exact path='/lawrenceville-ga/camps' component={LawrencevilleSummerPage} />
        <Route exact path='/lawrenceville-ga/parks-and-rec-partnerships' component={LawrencevilleOnlineFallCodingPage} />
        <Redirect from="/lawrenceville-ga/fall-classes" to="/lawrenceville-ga/online-classes" />
        <Route exact path='/lawrenceville-ga/online-classes' component={LawrencevilleFallClassesPage} />
        <Route exact path='/lawrenceville-ga/first-lego-league' component={LawrencevilleLegoLeaguePage} />
        <Route exact path='/lawrenceville-ga/blog' component={LawrencevilleBlogPage} />
        <Route exact path='/lawrenceville-ga/acsl' component={LawrencevilleAcslPage} />
        <Route exact path='/lawrenceville-ga/privacy-policy' component={LawrencevillePrivacyPolicyPage} />
        <Route exact path='/lawrenceville-ga/thank-you' component={LawrencevilleThankYouPage} />
        <Route exact path='/lawrenceville-ga/in-person-classes' component={LawrencevilleInPersonClasses} />
        {/* <Route exact path='/lawrenceville-ga/pricing' component={LawrencevillePricingPage} /> */}

        {/* Plano TX */}
        <Route exact path='/plano-tx/about-us' component={PlanoAboutPage} />
        <Route exact path='/plano-tx/birthday-parties' component={PlanoBirthdayPartiesPage} />
        <Route exact path='/plano-tx/coding-robotics-competitions-for-kids' component={PlanoCompetitionsPage} />
        <Route exact path='/plano-tx/contact-us' component={PlanoContactPage} />
        <Route exact path='/plano-tx/faqs' component={PlanoFaqsPage} />
        <Route exact path='/plano-tx/findmycourse' component={PlanoFindACoursePage} />
        <Route exact path='/plano-tx/after-school-coding-programs' component={PlanoProgramsPage} />
        <Route exact path='/plano-tx/girl-scout-badges' component={PlanoGirlsScoutPage} />
        <Route exact path='/plano-tx/technovation-challenge' component={PlanoTechnovationPage} />
        <Route exact path='/plano-tx/code-wiz-locations' component={PlanoLocationsPage} />
        <Route exact path='/plano-tx/acsl-competition' component={PlanoAcslPage} />
        <Route exact path='/plano-tx/virtual-robotics-competition' component={PlanoVirtualRoboticsPage} />
        <Route exact path='/plano-tx/welcome-message' component={PlanoWelcomePage} />
        <Redirect from="/plano-tx/sessions" to="/plano-tx/camps" />
        <Redirect from="/plano-tx/sessions-new" to="/plano-tx/camps" />
        <Route exact path='/plano-tx/camps' component={PlanoSummerPage} />
        <Route exact path='/plano-tx/parks-and-rec-partnerships' component={PlanoOnlineFallCodingPage} />
        <Redirect from="/plano-tx/fall-classes" to="/plano-tx/online-classes" />
        <Route exact path='/plano-tx/online-classes' component={PlanoFallClassesPage} />
        <Route exact path='/plano-tx/first-lego-league' component={PlanoLegoLeaguePage} />
        <Route exact path='/plano-tx/blog' component={PlanoBlogPage} />
        <Route exact path='/plano-tx/acsl' component={PlanoAcslPage} />
        <Route exact path='/plano-tx/privacy-policy' component={PlanoPrivacyPolicyPage} />
        <Route exact path='/plano-tx/thank-you' component={PlanoThankYouPage} />
        <Route exact path='/plano-tx/in-person-classes' component={PlanoInPersonClasses} />
        {/* <Route exact path='/plano-tx/pricing' component={PlanoPricingPage} /> */}

        {/* St. Johns FL */}
        <Route exact path='/stjohns-fl/about-us' component={StjohnsAboutPage} />
        <Route exact path='/stjohns-fl/birthday-parties' component={StjohnsBirthdayPartiesPage} />
        <Route exact path='/stjohns-fl/coding-robotics-competitions-for-kids' component={StjohnsCompetitionsPage} />
        <Route exact path='/stjohns-fl/contact-us' component={StjohnsContactPage} />
        <Route exact path='/stjohns-fl/faqs' component={StjohnsFaqsPage} />
        <Route exact path='/stjohns-fl/findmycourse' component={StjohnsFindACoursePage} />
        <Route exact path='/stjohns-fl/after-school-coding-programs' component={StjohnsProgramsPage} />
        <Route exact path='/stjohns-fl/girl-scout-badges' component={StjohnsGirlsScoutPage} />
        <Route exact path='/stjohns-fl/technovation-challenge' component={StjohnsTechnovationPage} />
        <Route exact path='/stjohns-fl/code-wiz-locations' component={StjohnsLocationsPage} />
        <Route exact path='/stjohns-fl/acsl-competition' component={StjohnsAcslPage} />
        <Route exact path='/stjohns-fl/virtual-robotics-competition' component={StjohnsVirtualRoboticsPage} />
        <Route exact path='/stjohns-fl/welcome-message' component={StjohnsWelcomePage} />
        <Redirect from="/stjohns-fl/sessions" to="/stjohns-fl/camps" />
        <Redirect from="/stjohns-fl/sessions-new" to="/stjohns-fl/camps" />
        <Route exact path='/stjohns-fl/camps' component={StjohnsSummerPage} />
        <Route exact path='/stjohns-fl/parks-and-rec-partnerships' component={StjohnsOnlineFallCodingPage} />
        <Redirect from="/stjohns-fl/fall-classes" to="/stjohns-fl/online-classes" />
        <Route exact path='/stjohns-fl/online-classes' component={StjohnsFallClassesPage} />
        <Route exact path='/stjohns-fl/first-lego-league' component={StjohnsLegoLeaguePage} />
        <Route exact path='/stjohns-fl/blog' component={StjohnsBlogPage} />
        <Route exact path='/stjohns-fl/acsl' component={StjohnsAcslPage} />
        <Route exact path='/stjohns-fl/privacy-policy' component={StjohnsPrivacyPolicyPage} />
        <Route exact path='/stjohns-fl/thank-you' component={StjohnsThankYouPage} />
        <Route exact path='/stjohns-fl/in-person-classes' component={StjohnsInPersonClasses} />
        {/* <Route exact path='/stjohns-fl/pricing' component={StjohnsPricingPage} /> */}

        {/* Houston TX (Oak Forest) */}
        <Route exact path='/oakforest-tx/about-us' component={HoustonAboutPage} />
        <Route exact path='/oakforest-tx/birthday-parties' component={HoustonBirthdayPartiesPage} />
        <Route exact path='/oakforest-tx/coding-robotics-competitions-for-kids' component={HoustonCompetitionsPage} />
        <Route exact path='/oakforest-tx/contact-us' component={HoustonContactPage} />
        <Route exact path='/oakforest-tx/faqs' component={HoustonFaqsPage} />
        <Route exact path='/oakforest-tx/findmycourse' component={HoustonFindACoursePage} />
        <Route exact path='/oakforest-tx/after-school-coding-programs' component={HoustonProgramsPage} />
        <Route exact path='/oakforest-tx/girl-scout-badges' component={HoustonGirlsScoutPage} />
        <Route exact path='/oakforest-tx/technovation-challenge' component={HoustonTechnovationPage} />
        <Route exact path='/oakforest-tx/code-wiz-locations' component={HoustonLocationsPage} />
        <Route exact path='/oakforest-tx/acsl-competition' component={HoustonAcslPage} />
        <Route exact path='/oakforest-tx/virtual-robotics-competition' component={HoustonVirtualRoboticsPage} />
        <Route exact path='/oakforest-tx/welcome-message' component={HoustonWelcomePage} />
        <Redirect from="/oakforest-tx/sessions" to="/oakforest-tx/camps" />
        <Redirect from="/oakforest-tx/sessions-new" to="/oakforest-tx/camps" />
        <Route exact path='/oakforest-tx/camps' component={HoustonSummerPage} />
        <Route exact path='/oakforest-tx/parks-and-rec-partnerships' component={HoustonOnlineFallCodingPage} />
        <Redirect from="/oakforest-tx/fall-classes" to="/oakforest-tx/online-classes" />
        <Route exact path='/oakforest-tx/online-classes' component={HoustonFallClassesPage} />
        <Route exact path='/oakforest-tx/first-lego-league' component={HoustonLegoLeaguePage} />
        <Route exact path='/oakforest-tx/blog' component={HoustonBlogPage} />
        <Route exact path='/oakforest-tx/acsl' component={HoustonAcslPage} />
        <Route exact path='/oakforest-tx/privacy-policy' component={HoustonPrivacyPolicyPage} />
        <Route exact path='/oakforest-tx/thank-you' component={HoustonThankYouPage} />
        <Route exact path='/oakforest-tx/in-person-classes' component={HoustonInPersonClasses} />
        {/* <Route exact path='/oakforest-tx/pricing' component={HoustonPricingPage} /> */}

        {/* Tierrasanta CA */}
        <Route exact path='/tierrasanta-ca/about-us' component={TierrasantaAboutPage} />
        <Route exact path='/tierrasanta-ca/birthday-parties' component={TierrasantaBirthdayPartiesPage} />
        <Route exact path='/tierrasanta-ca/coding-robotics-competitions-for-kids' component={TierrasantaCompetitionsPage} />
        <Route exact path='/tierrasanta-ca/contact-us' component={TierrasantaContactPage} />
        <Route exact path='/tierrasanta-ca/faqs' component={TierrasantaFaqsPage} />
        <Route exact path='/tierrasanta-ca/findmycourse' component={TierrasantaFindACoursePage} />
        <Route exact path='/tierrasanta-ca/after-school-coding-programs' component={TierrasantaProgramsPage} />
        <Route exact path='/tierrasanta-ca/girl-scout-badges' component={TierrasantaGirlsScoutPage} />
        <Route exact path='/tierrasanta-ca/technovation-challenge' component={TierrasantaTechnovationPage} />
        <Route exact path='/tierrasanta-ca/code-wiz-locations' component={TierrasantaLocationsPage} />
        <Route exact path='/tierrasanta-ca/acsl-competition' component={TierrasantaAcslPage} />
        <Route exact path='/tierrasanta-ca/virtual-robotics-competition' component={TierrasantaVirtualRoboticsPage} />
        <Route exact path='/tierrasanta-ca/welcome-message' component={TierrasantaWelcomePage} />
        <Redirect from="/tierrasanta-ca/sessions" to="/tierrasanta-ca/camps" />
        <Redirect from="/tierrasanta-ca/sessions-new" to="/tierrasanta-ca/camps" />
        <Route exact path='/tierrasanta-ca/camps' component={TierrasantaSummerPage} />
        <Route exact path='/tierrasanta-ca/parks-and-rec-partnerships' component={TierrasantaOnlineFallCodingPage} />
        <Redirect from="/tierrasanta-ca/fall-classes" to="/tierrasanta-ca/online-classes" />
        <Route exact path='/tierrasanta-ca/online-classes' component={TierrasantaFallClassesPage} />
        <Route exact path='/tierrasanta-ca/first-lego-league' component={TierrasantaLegoLeaguePage} />
        <Route exact path='/tierrasanta-ca/blog' component={TierrasantaBlogPage} />
        <Route exact path='/tierrasanta-ca/acsl' component={TierrasantaAcslPage} />
        <Route exact path='/tierrasanta-ca/privacy-policy' component={TierrasantaPrivacyPolicyPage} />
        <Route exact path='/tierrasanta-ca/thank-you' component={TierrasantaThankYouPage} />
        <Route exact path='/tierrasanta-ca/in-person-classes' component={TierrasantaInPersonClasses} />
        {/* <Route exact path='/tierrasanta-ca/pricing' component={TierrasantaPricingPage} />

        {/* Durham NC */}
        <Route exact path='/durham-nc/about-us' component={DurhamAboutPage} />
        <Route exact path='/durham-nc/birthday-parties' component={DurhamBirthdayPartiesPage} />
        <Route exact path='/durham-nc/coding-robotics-competitions-for-kids' component={DurhamCompetitionsPage} />
        <Route exact path='/durham-nc/contact-us' component={DurhamContactPage} />
        <Route exact path='/durham-nc/faqs' component={DurhamFaqsPage} />
        <Route exact path='/durham-nc/findmycourse' component={DurhamFindACoursePage} />
        <Route exact path='/durham-nc/after-school-coding-programs' component={DurhamProgramsPage} />
        <Route exact path='/durham-nc/girl-scout-badges' component={DurhamGirlsScoutPage} />
        <Route exact path='/durham-nc/technovation-challenge' component={DurhamTechnovationPage} />
        <Route exact path='/durham-nc/code-wiz-locations' component={DurhamLocationsPage} />
        <Route exact path='/durham-nc/acsl-competition' component={DurhamAcslPage} />
        <Route exact path='/durham-nc/virtual-robotics-competition' component={DurhamVirtualRoboticsPage} />
        <Route exact path='/durham-nc/welcome-message' component={DurhamWelcomePage} />
        <Redirect from="/durham-nc/sessions" to="/durham-nc/camps" />
        <Redirect from="/durham-nc/sessions-new" to="/durham-nc/camps" />
        <Route exact path='/durham-nc/camps' component={DurhamSummerPage} />
        <Route exact path='/durham-nc/parks-and-rec-partnerships' component={DurhamOnlineFallCodingPage} />
        <Redirect from="/durham-nc/fall-classes" to="/durham-nc/online-classes" />
        <Route exact path='/durham-nc/online-classes' component={DurhamFallClassesPage} />
        <Route exact path='/durham-nc/first-lego-league' component={DurhamLegoLeaguePage} />
        <Route exact path='/durham-nc/blog' component={DurhamBlogPage} />
        <Route exact path='/durham-nc/acsl' component={DurhamAcslPage} />
        <Route exact path='/durham-nc/privacy-policy' component={DurhamPrivacyPolicyPage} />
        <Route exact path='/durham-nc/thank-you' component={DurhamThankYouPage} />
        <Route exact path='/durham-nc/in-person-classes' component={DurhamInPersonClasses} /> 
        {/* <Route exact path='/durham-nc/pricing' component={DurhamPricingPage} /> */}

        {/* FishHawk FL */}
        <Route exact path='/fishhawk-fl/about-us' component={FishHawkAboutPage} />
        <Route exact path='/fishhawk-fl/birthday-parties' component={FishHawkBirthdayPartiesPage} />
        <Route exact path='/fishhawk-fl/coding-robotics-competitions-for-kids' component={FishHawkCompetitionsPage} />
        <Route exact path='/fishhawk-fl/contact-us' component={FishHawkContactPage} />
        <Route exact path='/fishhawk-fl/faqs' component={FishHawkFaqsPage} />
        <Route exact path='/fishhawk-fl/findmycourse' component={FishHawkFindACoursePage} />
        <Route exact path='/fishhawk-fl/after-school-coding-programs' component={FishHawkProgramsPage} />
        <Route exact path='/fishhawk-fl/girl-scout-badges' component={FishHawkGirlsScoutPage} />
        <Route exact path='/fishhawk-fl/technovation-challenge' component={FishHawkTechnovationPage} />
        <Route exact path='/fishhawk-fl/code-wiz-locations' component={FishHawkLocationsPage} />
        <Route exact path='/fishhawk-fl/acsl-competition' component={FishHawkAcslPage} />
        <Route exact path='/fishhawk-fl/virtual-robotics-competition' component={FishHawkVirtualRoboticsPage} />
        <Route exact path='/fishhawk-fl/welcome-message' component={FishHawkWelcomePage} />
        <Redirect from="/fishhawk-fl/sessions" to="/fishhawk-fl/camps" />
        <Redirect from="/fishhawk-fl/sessions-new" to="/fishhawk-fl/camps" />
        <Route exact path='/fishhawk-fl/camps' component={FishHawkSummerPage} />
        <Route exact path='/fishhawk-fl/parks-and-rec-partnerships' component={FishHawkOnlineFallCodingPage} />
        <Redirect from="/fishhawk-fl/fall-classes" to="/fishhawk-fl/online-classes" />
        <Route exact path='/fishhawk-fl/online-classes' component={FishHawkFallClassesPage} />
        <Route exact path='/fishhawk-fl/first-lego-league' component={FishHawkLegoLeaguePage} />
        <Route exact path='/fishhawk-fl/blog' component={FishHawkBlogPage} />
        <Route exact path='/fishhawk-fl/acsl' component={FishHawkAcslPage} />
        <Route exact path='/fishhawk-fl/privacy-policy' component={FishHawkPrivacyPolicyPage} />
        <Route exact path='/fishhawk-fl/thank-you' component={FishHawkThankYouPage} />
        <Route exact path='/fishhawk-fl/in-person-classes' component={FishHawkInPersonClasses} />  
        {/* <Route exact path='/fishhawk-fl/pricing' component={FishHawkPricingPage} />

        {/* Cypress TX */}
        <Route exact path='/cypress-tx/about-us' component={CypressAboutPage} />
        <Route exact path='/cypress-tx/birthday-parties' component={CypressBirthdayPartiesPage} />
        <Route exact path='/cypress-tx/coding-robotics-competitions-for-kids' component={CypressCompetitionsPage} />
        <Route exact path='/cypress-tx/contact-us' component={CypressContactPage} />
        <Route exact path='/cypress-tx/faqs' component={CypressFaqsPage} />
        <Route exact path='/cypress-tx/findmycourse' component={CypressFindACoursePage} />
        <Route exact path='/cypress-tx/after-school-coding-programs' component={CypressProgramsPage} />
        <Route exact path='/cypress-tx/girl-scout-badges' component={CypressGirlsScoutPage} />
        <Route exact path='/cypress-tx/technovation-challenge' component={CypressTechnovationPage} />
        <Route exact path='/cypress-tx/code-wiz-locations' component={CypressLocationsPage} />
        <Route exact path='/cypress-tx/acsl-competition' component={CypressAcslPage} />
        <Route exact path='/cypress-tx/virtual-robotics-competition' component={CypressVirtualRoboticsPage} />
        <Route exact path='/cypress-tx/welcome-message' component={CypressWelcomePage} />
        <Redirect from="/cypress-tx/sessions" to="/cypress-tx/camps" />
        <Redirect from="/cypress-tx/sessions-new" to="/cypress-tx/camps" />
        <Route exact path='/cypress-tx/camps' component={CypressSummerPage} />
        <Route exact path='/cypress-tx/parks-and-rec-partnerships' component={CypressOnlineFallCodingPage} />
        <Redirect from="/cypress-tx/fall-classes" to="/cypress-tx/online-classes" />
        <Route exact path='/cypress-tx/online-classes' component={CypressFallClassesPage} />
        <Route exact path='/cypress-tx/first-lego-league' component={CypressLegoLeaguePage} />
        <Route exact path='/cypress-tx/blog' component={CypressBlogPage} />
        <Route exact path='/cypress-tx/acsl' component={CypressAcslPage} />
        <Route exact path='/cypress-tx/privacy-policy' component={CypressPrivacyPolicyPage} />
        <Route exact path='/cypress-tx/thank-you' component={CypressThankYouPage} />
        <Route exact path='/cypress-tx/in-person-classes' component={CypressInPersonClasses} />
        {/* <Route exact path='/cypress-tx/pricing' component={CypressPricingPage} /> */}

        

        {/* Carmel IN */}
        {/* <Route exact path='/carmel-in/about-us' component={CarmelAboutPage} />
        <Route exact path='/carmel-in/birthday-parties' component={CarmelBirthdayPartiesPage} />
        <Route exact path='/carmel-in/coding-robotics-competitions-for-kids' component={CarmelCompetitionsPage} />
        <Route exact path='/carmel-in/contact-us' component={CarmelContactPage} />
        <Route exact path='/carmel-in/faqs' component={CarmelFaqsPage} />
        <Route exact path='/carmel-in/findmycourse' component={CarmelFindACoursePage} />
        <Route exact path='/carmel-in/after-school-coding-programs' component={CarmelProgramsPage} />
        <Route exact path='/carmel-in/girl-scout-badges' component={CarmelGirlsScoutPage} />
        <Route exact path='/carmel-in/technovation-challenge' component={CarmelTechnovationPage} />
        <Route exact path='/carmel-in/code-wiz-locations' component={CarmelLocationsPage} />
        <Route exact path='/carmel-in/acsl-competition' component={CarmelAcslPage} />
        <Route exact path='/carmel-in/virtual-robotics-competition' component={CarmelVirtualRoboticsPage} />
        <Route exact path='/carmel-in/welcome-message' component={CarmelWelcomePage} />
        <Redirect from="/carmel-in/sessions" to="/carmel-in/camps" />
        <Redirect from="/carmel-in/sessions-new" to="/carmel-in/camps" />
        <Route exact path='/carmel-in/camps' component={CarmelSummerPage} />
        <Route exact path='/carmel-in/parks-and-rec-partnerships' component={CarmelOnlineFallCodingPage} />
        <Redirect from="/carmel-in/fall-classes" to="/carmel-in/online-classes" />
        <Route exact path='/carmel-in/online-classes' component={CarmelFallClassesPage} />
        <Route exact path='/carmel-in/first-lego-league' component={CarmelLegoLeaguePage} />
        <Route exact path='/carmel-in/blog' component={CarmelBlogPage} />
        <Route exact path='/carmel-in/acsl' component={CarmelAcslPage} />
        <Route exact path='/carmel-in/privacy-policy' component={CarmelPrivacyPolicyPage} />
        <Route exact path='/carmel-in/thank-you' component={CarmelThankYouPage} />
        <Route exact path='/carmel-in/in-person-classes' component={CarmelInPersonClasses} /> */}
        {/* <Route exact path='/carmel-in/pricing' component={CarmelPricingPage} /> */}

        {/* Katy TX */}
        <Route exact path='/katy-tx/about-us' component={KatyAboutPage} />
        <Route exact path='/katy-tx/birthday-parties' component={KatyBirthdayPartiesPage} />
        <Route exact path='/katy-tx/coding-robotics-competitions-for-kids' component={KatyCompetitionsPage} />
        <Route exact path='/katy-tx/contact-us' component={KatyContactPage} />
        <Route exact path='/katy-tx/faqs' component={KatyFaqsPage} />
        <Route exact path='/katy-tx/findmycourse' component={KatyFindACoursePage} />
        <Route exact path='/katy-tx/after-school-coding-programs' component={KatyProgramsPage} />
        <Route exact path='/katy-tx/girl-scout-badges' component={KatyGirlsScoutPage} />
        <Route exact path='/katy-tx/technovation-challenge' component={KatyTechnovationPage} />
        <Route exact path='/katy-tx/code-wiz-locations' component={KatyLocationsPage} />
        <Route exact path='/katy-tx/acsl-competition' component={KatyAcslPage} />
        <Route exact path='/katy-tx/virtual-robotics-competition' component={KatyVirtualRoboticsPage} />
        <Route exact path='/katy-tx/welcome-message' component={KatyWelcomePage} />
        <Redirect from="/katy-tx/sessions" to="/katy-tx/camps" />
        <Redirect from="/katy-tx/sessions-new" to="/katy-tx/camps" />
        <Route exact path='/katy-tx/camps' component={KatySummerPage} />
        <Route exact path='/katy-tx/parks-and-rec-partnerships' component={KatyOnlineFallCodingPage} />
        <Redirect from="/katy-tx/fall-classes" to="/katy-tx/online-classes" />
        <Route exact path='/katy-tx/online-classes' component={KatyFallClassesPage} />
        <Route exact path='/katy-tx/first-lego-league' component={KatyLegoLeaguePage} />
        <Route exact path='/katy-tx/blog' component={KatyBlogPage} />
        <Route exact path='/katy-tx/acsl' component={KatyAcslPage} />
        <Route exact path='/katy-tx/privacy-policy' component={KatyPrivacyPolicyPage} />
        <Route exact path='/katy-tx/thank-you' component={KatyThankYouPage} />
        <Route exact path='/katy-tx/in-person-classes' component={KatyInPersonClasses} />  
        {/* <Route exact path='/katy-tx/pricing' component={KatyPricingPage} />

        {/* KellerEast TX */}
        <Route exact path='/kellereast-tx/about-us' component={KellerEastAboutPage} />
        <Route exact path='/kellereast-tx/birthday-parties' component={KellerEastBirthdayPartiesPage} />
        <Route exact path='/kellereast-tx/coding-robotics-competitions-for-kids' component={KellerEastCompetitionsPage} />
        <Route exact path='/kellereast-tx/contact-us' component={KellerEastContactPage} />
        <Route exact path='/kellereast-tx/faqs' component={KellerEastFaqsPage} />
        <Route exact path='/kellereast-tx/findmycourse' component={KellerEastFindACoursePage} />
        <Route exact path='/kellereast-tx/after-school-coding-programs' component={KellerEastProgramsPage} />
        <Route exact path='/kellereast-tx/girl-scout-badges' component={KellerEastGirlsScoutPage} />
        <Route exact path='/kellereast-tx/technovation-challenge' component={KellerEastTechnovationPage} />
        <Route exact path='/kellereast-tx/code-wiz-locations' component={KellerEastLocationsPage} />
        <Route exact path='/kellereast-tx/acsl-competition' component={KellerEastAcslPage} />
        <Route exact path='/kellereast-tx/virtual-robotics-competition' component={KellerEastVirtualRoboticsPage} />
        <Route exact path='/kellereast-tx/welcome-message' component={KellerEastWelcomePage} />
        <Redirect from="/kellereast-tx/sessions" to="/kellereast-tx/camps" />
        <Redirect from="/kellereast-tx/sessions-new" to="/kellereast-tx/camps" />
        <Route exact path='/kellereast-tx/camps' component={KellerEastSummerPage} />
        <Route exact path='/kellereast-tx/parks-and-rec-partnerships' component={KellerEastOnlineFallCodingPage} />
        <Redirect from="/kellereast-tx/fall-classes" to="/kellereast-tx/online-classes" />
        <Route exact path='/kellereast-tx/online-classes' component={KellerEastFallClassesPage} />
        <Route exact path='/kellereast-tx/first-lego-league' component={KellerEastLegoLeaguePage} />
        <Route exact path='/kellereast-tx/blog' component={KellerEastBlogPage} />
        <Route exact path='/kellereast-tx/acsl' component={KellerEastAcslPage} />
        <Route exact path='/kellereast-tx/privacy-policy' component={KellerEastPrivacyPolicyPage} />
        <Route exact path='/kellereast-tx/thank-you' component={KellerEastThankYouPage} />
        <Route exact path='/kellereast-tx/in-person-classes' component={KellerEastInPersonClasses} />  
        {/* <Route exact path='/kellereast-tx/pricing' component={KellerEastPricingPage} />

        {/* Colleyville TX */}
        <Route exact path='/colleyville-tx/about-us' component={ColleyvilleAboutPage} />
        <Route exact path='/colleyville-tx/birthday-parties' component={ColleyvilleBirthdayPartiesPage} />
        <Route exact path='/colleyville-tx/coding-robotics-competitions-for-kids' component={ColleyvilleCompetitionsPage} />
        <Route exact path='/colleyville-tx/contact-us' component={ColleyvilleContactPage} />
        <Route exact path='/colleyville-tx/faqs' component={ColleyvilleFaqsPage} />
        <Route exact path='/colleyville-tx/findmycourse' component={ColleyvilleFindACoursePage} />
        <Route exact path='/colleyville-tx/after-school-coding-programs' component={ColleyvilleProgramsPage} />
        <Route exact path='/colleyville-tx/girl-scout-badges' component={ColleyvilleGirlsScoutPage} />
        <Route exact path='/colleyville-tx/technovation-challenge' component={ColleyvilleTechnovationPage} />
        <Route exact path='/colleyville-tx/code-wiz-locations' component={ColleyvilleLocationsPage} />
        <Route exact path='/colleyville-tx/acsl-competition' component={ColleyvilleAcslPage} />
        <Route exact path='/colleyville-tx/virtual-robotics-competition' component={ColleyvilleVirtualRoboticsPage} />
        <Route exact path='/colleyville-tx/welcome-message' component={ColleyvilleWelcomePage} />
        <Redirect from="/colleyville-tx/sessions" to="/colleyville-tx/camps" />
        <Redirect from="/colleyville-tx/sessions-new" to="/colleyville-tx/camps" />
        <Route exact path='/colleyville-tx/camps' component={ColleyvilleSummerPage} />
        <Route exact path='/colleyville-tx/parks-and-rec-partnerships' component={ColleyvilleOnlineFallCodingPage} />
        <Redirect from="/colleyville-tx/fall-classes" to="/colleyville-tx/online-classes" />
        <Route exact path='/colleyville-tx/online-classes' component={ColleyvilleFallClassesPage} />
        <Route exact path='/colleyville-tx/first-lego-league' component={ColleyvilleLegoLeaguePage} />
        <Route exact path='/colleyville-tx/blog' component={ColleyvilleBlogPage} />
        <Route exact path='/colleyville-tx/acsl' component={ColleyvilleAcslPage} />
        <Route exact path='/colleyville-tx/privacy-policy' component={ColleyvillePrivacyPolicyPage} />
        <Route exact path='/colleyville-tx/thank-you' component={ColleyvilleThankYouPage} />
        <Route exact path='/colleyville-tx/in-person-classes' component={ColleyvilleInPersonClasses} />  
        {/* <Route exact path='/colleyville-tx/pricing' component={ColleyvillePricingPage} /> */}

        {/* Rutherford NJ */}
        <Route exact path='/rutherford-nj/about-us' component={RutherfordAboutPage} />
        <Route exact path='/rutherford-nj/birthday-parties' component={RutherfordBirthdayPartiesPage} />
        <Route exact path='/rutherford-nj/coding-robotics-competitions-for-kids' component={RutherfordCompetitionsPage} />
        <Route exact path='/rutherford-nj/contact-us' component={RutherfordContactPage} />
        <Route exact path='/rutherford-nj/faqs' component={RutherfordFaqsPage} />
        <Route exact path='/rutherford-nj/findmycourse' component={RutherfordFindACoursePage} />
        <Route exact path='/rutherford-nj/after-school-coding-programs' component={RutherfordProgramsPage} />
        <Route exact path='/rutherford-nj/girl-scout-badges' component={RutherfordGirlsScoutPage} />
        <Route exact path='/rutherford-nj/technovation-challenge' component={RutherfordTechnovationPage} />
        <Route exact path='/rutherford-nj/code-wiz-locations' component={RutherfordLocationsPage} />
        <Route exact path='/rutherford-nj/acsl-competition' component={RutherfordAcslPage} />
        <Route exact path='/rutherford-nj/virtual-robotics-competition' component={RutherfordVirtualRoboticsPage} />
        <Route exact path='/rutherford-nj/welcome-message' component={RutherfordWelcomePage} />
        <Redirect from="/rutherford-nj/sessions" to="/rutherford-nj/camps" />
        <Redirect from="/rutherford-nj/sessions-new" to="/rutherford-nj/camps" />
        <Route exact path='/rutherford-nj/camps' component={RutherfordSummerPage} />
        <Route exact path='/rutherford-nj/parks-and-rec-partnerships' component={RutherfordOnlineFallCodingPage} />
        <Redirect from="/rutherford-nj/fall-classes" to="/rutherford-nj/online-classes" />
        <Route exact path='/rutherford-nj/online-classes' component={RutherfordFallClassesPage} />
        <Route exact path='/rutherford-nj/first-lego-league' component={RutherfordLegoLeaguePage} />
        <Route exact path='/rutherford-nj/blog' component={RutherfordBlogPage} />
        <Route exact path='/rutherford-nj/acsl' component={RutherfordAcslPage} />
        <Route exact path='/rutherford-nj/privacy-policy' component={RutherfordPrivacyPolicyPage} />
        <Route exact path='/rutherford-nj/thank-you' component={RutherfordThankYouPage} />
        <Route exact path='/rutherford-nj/in-person-classes' component={RutherfordInPersonClasses} /> 
        {/* <Route exact path='/rutherford-nj/pricing' component={RutherfordPricingPage} />

        {/* Quincy MA */}
        <Route exact path='/quincy-ma/about-us' component={QuincyAboutPage} />
        <Route exact path='/quincy-ma/birthday-parties' component={QuincyBirthdayPartiesPage} />
        <Route exact path='/quincy-ma/coding-robotics-competitions-for-kids' component={QuincyCompetitionsPage} />
        <Route exact path='/quincy-ma/contact-us' component={QuincyContactPage} />
        <Route exact path='/quincy-ma/faqs' component={QuincyFaqsPage} />
        <Route exact path='/quincy-ma/findmycourse' component={QuincyFindACoursePage} />
        <Route exact path='/quincy-ma/after-school-coding-programs' component={QuincyProgramsPage} />
        <Route exact path='/quincy-ma/girl-scout-badges' component={QuincyGirlsScoutPage} />
        <Route exact path='/quincy-ma/technovation-challenge' component={QuincyTechnovationPage} />
        <Route exact path='/quincy-ma/code-wiz-locations' component={QuincyLocationsPage} />
        <Route exact path='/quincy-ma/acsl-competition' component={QuincyAcslPage} />
        <Route exact path='/quincy-ma/virtual-robotics-competition' component={QuincyVirtualRoboticsPage} />
        <Route exact path='/quincy-ma/welcome-message' component={QuincyWelcomePage} />
        <Redirect from="/quincy-ma/sessions" to="/quincy-ma/camps" />
        <Redirect from="/quincy-ma/sessions-new" to="/quincy-ma/camps" />
        <Route exact path='/quincy-ma/camps' component={QuincySummerPage} />
        <Route exact path='/quincy-ma/parks-and-rec-partnerships' component={QuincyOnlineFallCodingPage} />
        <Redirect from="/quincy-ma/fall-classes" to="/quincy-ma/online-classes" />
        <Route exact path='/quincy-ma/online-classes' component={QuincyFallClassesPage} />
        <Route exact path='/quincy-ma/first-lego-league' component={QuincyLegoLeaguePage} />
        <Route exact path='/quincy-ma/blog' component={QuincyBlogPage} />
        <Route exact path='/quincy-ma/acsl' component={QuincyAcslPage} />
        <Route exact path='/quincy-ma/privacy-policy' component={QuincyPrivacyPolicyPage} />
        <Route exact path='/quincy-ma/thank-you' component={QuincyThankYouPage} />
        <Route exact path='/quincy-ma/in-person-classes' component={QuincyInPersonClasses} />  
        {/* <Route exact path='/quincy-ma/pricing' component={QuincyPricingPage} />

        {/* Owasso OK */}
        <Route exact path='/owasso-ok/about-us' component={OwassoAboutPage} />
        <Route exact path='/owasso-ok/birthday-parties' component={OwassoBirthdayPartiesPage} />
        <Route exact path='/owasso-ok/coding-robotics-competitions-for-kids' component={OwassoCompetitionsPage} />
        <Route exact path='/owasso-ok/contact-us' component={OwassoContactPage} />
        <Route exact path='/owasso-ok/faqs' component={OwassoFaqsPage} />
        <Route exact path='/owasso-ok/findmycourse' component={OwassoFindACoursePage} />
        <Route exact path='/owasso-ok/after-school-coding-programs' component={OwassoProgramsPage} />
        <Route exact path='/owasso-ok/girl-scout-badges' component={OwassoGirlsScoutPage} />
        <Route exact path='/owasso-ok/technovation-challenge' component={OwassoTechnovationPage} />
        <Route exact path='/owasso-ok/code-wiz-locations' component={OwassoLocationsPage} />
        <Route exact path='/owasso-ok/acsl-competition' component={OwassoAcslPage} />
        <Route exact path='/owasso-ok/virtual-robotics-competition' component={OwassoVirtualRoboticsPage} />
        <Route exact path='/owasso-ok/welcome-message' component={OwassoWelcomePage} />
        <Redirect from="/owasso-ok/sessions" to="/owasso-ok/camps" />
        <Redirect from="/owasso-ok/sessions-new" to="/owasso-ok/camps" />
        <Route exact path='/owasso-ok/camps' component={OwassoSummerPage} />
        <Route exact path='/owasso-ok/parks-and-rec-partnerships' component={OwassoOnlineFallCodingPage} />
        <Redirect from="/owasso-ok/fall-classes" to="/owasso-ok/online-classes" />
        <Route exact path='/owasso-ok/online-classes' component={OwassoFallClassesPage} />
        <Route exact path='/owasso-ok/first-lego-league' component={OwassoLegoLeaguePage} />
        <Route exact path='/owasso-ok/blog' component={OwassoBlogPage} />
        <Route exact path='/owasso-ok/acsl' component={OwassoAcslPage} />
        <Route exact path='/owasso-ok/privacy-policy' component={OwassoPrivacyPolicyPage} />
        <Route exact path='/owasso-ok/thank-you' component={OwassoThankYouPage} />
        <Route exact path='/owasso-ok/in-person-classes' component={OwassoInPersonClasses} /> 
        {/* <Route exact path='/owasso-ok/pricing' component={OwassoPricingPage} /> */}

        {/* Plainsboro NJ */}
        {/* <Route exact path='/plainsboro-nj/about-us' component={PlainsboroAboutPage} />
        <Route exact path='/plainsboro-nj/birthday-parties' component={PlainsboroBirthdayPartiesPage} />
        <Route exact path='/plainsboro-nj/coding-robotics-competitions-for-kids' component={PlainsboroCompetitionsPage} />
        <Route exact path='/plainsboro-nj/contact-us' component={PlainsboroContactPage} />
        <Route exact path='/plainsboro-nj/faqs' component={PlainsboroFaqsPage} />
        <Route exact path='/plainsboro-nj/findmycourse' component={PlainsboroFindACoursePage} />
        <Route exact path='/plainsboro-nj/after-school-coding-programs' component={PlainsboroProgramsPage} />
        <Route exact path='/plainsboro-nj/girl-scout-badges' component={PlainsboroGirlsScoutPage} />
        <Route exact path='/plainsboro-nj/technovation-challenge' component={PlainsboroTechnovationPage} />
        <Route exact path='/plainsboro-nj/code-wiz-locations' component={PlainsboroLocationsPage} />
        <Route exact path='/plainsboro-nj/acsl-competition' component={PlainsboroAcslPage} />
        <Route exact path='/plainsboro-nj/virtual-robotics-competition' component={PlainsboroVirtualRoboticsPage} />
        <Route exact path='/plainsboro-nj/welcome-message' component={PlainsboroWelcomePage} />
        <Redirect from="/plainsboro-nj/sessions" to="/plainsboro-nj/camps" />
        <Redirect from="/plainsboro-nj/sessions-new" to="/plainsboro-nj/camps" />
        <Route exact path='/plainsboro-nj/camps' component={PlainsboroSummerPage} />
        <Route exact path='/plainsboro-nj/parks-and-rec-partnerships' component={PlainsboroOnlineFallCodingPage} />
        <Redirect from="/plainsboro-nj/fall-classes" to="/plainsboro-nj/online-classes" />
        <Route exact path='/plainsboro-nj/online-classes' component={PlainsboroFallClassesPage} />
        <Route exact path='/plainsboro-nj/first-lego-league' component={PlainsboroLegoLeaguePage} />
        <Route exact path='/plainsboro-nj/blog' component={PlainsboroBlogPage} />
        <Route exact path='/plainsboro-nj/acsl' component={PlainsboroAcslPage} />
        <Route exact path='/plainsboro-nj/privacy-policy' component={PlainsboroPrivacyPolicyPage} />
        <Route exact path='/plainsboro-nj/thank-you' component={PlainsboroThankYouPage} />
        <Route exact path='/plainsboro-nj/in-person-classes' component={PlainsboroInPersonClasses} />  */}
        {/* <Route exact path='/plainsboro-nj/pricing' component={PlainsboroPricingPage} />

        {/* Chandler AZ */}
        {/* <Route exact path='/chandler-az/about-us' component={ChandlerAboutPage} />
        <Route exact path='/chandler-az/birthday-parties' component={ChandlerBirthdayPartiesPage} />
        <Route exact path='/chandler-az/coding-robotics-competitions-for-kids' component={ChandlerCompetitionsPage} />
        <Route exact path='/chandler-az/contact-us' component={ChandlerContactPage} />
        <Route exact path='/chandler-az/faqs' component={ChandlerFaqsPage} />
        <Route exact path='/chandler-az/findmycourse' component={ChandlerFindACoursePage} />
        <Route exact path='/chandler-az/after-school-coding-programs' component={ChandlerProgramsPage} />
        <Route exact path='/chandler-az/girl-scout-badges' component={ChandlerGirlsScoutPage} />
        <Route exact path='/chandler-az/technovation-challenge' component={ChandlerTechnovationPage} />
        <Route exact path='/chandler-az/code-wiz-locations' component={ChandlerLocationsPage} />
        <Route exact path='/chandler-az/acsl-competition' component={ChandlerAcslPage} />
        <Route exact path='/chandler-az/virtual-robotics-competition' component={ChandlerVirtualRoboticsPage} />
        <Route exact path='/chandler-az/welcome-message' component={ChandlerWelcomePage} />
        <Redirect from="/chandler-az/sessions" to="/chandler-az/camps" />
        <Redirect from="/chandler-az/sessions-new" to="/chandler-az/camps" />
        <Route exact path='/chandler-az/camps' component={ChandlerSummerPage} />
        <Route exact path='/chandler-az/parks-and-rec-partnerships' component={ChandlerOnlineFallCodingPage} />
        <Redirect from="/chandler-az/fall-classes" to="/chandler-az/online-classes" />
        <Route exact path='/chandler-az/online-classes' component={ChandlerFallClassesPage} />
        <Route exact path='/chandler-az/first-lego-league' component={ChandlerLegoLeaguePage} />
        <Route exact path='/chandler-az/blog' component={ChandlerBlogPage} />
        <Route exact path='/chandler-az/acsl' component={ChandlerAcslPage} />
        <Route exact path='/chandler-az/privacy-policy' component={ChandlerPrivacyPolicyPage} />
        <Route exact path='/chandler-az/thank-you' component={ChandlerThankYouPage} />
        <Route exact path='/chandler-az/in-person-classes' component={ChandlerInPersonClasses} />   */}
        {/* <Route exact path='/chandler-az/pricing' component={ChandlerPricingPage} />
        
        {/* Blog Pages */}
        <Route exact path='/top-10-reasons-why-a-coding-franchise-is-a-wise-investment' component={ReasonsToInvest} />
        <Route exact path='/screen-time-for-kids-how-to-manage-it-during-distance-learning' component={ScreenTime} />
        <Route exact path='/how-interactive-learning-classes-puts-the-social-into-homeschooling' component={HomeSchooling} />
        <Route exact path='/after-school-coding-classes' component={AfterSchoolCoding} />
        <Route exact path='/tips-to-choose-the-best-after-school-coding-classes-for-kids' component={BestAfterSchool} />
        <Route exact path='/code-wiz-print-3d-masks-amid-covid-19' component={CodewizCommitment} />
        <Route exact path='/kids-coding-and-technology-blog' component={CodewizSchoolForKids} />
        <Route exact path='/covid-19-online-coding-classes' component={Covid19Updates} />
        <Route exact path='/i-am-a-coder-youtube-challenge-2020' component={IAmACoder} />
        <Route exact path='/massachusetts-woman-owned-business' component={MassachusettsWoman} />
        <Route exact path='/minecraft-as-an-education-tool' component={MinecraftEducation} />
        <Route exact path='/minecraft-and-roblox-zoom-video-backgrounds' component={MinecraftRoblox} />
        <Route exact path='/roblox-education-inspiring-future-innovators' component={RobloxEducation} />
        <Route exact path='/engineering-for-kids' component={KidsIntroCoding} />
        <Route exact path='/why-is-coding-important' component={CodingImportance} />
        
        <Route exact path='/15-factors-to-consider-before-buying-a-computer-programming-franchise' component={KeyFactors} />
        <Route exact path='/benefits-of-coding-competitions' component={BenefitsOfCoding} />
        <Route exact path='/the-benefits-of-minecraft-for-kids' component={BenefitsOfMinecraft} />
        <Route exact path='/coding-competitions-for-kids' component={CodingCompetition} />
        <Route exact path='/how-roblox-coding-teaches-kids-skills-that-grooms-them-for-the-future' component={HowRobloxCodingTeach} />
        <Route exact path='/what-is-competitive-coding' component={WhatIsCompetitiveCoding} />
        <Route exact path='/code-a-thon-codeathon' component={CodeAThon} />
        <Route exact path='/how-to-find-the-best-kids-summer-camp-for-your-child/' component={BestKidsSummer} />
        <Route exact path='/kids-coding-languages/' component={LanguagesToLearn} />
        <Route exact path='/why-you-should-send-your-kids-to-a-coding-for-kids-summer-camp/' component={MakeTheSummerCount} />
        {/* <Route exact path='' component={} /> */}

        <Route exact path='/5-gift-idea-for-kids-who-code/' component={FiveGiftIdeas} />
        <Route exact path='/what-age-is-appropriate-for-minecraft/' component={AgeAppropriateMinecraft} />
        <Route exact path='/kids-coding-languages/' component={BestKidsCoding} />
        <Route exact path='/boston-with-kids/' component={BostonKids} />
        <Route exact path='/coding-explained-minecraft/' component={CodingExplainedMinecraft} />
        <Route exact path='/coding-explained-roblox/' component={CodingExplainRoblox} />
        <Route exact path='/coding-with-kids/' component={CodingWithKids} />
        <Route exact path='/women-in-stem-quotes/' component={GirlWhoCodes} />
        <Route exact path='/how-to-keep-kids-engaged-and-productive-over-summer/' component={HowToKeepKidsEngaged} />
        <Route exact path='/kid-birthday-party-places-ideas/' component={KidBirthdayParty} />
        <Route exact path='/learn-math-playing-math-games/' component={LearnMath} />
        <Route exact path='/scratch-programming-for-kids/' component={ScratchProgramming} />
        <Route exact path='/teaching-kids-coding-the-code-advantage/' component={TheCodeAdvantage} />

        <Route exact path='/data-privacy-tips-for-students' component={DataPrivacyTipsForStudent} />
        <Route exact path='/incredible-women-in-tech' component={IncredibleWomenInTech} />
        <Route exact path='/reasons-why-children-with-autism-can-excel-at-coding/' component={AutismAwarenessDay} />
        <Route exact path='/sparking-an-interest-in-game-development' component={FreeGuyGameDev} />
        <Route exact path='/top-coding-languages-and-platforms' component={TopCodingLangs} />
        <Route exact path='/in-demand-programming-careers-for-students-to-explore' component={InDemandCareers} />
        <Route exact path='/coding-from-a-young-age' component={CodingFromAYoungAge} />
        <Route exact path='/the-future-of-AI' component={FutureOfAi} />
        <Route exact path='/teaching-robotics-to-kids-and-First-Lego-League' component={MakeARobot} />
        {/* <Route exact path='/tips- to-learning-to-code' component={LearnToCode} /> */}
        <Route exact path='/shaping-your-childs-future' component={ShapingChildsFuture} />
        

        {/* Redirects */}
        <Redirect from="/westford/" to="/westford-ma/" />
        <Redirect from="/westborough/" to="/westborough-ma/" />
        <Redirect from="/reading/" to="/reading-ma/" />
        <Redirect from="/arlington/" to="/arlington-ma/" />
        <Redirect from="/nashua/" to="/nashua-nh/" />
        <Redirect from="/medford/" to="/medford-ma/" />
        <Redirect from="/jerseycity/" to="/westford-ma/" />
        <Redirect from="/needham/" to="/needham-ma/" />
        <Redirect from="/lawrenceville/" to="/lawrenceville-ga/" />
        <Redirect from="/plano/" to="/plano-tx/" />
        <Redirect from="/stjohns/" to="/stjohns-fl/" />
        <Redirect from="/houston/" to="/houston-tx/" />
        {/* <Redirect from="/durham/" to="/durham-nc/" /> */}
        <Redirect from="/cypress/" to="/cypress-tx/" />
        <Redirect from="/tierrasanta/" to="/tierrasanta-ca/" />
        <Redirect from="/carmel/" to="/carmel-in/" />
        <Redirect from="/katy" to="/katy-tx" />
        <Redirect from="/kellereast" to="/kellereast-tx" />
        <Redirect from="/quincy" to="/quincy-ma" />
        <Redirect from="/colleyville" to="/colleyville-tx" />
        <Redirect from="/rutherford" to="/rutherford-nj" />
        <Redirect from="/plainsboro" to="/plainsboro-nj" />
        <Redirect from="/owasso" to="/owasso-ok" />




        <Redirect from="/arlington-ma/teaching-kids-coding-the-code-advantage/" to="/teaching-kids-coding-the-code-advantage/" />
        <Redirect from="/arlington-ma/scratch-programming-for-kids/" to="/scratch-programming-for-kids/" />
        <Redirect from="/arlington-ma/learn-math-playing-math-games/" to="/learn-math-playing-math-games/" />
        <Redirect from="/arlington-ma/kid-birthday-party-places-ideas/" to="/kid-birthday-party-places-ideas/" />
        <Redirect from="/arlington-ma/how-to-keep-kids-engaged-and-productive-over-summer/" to="/how-to-keep-kids-engaged-and-productive-over-summer/" />
        <Redirect from="/arlington-ma/women-in-stem-quotes/" to="/women-in-stem-quotes/" />
        <Redirect from="/arlington-ma/coding-with-kids/" to="/coding-with-kids/" />
        <Redirect from="/arlington-ma/coding-explained-roblox/" to="/coding-explained-roblox/" />
        <Redirect from="/arlington-ma/coding-explained-minecraft/" to="/coding-explained-minecraft/" />
        <Redirect from="/arlington-ma/boston-with-kids/" to="/boston-with-kids/" />
        <Redirect from="/arlington-ma/kids-coding-languages/" to="/kids-coding-languages/" />
        <Redirect from="/arlington-ma/what-age-is-appropriate-for-minecraft/" to="/what-age-is-appropriate-for-minecraft/" />
        <Redirect from="/arlington-ma/5-gift-idea-for-kids-who-code/" to="/5-gift-idea-for-kids-who-code/" />
        {/* <Redirect from="" to="" /> */}

        {/* <Route exact path='/course-details' component={FindACoursePage} /> */}
        <Redirect from="/course-details" to="/findmycourse" />
        <Redirect from="/arlington-ma/course-details" to="/arlington-ma/findmycourse" />
        <Redirect from="/medford-ma/course-details" to="/medford-ma/findmycourse" />
        <Redirect from="/westford-ma/course-details" to="/westford-ma/findmycourse" />
        <Redirect from="/westborough-ma/course-details" to="/westborough-ma/findmycourse" />
        <Redirect from="/reading-ma/course-details" to="/reading-ma/findmycourse" />
        <Redirect from="/jerseycity-nj/course-details" to="/westford-ma/findmycourse" />
        <Redirect from="/nashua-nh/course-details" to="/nashua-nh/findmycourse" />
        <Redirect from='/needham-ma/course-details' to='/needham-ma/findmycourse' />
        <Redirect from='/lawrenceville-ga/course-details' to='/lawrenceville-ga/findmycourse' />
        <Redirect from='/plano-tx/course-details' to='/plano-tx/findmycourse' />
        <Redirect from='/houston-tx/course-details' to='/houston-tx/findmycourse' />
        <Redirect from='/stjohns-fl/course-details' to='/stjohns-fl/findmycourse' />
        <Redirect from='/durham-nc/course-details' to='/durham-nc/findmycourse' />
        <Redirect from='/cypress-tx/course-details' to='/cypress-tx/findmycourse' />
        <Redirect from='/tierrasanta-ca/course-details' to='/tierrasanta-ca/findmycourse' />
        <Redirect from='/carmel-in/course-details' to='/carmel-in/findmycourse' />
        <Redirect from='/katy-tx/course-details' to='/katy-tx/findmycourse' />
        <Redirect from='/kellereast-tx/course-details' to='/kellereast-tx/findmycourse' />
        <Redirect from="/quincy-ma/course-details" to="/quincy-ma/findmycourse" />
        <Redirect from="/colleyville/course-details" to="/colleyville-tx/findmycourse" />
        <Redirect from="/rutherford/course-details" to="/rutherford-nj/findmycourse" />
        <Redirect from="/plainsboro/course-details" to="/plainsboro-nj/findmycourse" />
        <Redirect from="/owasso/course-details" to="/owasso-ok/findmycourse" />

        <Redirect from="/arlington-ma/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from="/medford-ma/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from="/westford-ma/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from="/westborough-ma/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from="/reading-ma/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from="/jerseycity-nj/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from="/nashua-nh/code-a-thon-codeathon/" to="/code-a-thon-codeathon" />
        <Redirect from='/needham-ma/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/lawrenceville-ga/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/plano-tx/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/houston-tx/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/stjohns-fl/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/durham-nc/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/cypress-tx/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/tierrasanta-ca/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from='/carmel-in/code-a-thon-codeathon' to='/code-a-thon-codeathon' />
        <Redirect from="/colleyville/code-a-thon-codeathon" to="/code-a-thon-codeathon" />
        <Redirect from="/rutherford/code-a-thon-codeathon" to="/code-a-thon-codeathon" />
        <Redirect from="/plainsboro/code-a-thon-codeathon" to="/code-a-thon-codeathon" />
        <Redirect from="/owasso/code-a-thon-codeathon" to="/code-a-thon-codeathon" />

        <Redirect from="/sessions" to="/camps" />
        <Redirect from="/sessions-new" to="/camps" />
        <Redirect from="/summer-coding-programs-for-kids" to="/camps" />
        <Redirect from="/online-coding-classes-for-kids" to="/online-coding-classes-and-camps" />
        <Redirect from="/meeting-space-in-westford-ma" to='/rent-event-space-westford-ma' />

        <Redirect from="/coding-classes-canada" to="/online-coding-classes-and-camps" />
        <Redirect from='/girl-scout-badges' to="/programs" />
        <Redirect from="/arlington-ma/code-wiz-team" to="/arlington-ma" />
        <Redirect from="/medford-ma/code-wiz-team" to="/medford-ma" />
        <Redirect from="/westford-ma/code-wiz-team" to="/westford-ma" />
        <Redirect from="/westborough-ma/code-wiz-team" to="/westborough-ma" />
        <Redirect from="/reading-ma/code-wiz-team" to="/reading-ma" />
        <Redirect from="/jerseycity-nj/code-wiz-team" to="/westford-ma" />
        <Redirect from="/nashua-nh/code-wiz-team" to="/nashua-nh" />
        <Redirect from='/needham-ma/code-wiz-team' to='/needham-ma' />
        <Redirect from='/lawrenceville-ga/code-wiz-team' to='/lawrenceville-ga' />
        <Redirect from='/plano-tx/code-wiz-team' to='/plano-tx' />
        <Redirect from='/houston-tx/code-wiz-team' to='/houston-tx' />
        <Redirect from='/stjohns-fl/code-wiz-team' to='/stjohns-fl' />
        <Redirect from='/durham-nc/code-wiz-team' to='/durham-nc' />
        <Redirect from='/cypress-tx/code-wiz-team' to='/cypress-tx' />
        <Redirect from='/tierrasanta-ca/code-wiz-team' to='/tierrasanta-ca' />
        <Redirect from='/carmel-in/code-wiz-team' to='/carmel-in' />
        <Redirect from='/katy-tx/code-wiz-team' to='/katy-tx' />
        <Redirect from='/kellereast-tx/code-wiz-team' to='/kellereast-tx' />
        <Redirect from="/quincy-ma/code-wiz-team" to="/quincy-ma" />
        <Redirect from="/colleyville/code-wiz-team" to="/colleyville-tx" />
        <Redirect from="/rutherford/code-wiz-team" to="/rutherford-nj" />
        <Redirect from="/plainsboro/code-wiz-team" to="/plainsboro-nj" />
        <Redirect from="/owasso/code-wiz-team" to="/owasso-ok" />

        <Redirect from='/arlington-ma/contact' to="/arlington-ma/contact-us" />
        <Redirect from='/medford-ma/contact' to="/medford-ma/contact-us" />
        <Redirect from='/westford-ma/contact' to="/westford-ma/contact-us" />
        <Redirect from='/westborough-ma/contact' to="/westborough-ma/contact-us" />
        <Redirect from='/reading-ma/contact' to="/reading-ma/contact-us" />
        <Redirect from='/jerseycity-nj/contact' to="/westford-ma/contact-us" />
        <Redirect from='/nashua-nh/contact' to="/nashua-nh/contact-us" />
        <Redirect from='/needham-ma/contact' to='/needham-ma/contact-us' />
        <Redirect from='/lawrenceville-ga/contact' to='/lawrenceville-ga/contact-us' />
        <Redirect from='/plano-tx/contact' to='/plano-tx/contact-us' />
        <Redirect from='/houston-tx/contact' to='/houston-tx/contact-us' />
        <Redirect from='/stjohns-fl/contact' to='/stjohns-fl/contact-us' />
        <Redirect from='/durham-nc/contact' to='/durham-nc/contact-us' />
        <Redirect from='/cypress-tx/contact' to='/cypress-tx/contact-us' />
        <Redirect from='/tierrasanta-ca/contact' to='/tierrasanta-ca/contact-us' />
        <Redirect from='/carmel-in/contact' to='/carmel-in/contact-us' />
        <Redirect from='/katy-tx/contact' to='/katy-tx/contact-us' />
        <Redirect from='/kellereast-tx/contact' to='/kellereast-tx/contact-us' />
        <Redirect from="/quincy-ma/contact" to="/quincy-ma/contact-us" />
        <Redirect from="/colleyville/contact" to="/colleyville-tx/contact-us" />
        <Redirect from="/rutherford/contact" to="/rutherford-nj/contact-us" />
        <Redirect from="/plainsboro/contact" to="/plainsboro-nj/contact-us" />
        <Redirect from="/owasso/contact" to="/owasso-ok/contact-us" />

        <Redirect from='/arlington-ma/competitions' to="/arlington-ma/coding-robotics-competitions-for-kids" />
        <Redirect from='/medford-ma/competitions' to="/medford-ma/coding-robotics-competitions-for-kids" />
        <Redirect from='/westford-ma/competitions' to="/westford-ma/coding-robotics-competitions-for-kids" />
        <Redirect from='/westborough-ma/competitions' to="/westborough-ma/coding-robotics-competitions-for-kids" />
        <Redirect from='/reading-ma/competitions' to="/reading-ma/coding-robotics-competitions-for-kids" />
        <Redirect from='/jerseycity-nj/competitions' to="/westford-ma/coding-robotics-competitions-for-kids" />
        <Redirect from='/nashua-nh/competitions' to="/nashua-nh/coding-robotics-competitions-for-kids" />
        <Redirect from='/needham-ma/competitions' to='/needham-ma/coding-robotics-competitions-for-kids' />
        <Redirect from='/lawrenceville-ga/competitions' to='/lawrenceville-ga/coding-robotics-competitions-for-kids' />
        <Redirect from='/plano-tx/competitions' to='/plano-tx/coding-robotics-competitions-for-kids' />
        <Redirect from='/houston-tx/competitions' to='/houston-tx/coding-robotics-competitions-for-kids' />
        <Redirect from='/stjohns-fl/competitions' to='/stjohns-fl/coding-robotics-competitions-for-kids' />
        <Redirect from='/durham-nc/competitions' to='/durham-nc/coding-robotics-competitions-for-kids' />
        <Redirect from='/cypress-tx/competitions' to='/cypress-tx/coding-robotics-competitions-for-kids' />
        <Redirect from='/tierrasanta-ca/competitions' to='/tierrasanta-ca/coding-robotics-competitions-for-kids' />
        <Redirect from='/carmel-in/competitions' to='/carmel-in/coding-robotics-competitions-for-kids' />
        <Redirect from='/katy-tx/competitions' to='/katy-tx/coding-robotics-competitions-for-kids' />
        <Redirect from='/kellereast-tx/competitions' to='/kellereast-tx/coding-robotics-competitions-for-kids' />
        <Redirect from="/quincy-ma/competitions" to="/quincy-ma/coding-robotics-competitions-for-kids" />
        <Redirect from="/colleyville/competitions" to="/colleyville-tx/coding-robotics-competitions-for-kids" />
        <Redirect from="/rutherford/competitions" to="/rutherford-nj/coding-robotics-competitions-for-kids" />
        <Redirect from="/plainsboro/competitions" to="/plainsboro-nj/coding-robotics-competitions-for-kids" />
        <Redirect from="/owasso/competitions" to="/owasso-ok/coding-robotics-competitions-for-kids" />


        <Redirect from='/coding-and-robotics-school-reading-ma/' to="/reading-ma/programs" />
        <Redirect from='/code-a-thon-codeathon/' to='/coding-robotics-competitions-for-kids' />

        <Redirect from='/sp_faq/problem-solving/' to='/faqs' />
        <Redirect from='/westborough-ma/sp_faq/' to='/westborough-ma/faqs' />
        <Redirect from='/westborough-ma/online-coding-classes-for-kids-westborough-ma/' to='/westborough-ma/online-classes' />
        <Redirect from='/arlington-ma/virtual-coding-class-arlington-ma/' to='/arlington-ma/online-classes' />
        <Redirect from='/arlington-ma/programs/game-programming/' to='/arlington-ma/programs' />

        <Redirect from='/coding-and-robotics-for-kids-franchise' to='http://www.codewizfranchise.com' />
        <Redirect from='/kids-build-projects-coding' to='https://students.thecodewiz.com/student-projects/' />
        <Redirect from='/student-portal' to='https://students.thecodewiz.com/student-projects/' />

        <Redirect from='/classes-offered' to='/after-school-coding-programs'/>
        <Redirect from='/programs' to='/after-school-coding-programs'/>
        <Redirect from='/westborough-ma/programs' to='/westborough-ma/after-school-coding-programs'/>
        <Redirect from='/westford-ma/programs' to='/westford-ma/after-school-coding-programs'/>
        <Redirect from='/arlington-ma/programs' to='/arlington-ma/after-school-coding-programs'/>
        <Redirect from='/reading-ma/programs' to='/reading-ma/after-school-coding-programs'/>
        <Redirect from='/medford-ma/programs' to='/medford-ma/after-school-coding-programs'/>
        <Redirect from='/jerseycity-nj/programs' to='/westford-ma/after-school-coding-programs'/>
        <Redirect from='/nashua-nh/programs' to='/nashua-nh/after-school-coding-programs'/>
        <Redirect from='/needham-ma/programs' to='/needham-ma/after-school-coding-programs'/>
        <Redirect from='/lawrenceville-ga/programs' to='/lawrenceville-ga/after-school-coding-programs' />
        <Redirect from='/plano-tx/programs' to='/plano-tx/after-school-coding-programs' />
        <Redirect from='/houston-tx/programs' to='/houston-tx/after-school-coding-programs' />
        <Redirect from='/stjohns-fl/programs' to='/stjohns-fl/after-school-coding-programs' />
        <Redirect from='/durham-nc/programs' to='/durham-nc/after-school-coding-programs' />
        <Redirect from='/cypress-tx/programs' to='/cypress-tx/after-school-coding-programs' />
        <Redirect from='/tierrasanta-ca/programs' to='/tierrasanta-ca/after-school-coding-programs' />
        <Redirect from='/carmel-in/programs' to='/carmel-in/after-school-coding-programs' />
        <Redirect from='/katy-tx/programs' to='/katy-tx/first-after-school-coding-programs' />
        <Redirect from='/kellereast-tx/programs' to='/kellereast-tx/first-after-school-coding-programs' />
        <Redirect from='/quincy-ma/programs' to='/quincy-ma/after-school-coding-programs'/>
        <Redirect from="/colleyville/programs" to="/colleyville-tx/first-after-school-coding-programs" />
        <Redirect from="/rutherford/programs" to="/rutherford-nj/first-after-school-coding-programs" />
        <Redirect from="/plainsboro/programs" to="/plainsboro-nj/first-after-school-coding-programs" />
        <Redirect from="/owasso/programs" to="/owasso-ok/first-after-school-coding-programs" />
        

        <Redirect from='/westborough-ma/lego-league' to='/westborough-ma/first-lego-league' />
        <Redirect from='/westford-ma/lego-league' to='/westford-ma/first-lego-league' />
        <Redirect from='/arlington-ma/lego-league' to='/arlington-ma/first-lego-league' />
        <Redirect from='/reading-ma/lego-league' to='/reading-ma/first-lego-league' />
        <Redirect from='/medford-ma/lego-league' to='/medford-ma/first-lego-league' />
        <Redirect from='/jerseycity-nj/lego-league' to='/westford-ma/first-lego-league' />
        <Redirect from='/nashua-nh/lego-league' to='/nashua-nh/first-lego-league' />
        <Redirect from='/needham-ma/lego-league' to='/needham-ma/first-lego-league' />
        <Redirect from='/lawrenceville-ga/lego-league' to='/lawrenceville-ga/first-lego-league' />
        <Redirect from='/plano-tx/lego-league' to='/plano-tx/first-lego-league' />
        <Redirect from='/houston-tx/lego-league' to='/houston-tx/first-lego-league' />
        <Redirect from='/stjohns-fl/lego-league' to='/stjohns-fl/first-lego-league' />
        <Redirect from='/durham-nc/lego-league' to='/durham-nc/first-lego-league' />
        <Redirect from='/cypress-tx/lego-league' to='/cypress-tx/first-lego-league' />
        <Redirect from='/tierrasanta-ca/lego-league' to='/tierrasanta-ca/first-lego-league' />
        <Redirect from='/carmel-in/lego-league' to='/carmel-in/first-lego-league' />
        <Redirect from='/katy-tx/lego-league' to='/katy-tx/first-lego-league' />
        <Redirect from='/kellereast-tx/lego-league' to='/kellereast-tx/first-lego-league' />
        <Redirect from='/quincy-ma/lego-league' to='/quincy-ma/first-lego-league' />
        <Redirect from="/colleyville/lego-league" to="/colleyville-tx/first-lego-league" />
        <Redirect from="/rutherford/lego-league" to="/rutherford-nj/first-lego-league" />
        <Redirect from="/plainsboro/lego-league" to="/plainsboro-nj/first-lego-league" />
        <Redirect from="/owasso/lego-league" to="/owasso-ok/first-lego-league" />

        {/* Forwarding Houston Links to Oak Forest */}
        <Redirect from='/houston-tx' to='/oakforest-tx' />
        <Redirect from='/oakforest' to='/oakforest-tx' />
        <Redirect from='/houston-tx/about-us' to='/oakforest-tx/about-us' />
        <Redirect from='/houston-tx/birthday-parties' to='/oakforest-tx/birthday-parties' />
        <Redirect from='/houston-tx/coding-robotics-competitions-for-kids' to='/oakforest-tx/coding-robotics-competitions-for-kids' />
        <Redirect from='/houston-tx/contact-us' to='/oakforest-tx/contact-us' />
        <Redirect from='/houston-tx/faqs' to='/oakforest-tx/faqs' />
        <Redirect from='/houston-tx/findmycourse' to='/oakforest-tx/findmycourse' />
        <Redirect from='/houston-tx/after-school-coding-programs' to='/oakforest-tx/after-school-coding-programs' />
        <Redirect from='/houston-tx/girl-scout-badges' to='/oakforest-tx/girl-scout-badges' />
        <Redirect from='/houston-tx/technovation-challenge' to='/oakforest-tx/technovation-challenge' />
        <Redirect from='/houston-tx/code-wiz-locations' to='/oakforest-tx/code-wiz-locations' />
        <Redirect from='/houston-tx/acsl-competition' to='/oakforest-tx/acsl-competition' />
        <Redirect from='/houston-tx/virtual-robotics-competition' to='/oakforest-tx/virtual-robotics-competition' />
        <Redirect from='/houston-tx/welcome-message' to='/oakforest-tx/welcome-message' />
        <Redirect from='/houston-tx/camps' to='/oakforest-tx/camps' />
        <Redirect from='/houston-tx/parks-and-rec-partnerships' to='/oakforest-tx/parks-and-rec-partnerships' />
        <Redirect from="/houston-tx/fall-classes" to="/houston-tx/online-classes" />
        <Redirect from='/houston-tx/online-classes' to='/oakforest-tx/online-classes' />
        <Redirect from='/houston-tx/first-lego-league' to='/oakforest-tx/first-lego-league' />
        <Redirect from='/houston-tx/blog' to='/oakforest-tx/blog' />
        <Redirect from='/houston-tx/acsl' to='/oakforest-tx/acsl' />
        <Redirect from='/houston-tx/privacy-policy' to='/oakforest-tx/privacy-policy' />
        <Redirect from='/houston-tx/thank-you' to='/oakforest-tx/thank-you' />
        <Redirect from='/houston-tx/in-person-classes' to='/oakforest-tx/in-person-classes' />
        {/* <Redirect from='/houston-tx/pricing' to={HoustonPricingPage} /> */}

        {/* Forwarding Jersey City NJ to Westford MA */}
        <Redirect from='/online-fall-classes-for-kids-in-jerseycity-nj' to='/online-fall-classes-for-kids-in-westford-ma' />
        <Redirect from='/coding-school-for-kids-jerseycity-nj' to='/coding-school-for-kids-westford-ma'/>
        <Redirect from='/jerseycity-nj' to='/westford-ma' />
        <Redirect from='/jerseycity-nj/about-us' to='/westford-ma/about-us' />
        <Redirect from='/jerseycity-nj/birthday-parties' to='/westford-ma/birthday-parties' />
        <Redirect from='/jerseycity-nj/coding-robotics-competitions-for-kids' to='/westford-ma/coding-robotics-competitions-for-kids' />
        <Redirect from='/jerseycity-nj/contact-us' to='/westford-ma/contact-us' />
        <Redirect from='/jerseycity-nj/faqs' to='/westford-ma/faqs' />
        <Redirect from='/jerseycity-nj/findmycourse' to='/westford-ma/findmycourse' />
        <Redirect from='/jerseycity-nj/after-school-coding-programs' to='/westford-ma/after-school-coding-programs' />
        <Redirect from='/jerseycity-nj/girl-scout-badges' to='/westford-ma/girl-scout-badges' />
        <Redirect from='/jerseycity-nj/technovation-challenge' to='/westford-ma/technovation-challenge' />
        <Redirect from='/jerseycity-nj/code-wiz-locations' to='/westford-ma/code-wiz-locations' />
        <Redirect from='/jerseycity-nj/acsl-competition' to='/westford-ma/acsl-competition' />
        <Redirect from='/jerseycity-nj/virtual-robotics-competition' to='/westford-ma/virtual-robotics-competition' />
        <Redirect from='/jerseycity-nj/welcome-message' to='/westford-ma/welcome-message' />
        <Redirect from="/jerseycity-ma/sessions" to="/westford-ma/camps" />
        <Redirect from="/jerseycity-ma/sessions-new" to="/westford-ma/camps" />
        <Redirect from='/jerseycity-nj/camps' to='/westford-ma/camps' />
        <Redirect from='/jerseycity-nj/parks-and-rec-partnerships' to='/westford-ma/parks-and-rec-partnerships' />
        <Redirect from="/jerseycity-nj/fall-classes" to="/westford-ma/online-classes" />
        <Redirect from='/jerseycity-nj/online-classes' to='/westford-ma/online-classes' />
        <Redirect from='/jerseycity-nj/first-lego-league' to='/westford-ma/first-lego-league' />
        <Redirect from='/jerseycity-nj/blog' to='/westford-ma/blog' />
        <Redirect from='/jerseycity-nj/acsl' to='/westford-ma/acsl' />
        <Redirect from='/jerseycity-nj/privacy-policy' to='/westford-ma/privacy-policy' />
        <Redirect from='/jerseycity-nj/thank-you' to='/westford-ma/thank-you' />
        <Redirect from='/jerseycity-nj/in-person-classes' to='/westford-ma/in-person-classes' />
        {/* <Redirect from='/jerseycity-nj/pricing' to={JerseyPricingPage} /> */}

        {/* <Redirect from='/static/media/Westboro-Fall-Brochure.f06d14fe.pdf' to='/westborough-ma/fall-schedule' /> */}
        {/* <Redirect from='/rfc' to='https://info.tutordoctorfranchise.com/cw-rfc' /> */}
        <Route exact path="/rfc" render={() => (window.location = "https://info.tutordoctorfranchise.com/cw-rfc")} />
        <Route exact path="/projects" render={() => (window.location = "https://students.thecodewiz.com/student-projects/")} />
        {/* <Route exact path="/westborough-ma/fall-schedule" render={() => (window.location = "https://www.thecodewiz.com/static/media/Westboro-Fall-Brochure.f06d14fe.pdf")} /> */}

        <Route component={NotFound} />
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
