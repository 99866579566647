import React from 'react';
import Select from 'react-select';
import Modal from 'react-modal';

//import CourseBlockDropDown from './course-block-dropdown.component';
import codingcourse from '../../assets/images/coding-course.png';
import competitioncourse from '../../assets/images/competition-course.png';
import gamingcourse from '../../assets/images/gaming-course.png';
import roboticscourse from '../../assets/images/robotics-course.png';
import modalImg from '../../assets/images/course-details-modal.png';
import pythonImg from '../../assets/images/python-logo.png';
import javaImg from '../../assets/images/java-logo.png';
import greenfootImg from '../../assets/images/greenfoot-logo.png';
import htmlcssImg from '../../assets/images/htmlcss-logo.png';
import javascriptImg from '../../assets/images/javascript-logo.png';
import unityImg from '../../assets/images/unity-logo.png';
import technovationImg from '../../assets/images/girls-technovation-logo.png';
import mindstormsImg from '../../assets/images/mindstorms-logo.png';
import fllImg from '../../assets/images/fll-logo.png';
import flljrImg from '../../assets/images/flljr-logo.jpg';
import arduinoImg from '../../assets/images/arduino-logo.png';
import tynkerImg from '../../assets/images/tynker-logo-1.png';
import androidImg from '../../assets/images/android-logo.jpeg';
import wedoImg from '../../assets/images/wedo-logo.jpg';
import microbitImg from '../../assets/images/Microbit-logo.png';
import codestersImg from '../../assets/images/codesters-logo.png';
import codecombatImg from '../../assets/images/code-combat.png';
import minecraftImg from '../../assets/images/minecraft-logo.png';
import robloxImg from '../../assets/images/roblox-bubble.png';

import './course-block.styles.scss';

let codewizCourseDesc = {
    python: {
        id: 10,
        value: 'python',
        label: 'Python - Intro to Text Based Coding',
        image: pythonImg,
        description: 'Python is a great way to start your journey in the world of text based coding. It’s a great first language because it’s concise, easy to read and very versatile. Whatever you want to do, you can do it in Python. Our Python course covers everything you need to know to master the language - from declaring variables to creating tuples. If your goal is to develop problem solving skills, learn a cool technology or develop projects for college applications, Python is a great place to start!',
        concepts: ["Coding in a pure text based environment", "Basic coding concepts such as variables, conditionals, loops, functions and events.", "Adavanced coding concepts - arrays, nested for loops, Objects", "Using an Integrated Development Environment (IDE)", "Problem Solving skills", "Debugging", "Real world applications of Python"],
        skilllevel: 'Basic - Advanced'
    },
    java: {
        id: 11,
        value: 'java',
        label: 'Java - Object Oriented Programming',
        image: javaImg,
        description: "Expand your knowledge and skill set with object-oriented programming in Java. Learning object-oriented Programming will keep your system modular, flexible and scalable. You'll learn about objects, methods, classes and use that to create card game simulations, parking garage software and basically any simulation that requires object interaction. Using your Java skills, you’ll be able to develop an impressive portfolio that cannot be overlooked by colleges. This course will make your AP Computer Science class a breeze!",
        concepts: ["Coding in a pure text based environment", "Basic coding concepts such as variables, conditionals, loops, functions and events.", "Advanced coding concepts - arrays, nested for loops, Objects", "Using an Integrated Development Environment (IDE)", "Problem Solving skills", "Debugging", "Real world applications of Java"],
        skilllevel: 'Intermediate to Advanced'
    },
    htmlcss: {
        id: 13, 
        value: 'htmlcss',
        label: 'Build Your Own Website With HTML/CSS',
        image: htmlcssImg,
        description: "HTML is the building block of every website. If you have an appreciation for good design and have an artistic flair, this would be for you! Learn how to build dynamic, engaging and interactive websites of your choice with the understanding of the HTML framework. You'll start from a simple web page to creating more complex multi page websites using cascading style sheets(CSS) for design and the bootstrap framework to make your website mobile friendly!",
        concepts: ["Learn the HTML framework", "Design websites using CSS", "How to build mobile friendly sites using Bootstrap", "Design thinking skills", "Planning for the user experience", "Debugging and problem solving"],
        skilllevel: 'Basic to Intermediate'
    },
    javascript: {
        id: 29, 
        value: 'javascript',
        name: 'Animations using p5.js',
        image: javascriptImg,
        description: '',
        concepts: ["", "", ""],
        skilllevel: ''
    },
    animations: {
        id: 14, 
        value: 'animations',
        label: 'Animations using P5.js',
        image: javascriptImg,
        description: "Paint and tell a story from your perspective all with code. In our Animations with p5.js course, you'll be able to express your creative side by learning how to create gripping animations and transitions using Javascript. With a snap, you’d create interactive web-based animations that look really cool! In this course, previous students have used code to simulate a sunrise and sunset, simulate the Fibonacci sequence, recreate the classic snake and pong games that can be played directly in the browser. Amazing stuff for your portfolio!",
        concepts: ["Learn to develop web-based JavaScript animation", "Create JavaScript games that can be played on any computer", "Mathematical concepts such as x-y coordinates, negative numbers, angles, percentages, remainders and more!", "Learn the fundamentals of programming - data types, variables, objects, functions, conditionals, etc"],
        skilllevel: 'Basic to Intermediate'
    },
    codesters: {
        id: 25, 
        value: 'codesters',
        label: 'Visual Python (Codesters)',
        image: codestersImg,
        description: "Like the idea of animations, funny storytelling with characters or making 2 player games to share with friends? With our visual Python course, you will get to use a unique drag-to-text platform that guides you through text-based Python in a fun and interactive way using sprites and animations! Whether you're a beginner or have some experience with drag and drop coding, visual Python is a great intermediate step between platforms like Scratch and pure text based courses like Java!",
        concepts: ["Python in a visual format", "Problem solving skills", "Basic coding concepts such as variables, conditionals, loops, functions, events and more!", "Mathematical concepts such as x-y coordinates, negative numbers, angles, percentages, remainders and more!"],
        skilllevel: 'Basic to Intermediate'
    },
    unity: {
        id: 15, 
        value: 'unity',
        label: 'Game Design with Unity',
        image: unityImg,
        description: "Gaming will never be the same again! In our 2D & 3D Game Design class, you'll use the Unity platform and a VR headset, to fuse your imagination with reality and create Virtual Reality and Augmented Reality games with your code. You will also learn how to add creative special filters to your camera feed. Another exciting feature you can learn is how to use hand gestures to interact with the elements of your virtual game. Virtual Reality and Augmented Reality are the hottest technologies out there! Sign up for our class and learn to use the technologies and be a cool programmer!",
        concepts: ["Navigate a 3D game engine", "Build 2D & 3D Unity games from the ground up", "Create Character and Camera Animations", "Cutscenes", "Basic artificial intelligence", "Write C# scripts", "Use variables and function", "Work with gravity and 3D objects", "Gain transferrable general knowledge of game design"],
        skilllevel: 'Intermediate to Advanced'
    },
    technovation: {
        id: 16,
        value: 'technovation',
        label: "Girls Technovation Challenge",
        image: technovationImg,
        description: 'Are you passionate about community issues? Do you want to use technology to create solutions to these issues? Then the Girls Technovation Challenge is for you! You as a young woman (ages 10-18) will be equipped to become a tech entrepreneur and leader. With the support of our coaches, you will work in a team to code mobile apps that address real-world problems.',
        concepts: ["Team Work", "Entrepreneurship", "Public Speaking", "Put into practice the software development lifecycle - starting from planning all the way to maintenance", "Fundamentals of coding", "Coding for a cause"],
        skilllevel: ''
    },
    mindstorms: {
        id: 17,
        value: 'mindstorms',
        label: 'LEGO Mindstorms Robotics',
        image: mindstormsImg,
        description: "Do you like battlebots? if you do, you'll want to take this course. In this course, you'll have fun building LEGO Mindstorms EV3 robots. You'll learn how different sensors, motors and gears work to make robots come to life. You'll learn how to program these robots to perform tasks such as traveling in straight lines, curved lines, following the edge of the line and so much more. You'll get to participate in an epic battlebots challenge with a robot you built! ",
        concepts: ["Design and build your own robot from scratch", "Learn how motors, sensors, and gears work", "Program LEGO Mindstorms EV3", "Soft skills such as team work, problem solving, critical thinking", "Engineering concepts thorough robotics"],
        skilllevel: ''
    },
    fll: {
        id: 18,
        value: 'fll',
        label: 'First Lego League',
        image: fllImg,
        description: 'FLL challenge an international robotics tournament in a cheerful atmosphere! You will be led through a global robotics program to solve real world problems. You will need to solve tricky “missions” with the help of a robot. You will research the given challenge within a team, plan programming and test an autonomous LEGO Mindstorms robot to solve the missions. You will meet to display your robot and compete with other teams at the tournaments. Your team could potentially move forward to compete in the regional, national and international tournaments!',
        concepts: ["Critical thinking, planning and problem solving skils", "Conceptualizing, designing and building LEGO robots from scratch", "Coding skills such as sequencing, conditionals, loops and events", "Team work", "Good sportsmanship", "Solve real-world problem", "Presentation skills"],
        skilllevel: ''
    },
    arduino: {
        id: 19,
        value: 'arduino',
        label: 'Arduino',
        image: arduinoImg,
        description: 'Arduino is an open-source electronics platform based on easy-to-use hardware and software.',
        concepts: ["Program and design apps", "Better understanding ", ""],
        skilllevel: ''
    },
    tynker: {
        id: 20,
        value: 'tynker',
        label: 'Drag n Drop Coding',
        image: tynkerImg,
        description: 'Dive into the world of code by coding your own space shooter games, trivia games, music bands, beautiful computer-generated art and math simulations or tell a story! Perfect for the young beginner, drag n drop coding is the perfect way to start learning to code all while having a good time! Using code blocks that snap together, you can go from newbie to pro without a ton of typing. All you need is a little imagination and you can start to create anything you want including drawing your own characters!',
        concepts: ["Problem solving skills", "Basic coding concepts such as variables, conditionals, loops, functions, events and more!", "Mathematical concepts such as x-y coordinates, negative numbers, angles, percentages, remainders and more!", "Communication skills"],
        skilllevel: 'Basic - Intermediate'
    },
    wedo: {
        id: 21, 
        value: 'wedo',
        label: 'LEGO WeDo Robotics',
        image: wedoImg,
        description: "In our LEGO WeDo Robotics course, you'll have hands-on experience in building STEM projects! Using LEGO block and coding with drag n drop techniques, you'll get to build your own robotic models and create the code to make your robots come alive! You will use motors, motion sensors, tilt sensors to make fun robots! You'll also have the opportunity to participate in FLL Jr. challenge!",
        concepts: ["How to build a variety of LEGO models from Spy robots to Helicopters", "Build motions using motors, motion sensors, and tilt sensors", "Program models with display blocks, flow blocks, motor blocks and inputs"],
        skilllevel: ''
    },
    flljr: {
        id: 22, 
        value: 'flljr',
        label: 'First Lego League Jr.',
        image: flljrImg,
        description: "In FIRST LEGO League Jr. is a project-based program where you will be introduced to science, technology, engineering and math (STEM) concepts in a fun and creative way! You'll have fun investigating and working on a scientific challenge with your team, all through a brand you know and love − LEGO®. You'll get a chance at the celebration event to showcase all that you've learned and the robot that you've built with your team! How exciting!",
        concepts: ["Hands-on STEM learning", "Conceptualizing, designing and building LEGO robots from scratch", "Critical thinking and problem solving skills", "Fundamental coding skills such sequencing, basic conditonals", "Robotics", "Team work"],
        skilllevel: ''
    },
    android: {
        id: 23, 
        value: 'android',
        label: 'Android',
        image: androidImg,
        description: "Spend the day programming fun android mobile apps while gaining the required skills required to be an app developer! Create Doodle apps, basketball trivia, space invaders, and your very own “Google” search engine! You will be able to innovate and also acquire computational skills with the MIT media lab learning environment. The colorful visuals and appealing animations will stir your creativity. When you're done with your app, you can push the app to your mobile device to play with friends and family at home!",
        concepts: ["Learn to build and develop functiomal mobile apps", "Learn to create, design and implement games and apps"],
        skilllevel: ''
    },
    // codesters: {
    //     id: 25, 
    //     value: 'codesters',
    //     label: 'Visual Python',
    //     image: codestersImg,
    //     description: "Like the idea of animations, funny storytelling with characters or making 2 player games to share with friends? Cool! Using the Python programming language, you’ll get to create fun animations and games. We'll first learn the basics of Python and afterward, the extent of your creativity can be explored as you come up with and code your own ideas!",
    //     concepts: ["Create Python applications and animations!", "Problem solving", "Working through loops", "Control statements", "Create their own games and animations"],
    //     skilllevel: ''
    // },
    minecraft: {
        id: 26,
        value: 'minecraft',
        label: 'Minecraft World Builder',
        image: minecraftImg,
        description: 'In our Minecraft World Builder course, you will learn how to run a city, manage money, protect the environment, all within the 3D world of Minecraft! This course is not coding based but a platform for you to learn more about real world economics, city planning, and life management! In Minecraft World Builder, you will be apart of a committee and learn to manage money by building onto your ever growing city with your peers!',
        concepts: ["Team work", "Creativity and Problem Solving", "Real life money management", "Town designing", "Care of the environment", "Economics"],
        skilllevel: 'Basic'
    },
    codecombat: {
        id: 27,
        value: 'codecombat',
        label: 'Game Based Python',
        image: codecombatImg,
        description: "Do you love playing games? Do you want to learn how to code? Well, you are in the right place! In our Game-Based Python course, you'll learn to code by playing a game and solving coding puzzles that get increasingly challenging. As you go, you'll get to create your own game in Python using the skills and problem solving skills you have just gained!",
        concepts: [" Python in a visual format", "Problem solving skills", "Basic coding concepts such as variables, conditionals, loops, functions, events and more!", "Mathematical concepts such as x-y coordinates, negative numbers, angles, percentages, remainders and more!"],
        skilllevel: ''
    },
    roblox: {
        id: 28,
        value: 'roblox',
        label: 'Code Roblox Games',
        image: robloxImg,
        description: "Anyone can build a game in Roblox! Roblox studio has all the tools to make your wildest gaming imaginations a reality. You tell us what game you want to make and we'll teach you the fundamentals and coach you through bringing that game to life! Whether you're new to coding have some experience, you will be challenged and have fun while bringing your game to life. Through drag and drop techniques or pure scripting, you’ll be creating extensions to the Roblox game while fostering your creativity and learning real-world computer coding. ",
        concepts: ["Game design using Roblox Studio", "Learn to use Roblox Studio 3D game engine to design a 3D world", "Write code scripts to program characters and objects in the game", "Finding and fixing errors in their code", "Learn the life cycle of game development (from planning to design to their finished product)", "Mathematical concepts"],
        skilllevel: 'Basic - Advanced'
    },
    arcade: {
        id: 29,
        value: 'arcade',
        label: 'Arcade',
        image: robloxImg,
        description: 'Arcade coding',
        concepts: ["Program within Roblox Studio using LUA programming language", "Learn to use Roblox Studio 3D game engine", "Design 3D world", "Scripting different objects", "Debugging code", "Learn the life cycle of game development (from planning to deisgn to their finished product)"],
        skilllevel: ''
    },
    alexa: {
        id: 30,
        value: 'alexa',
        label: 'Voice Apps for Alexa',
        image: robloxImg,
        description: 'Redefine your voice app experience. Create something uniquely different from anything anyone has ever seen. You’ll be equipped during this course to create global apps for Alexa. You’ll be able to fully incorporate any new feature you dream up into your app. Voiceflow’s drag and drop feature makes this a breeze. Your design could be the next big thing!',
        concepts: ["Program the newest essential in everyone's home, Alexa devices!", "Learn conversation flow, prompts and stop messages", "How to use voiceflow's drag and drop coding", "Programming concepts such as variables, loops, and functions", "Integration with APIs and Google Sheets", "Critical thinking and proboem solving skills", "Marketable and impressive skills"],
        skilllevel: 'Intermediate to Advanced'
    },
    minecraftmodding: {
        id: 26,
        value: 'minecraftmodding',
        label: 'Modding with Minecraft',
        image: minecraftImg,
        description: "Explore, create or survive! Mine deep into the minecraft world and create the future! In our modding with Minecraft course, you'll challenge the odds, craft your own kinds of weapons and build dream worlds using code. You will do this while also learning fundamental programming concepts, level design and problem solving skills. You will make use of ready made coding blocks to create their mini versions of Minecraft games all through out the course.",
        concepts: ["Learn to make mods", "Make your own Texture Packs", "Code using drag and drop or Java", "Problem solving", "Learn transferable programming skills", "Introduction to debugging programs"],
        skilllevel: 'Basic to Advanced'
    },
    artificialintelligence: {
        id: 31, 
        value: 'artificialintelligence',
        label: 'Into to Artificial Intelligence',
        image: robloxImg,
        description: "AI is one of the technology drivers that is shapping our world today. It is important that young people have an understanding of this technology to further innovation, have intelligent conversations and make informed decisions on the proper use of AI. In our AI course, you'll learn the basics of AI, which include robotics, machine learning, reasoning, natural language processing and computer vision. Another benefit of this course is that it will look great on your college applications! This is a win-win!",
        concepts: ["Understand different algorithms", "Use those algorithms in the most efficient manner in order to complete a task.", "Some topic that will be covered include, problem solving, reasoning, machine learning, state space transitions, sorting algorithms, searching algorithms, algorithmic efficiency, big O notation, recursion, MinMax, alpha beta pruning, and many more."],
        skilllevel: ''
    }

};

const customStyles = {
    content : {
        textAlign: 'center'
    }
};

class CourseBlock extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            title: props.title,
            classes: props.classes,
            showModal: false,
            showCol: "showCal",
            selectedOption: '',
            courseOptions: codewizCourseDesc,
            detailHeader: "Test Header",
            detailBody: "Test Body",
            detailConcept: "lists",
            detailImage: modalImg
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange (selector, event) {
        console.log("Selectors", selector);
        // Here you trigger whatever you want
        //showDetails(selector.value);
        let details = this.state.courseOptions[selector.value];
        console.log("Details", details);
        this.setState({ showModal: true, detailHeader: details.label, detailBody: details.description, detailConcept: details.concepts, detailImage: details.image });
    }

    handleOpenModal () {
    this.setState({ showModal: true });
    }
    
    handleCloseModal () {
    this.setState({ showModal: false });
    }
    render() {
        //let upperCaseTitle = this.state.title.toUpperCase();
        
        let title = this.state.title;
        let classes = this.props.classes;
        let upperCaseTitle = title.toUpperCase()
        let startButton = this.state.title+"-start";
        let image = (<div>Hello</div>);
        //let showCol = "";
        let selectedOption =null;
        let courseOptions = [];
        let concepts = [];
        for(let i=0; i<this.state.detailConcept.length; i++){
            concepts.push(<li>{this.state.detailConcept[i]}</li>);
        }
        

        // console.log("course block classes", this.props.classes);
        // console.log("course block title", title);

        if(classes && classes.length > 0){
            for(let i=0; i<classes.length; i++){
                console.log("Courses", codewizCourseDesc[classes[i]]);
                let crs = codewizCourseDesc[classes[i]];
                courseOptions.push({ value: crs.value, label: crs.label }) ;
            }
            this.state.showCol = "showCol";
        } else {
            this.state.showCol = "hideCol";
           // showCol = "hideCol";
        }

        if(title === "coding"){
            image = (<img src={codingcourse} alt="title" />);
        } else if(title === "competitions"){
            image = (<img src={competitioncourse} alt="title" />);
        } else if(title === "gaming"){
            image = (<img src={gamingcourse} alt="title" />);
        } else if(title === "robotics"){
            image = (<img src={roboticscourse} alt="title" />);
        }
        return (
            <div id={this.state.title} className={`col-md-3 ${this.state.showCol}`}>
                <div className="course">
                    <div className="course-img"> 
                        {image}
                    </div>
                    <h4 className="cw-dark-blue">{upperCaseTitle}</h4>
                    <Select
                        value={selectedOption}
                        onChange={event => this.handleChange(event)}
                        options={courseOptions}
                    />
                    {/* <button id={startButton} className="btn orange-button course-btn">SEE DETAILS</button> */}
                </div>
                <Modal 
                isOpen={this.state.showModal}
                contentLabel="Modal #1 Global Style Override Example"
                onRequestClose={this.handleCloseModal}
                style={customStyles}
                >
                    <div className="container">
                        <div className="row"> 
                            <div className="image col-md-12 col-lg-6">
                                <img src={this.state.detailImage} alt="Course details"/>
                            </div>
                            <div className="text col-md-12 col-lg-6">
                                <h3 className="bungee cw-dark-blue">{this.state.detailHeader}</h3>
                                <p>{this.state.detailBody}</p>
                                {/* <h4 className="bungee cw-dark-blue">What Your Children Would Learn</h4>
                                <ul className="modal-concepts-list cw-dark-blue">{concepts}</ul> */}
                                {/* <h4 className="bungee cw-dark-blue">Skill Levels</h4>
                                <ul className="modal-concepts-list cw-dark-blue">{skills}</ul> */}
                            </div>
                            <div className="col-md-12">
                                {/* <br /> */}
                                <br />
                                <button className="btn btn-danger" onClick={this.handleCloseModal}>Close Modal</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default CourseBlock;

