import React, { useState, useEffect } from "react";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow
  } from "react-google-maps";
import mapStyles from './maps-style';
import './google-maps.styles.scss';

const locationData = [
    {
        "location": "Durham, NC",
        "address": "7001 Fayetteville Road Suite 133", 
        "lat": 35.90065, 
        "long": -78.9396487,
        "website": 'https://thecodewiz.com/durham-nc/',
        "phone": "984-234-5603"
    } 
];   

function Map(props) {
    const [selectedloc, setSelectedLoc] = useState(null);
    //const locationData = props.data;

    useEffect(() => {
        const listener = e => {
        if (e.key === "Escape") {
            setSelectedLoc(null);
        }
        };
        window.addEventListener("keydown", listener);

        return () => {
        window.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <GoogleMap 
            defaultZoom={17}
            defaultCenter={{ lat: locationData[0].lat, lng: locationData[0].long}}
            defaultOptions={{ styles: mapStyles }}
        >
            {locationData.map((cwf) => (
                // console.log(cwf)
                <Marker 
                    key={cwf.location} 
                    position={{
                        lat:cwf.lat, lng: cwf.long
                    }}
                    onClick={() => {
                        setSelectedLoc(cwf);
                    }}
                    // icon={{
                    //     url: `/map-pointer-logo.svg`,
                    //     scaledSize: new window.google.maps.Size(25, 25)
                    // }}
                />
            ))}

            {selectedloc && (
                <InfoWindow
                onCloseClick={() => {
                    setSelectedLoc(null);
                }}
                position={{
                    lat: selectedloc.lat, 
                    lng: selectedloc.long
                }}
                >
                <div>
                    <h2>{selectedloc.location}</h2>
                    <p>{selectedloc.address}</p>
                    <p><a className="map-phone" href={`tel:${selectedloc.phone}`}>{selectedloc.phone}</a></p>
                    <a className="btn orange-button map-website-link" href={selectedloc.website}>VISIT WEBSITE</a>
                </div>
                </InfoWindow>
            )}
        </GoogleMap>

    );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

const GoogleMapDurhamComponent = () => (
    <div className="location-maps">
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAaSnEDJ_IvYbC8oYdnYSAj3RP3PUveUjg`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
);

export default GoogleMapDurhamComponent;