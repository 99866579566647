import React from 'react';
import firebase from '../Firebase/firebase.js';

import Button from '../button/button.component';
import FloatingMenu from '../floating-menu/floating-menu.component';
// import FirstLegoHeaderImg from '../../assets/images/first_lego_hero_image.png';
import FirstLegoHeaderImg from '../../assets/images/FLL&Robotics-orange.png';

import './lego-league.styles.scss';
const couponUrl ="https://getform.io/f/3f2a0e4c-03e3-4c9a-948d-6b79076b5b96";
const grandOpeningUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e";

class LegoLeague extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            formType: props.formType,
            owner: props.owner,
            locationemail: props.franchisee + "@thecodewiz.com"
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        let formHeader = "";
        let formHeaderDesc = "";
        let formUrl ="";

        if(this.props.franchisee){
            this.state.isfranchisee = true; 
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }
        if(this.state.formType == "grandopening"){
            formUrl = grandOpeningUrl;
            formHeader = "Grand Opening Sale!!";
            formHeaderDesc = "Founding families enjoy **31.4159%** off their memberships for the first 6 months!";
        } else if(this.state.formType == "coupon"){
            formUrl = couponUrl;
            formHeader = "We'd Love to Keep You Updated!";
            formHeaderDesc = "We'll send you a coupon for 5% OFF as a Thank You!";
        }
        return (
            <div className="special-page online-coding" id=""> 
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">FIRST LEGO LEAGUE</h2> 
                                <h4 class="header-text cw-dark-blue">Join the mission into the 32nd season of FIRST! Discover your sense of wonder during the FIRST® LEGO® Competition Season!</h4>
                                <h4 class="header-text cw-dark-blue">Teams have started forming! Contact us to sign up. </h4>
                                {/* <Button color="blue" link="https://youtu.be/tlYyUQJX_sw" size="medium">CONTACT US</Button> */}
                                {/* <Button color="blue" link={`mailto:${this.state.data.email}`} size="medium">CONTACT US</Button> */}
                                <Button color="blue" link={this.state.data.parentportal} size="medium">REGISTER FOR FLL TEAM</Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={FirstLegoHeaderImg} alt="lego-league"/> 
                        </div>
                    </div>
                </div>
                <div className="blue_section online_coding_camps_section first_lego_league_blue_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sessions_image_section">
                                    <div className="image">
                                        <h4 className="bungee center">View this year's mission below</h4>
                                        <iframe className="fll-video" src="https://www.youtube.com/embed/QArg43rCMBA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div className="text">
                                        <div className="form-white bg-white">
                                            <div className="cw-coupon-form-header">
                                                <h3 className="bungee cw-dark-blue center">{formHeader}</h3>
                                                <h4 className="center cw-dark-blue">{formHeaderDesc}</h4>
                                            </div>
                                            <form id="cw-contact-form" className="contact-form cw-dark-blue" action={formUrl} method="POST">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputFirst">First Name</label>
                                                        <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputLast">Last Name</label>
                                                        <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputEmail">Email</label>
                                                        <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputPhone">Phone</label>
                                                        <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890"/>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <input type="hidden" id="location" name="location" value={this.state.location}></input>
                                                    <input type="hidden" id="zoho" name="zoho" value={this.state.owner}></input>
                                                    <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                        <div className="form-check">
                                                            <label className="form-check-label" htmlFor="gridCheck">
                                                            {/* By submitting this form, you are consenting to be contacted by Code Wiz and you will be added to their mailing list. */}
                                                            </label>
                                                        </div>
                                                    </div>
                                                {/* <button type="submit">Send</button> */}
                                                <button type="submit" className="btn orange-button" target="">GET OFFER</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="legos_box">
                                    <div className="lego_block">
                                        <h2 className="bungee"><span>first</span>LEGO LEAGUE JR.</h2>
                                        <p>Do you enjoy building with LEGO parts? Having fun with other children? Learning about science and technology?</p>
                                    </div>
                                    
                                    <div className="lego_block">
                                        <h2 className="bungee"><span>first</span>LEGO LEAGUE</h2>
                                        <p>Do you enjoy building with LEGO parts? Having fun with other children? Coming up with soutions to real problems?</p>
                                    </div>
                                    
                                    <div className="lego_block">
                                        <h2 className="bungee"><span>first</span>tech challenge</h2>
                                        <p>Are you up for challenge of designing, building, programming, and operating robots to play a floor game in an alliance format?</p>
                                    </div>
                                    
                                    <div className="lego_block">
                                        <h2 className="bungee"><span>first</span>robotics competition</h2>
                                        <p>Are you up for challenge of designing, building, programming, and operating 120lb. robot to play a field game in an alliance format?</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lego_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="lego_bottom">
                                    <div className="lego_bottom_block">
                                        <h3 className="bungee">FIRST LEGO LEAGUE JR (Ages 6 – 8)</h3>
                                        <p>At Code Wiz, each team will have a maximum of 4 children and will meet 12 times (1 hour per week). This session will culminate in an Expo where all teams get to display their robots and projects!</p>
                                    </div>
                                    
                                    <div className="lego_bottom_block">
                                        <h3 className="bungee">FIRST LEGO LEAGUE (Ages 9 – 14)</h3>
                                        <p>For First Lego League, our teams will have a maximum of 4 children per team and will meet twice a week over 12 – 14 weeks. (3 hours per week)</p>
                                    </div>
                                </div>
                                
                                <Button color="orange" link={this.state.data.parentportal} size="medium">REGISTER FOR FLL TEAM</Button>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default LegoLeague
