import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

import LocationPageImg from '../../assets/images/find-a-location-header-image.png';
import LocationPageMobileImg from '../../assets/images/find-a-location-header-image-1.jpg';

import './locations.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';
import GoogleMapComponent from '../../components/google-map/google-map.component';


class LocationsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            },
            showModal: true
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        let showData = this.state.showModal;
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={LocationPageMobileImg} />
                    <title>{`Coding Schools for Kids | Coding Courses for Kids - ${aboutdata.city} ${aboutdata.state}`}</title>
                    <meta name="description" content="Are you interested in taking a coding class or kids coding classes near you? Click here to see a list of our locations and to find a location near you!"/>
                    <meta name="keywords" content="coding for kids, kids, coding, coding camps"></meta>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="locations-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">FIND A CENTER NEAR ME</h2> 
                                    <h4 className="header-text cw-dark-blue">We make it fun and easy to learn code, wherever you are! Code Wiz offers a range of coding classes, flexible schedules, and expert coaches in convenient locations.</h4>
                                    {/* <Button color="orange" link="https://www.firstlegoleague.org/" size="medium">FIND A CENTER NEAR ME</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">    
                                <img aria-hidden="true" src={LocationPageMobileImg} alt="Location image"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper locations-wrapper">
                        <div className="container find-a-location-container">
                            <div className="row"> 
                                <div className="text abs right col-md-12">
                                    {/* <h3 className="bungee cw-dark-blue">find a code wiz center near you</h3> */}
                                    {/* <input className="location-input" type="text" placeholder="ZIP, POSTAL CODE OR CITY"/> */}
                                </div>
                            </div>
                        </div>
                        <div className="container empty-space-half bw-4"></div>
                        <div className="container empty-space bw-5"></div>
                    </div>
                    {/* <div id="map" className="map"></div> */}
                    {/* <MapContainer /> */}
                    <GoogleMapComponent />
                    <div className="location-links-container container">
                        <div className="row address-boxes">
                            {/* <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Jersey City, NJ</h4>
                                <p>357 3RD STREET </p>
                                <p>JERSEY CITY, NJ 07302</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:201-589-0199">(201)-589-0199</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:jerseycity@thecodewiz.com">jerseycity@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/jerseycity-nj" size="small">VISIT WEBSITE</Button>
                            </div> */}
                        
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Westborough, MA</h4>
                                <p>30 LYMAN ST. </p>
                                <p>WESTBOROUGH, MA 01581</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:508-521-9096">(508)-521-9096</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:westborough@thecodewiz.com">westborough@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/westborough-ma" size="small">VISIT WEBSITE</Button>
                            </div>

                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Arlington, MA</h4>
                                <p>TBD ARLINGTON MA, TBD</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)205-9865</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:arlington@thecodewiz.com">arlington@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/arlington-ma" size="small">VISIT WEBSITE</Button>
                            </div>
                        
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Westford, MA</h4>
                                <p>175 LITTLETON RD. UNIT A </p>
                                <p>WESTFORD, MA 01886</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:978-496-1053">(978)496-1053</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:westford@thecodewiz.com">westford@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/westford-ma" size="small">VISIT WEBSITE</Button>
                            </div>

                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Reading, MA</h4>
                                <p>347 MAIN STREET </p> 
                                <p>READING, MA 01867</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)205-9865</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:reading@thecodewiz.com">reading@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/reading-ma" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Medford, MA</h4>
                                <p>TBD MEDFORD, MA TBD</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)205-9865</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:medford@thecodewiz.com">medford@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/medford-ma" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Nashua, NH</h4>
                                <p></p>
                                <p> NASHUA, NH 03062</p>
                                <p></p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)-205-9865</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:nashua@thecodewiz.com">nashua@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/nashua-nh" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Needham, MA</h4>
                                <p>333 Chestnut St.</p> 
                                <p>NEEDHAM, MA 02492</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:339-970-5077">(339)-777-6090</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:needham@thecodewiz.com">needham@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/needham-ma" size="small">VISIT WEBSITE</Button>
                            </div>

                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Lawrenceville, GA</h4>
                                <p>3320-3370 Sugarloaf Pkwy, Ste G2</p> 
                                <p>LAWRENCEVILLE, GA</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:470-260-1358">(470) 260-1358</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:lawrenceville@thecodewiz.com">Lawrenceville@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/lawrenceville-ga" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">St. Johns, FL</h4>
                                <p>530 State Road 13 Unit #5</p> 
                                <p>FRUIT COVE, FL 32259</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:904-719-7490">(904) 719-7490</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:stjohns@thecodewiz.com">stjohns@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/stjohns-fl" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Tierrasanta, CA</h4>
                                <p>10425 Tierrasanta Blvd. Suite 110</p> 
                                <p>SAN DIEGO, CA 92124</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:858-264-3482">858-264-3482</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:tierrasanta@thecodewiz.com">tierrasanta@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/tierrasanta-ca" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Plano, TX</h4>
                                <p>6905 Coit Road Suite #104</p> 
                                <p>PLANO, TX 75024</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:469-824-3463">(469) 824-3463</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:plano@thecodewiz.com">plano@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/plano-tx" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Oak Forest, TX</h4>
                                <p>2015 W 34th St suite g </p> 
                                <p>HOUSTON, TX 77018</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:832-924-5987">(832) 924-5987</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:oakforest@thecodewiz.com">oakforest@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/houston-tx" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Durham, NC</h4>
                                <p>7001 Fayetteville Rd suite 133</p> 
                                <p>DURHAM, NC 27713</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:984-234-5603">(984) 234-5603</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:durham@thecodewiz.com">durham@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/durham-nc" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">FishHawk, FL</h4>
                                <p>16132 Churchview Dr Suite 105</p> 
                                <p>LITHIA, FL</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:813-820-6583">(813) 820-6583</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:fishhawk@thecodewiz.com">fishhawk@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/fishhawk-fl" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Cypress, TX</h4>
                                <p>16718 House & Hahl Road Suite I</p> 
                                <p>CYPRESS, TX</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:832-653-9079">(832) 653-9079</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:cypress@thecodewiz.com">cypress@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/cypress-tx" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Katy, TX</h4>
                                <p>1315 Grand Parkway S, STE 111</p> 
                                <p>KATY, TX</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:346-483-3736">(346) 483-3736</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:katy@thecodewiz.com">katy@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/katy-tx" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Keller East, TX</h4>
                                <p>790 S Main St, #409</p> 
                                <p>KELLER, TX</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:817-380-1352">(817) 380-1352</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:kellereast@thecodewiz.com">kellereast@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/kellereast-tx" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Quincy, MA</h4>
                                <p>275 Hancock St #206</p> 
                                <p>QUINCY, MA</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:617-934-3669">(617) 934-3669</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:quincy@thecodewiz.com">quincy@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/quincy-ma" size="small">VISIT WEBSITE</Button>
                            </div>
                            {/* <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Carmel, IN</h4>
                                <p>Coming Soon!</p> 
                                <p>CARMEL, IN</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:463-223-4132">Coming Soon</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:carmel@thecodewiz.com">carmel@thecodewiz.com</a></p>
                                {/* <Button color="light-blue" link="/carmel-in" size="small">VISIT WEBSITE</Button>                             
                            </div> */}
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Colleyville, TX</h4>
                                <p>3930 Glade Road, Suite 128</p> 
                                <p>COLLEYVILLE, TX</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:817-696-9794">(817) 696-9794</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:colleyville@thecodewiz.com">colleyville@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/colleyville-tx" size="small">VISIT WEBSITE</Button>
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Rutherford, NJ</h4>
                                <p>310 Union Avenue</p> 
                                <p>RUTHERFORD, NJ</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:201-231-7576">(201) 231-7576</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:rutherford@thecodewiz.com">rutherford@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/rutherford-nj" size="small">VISIT WEBSITE</Button> 
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Owasso, OK</h4>
                                <p>12500 East 86th St N Suite 102</p> 
                                <p>OWASSO, OK</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:918-212-5940"> (918) 212-5940</a></p>
                                <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:owasso@thecodewiz.com">owasso@thecodewiz.com</a></p>
                                <Button color="light-blue" link="/owasso-ok" size="small">VISIT WEBSITE</Button> 
                            </div>
                            <div className="col-md-5 box">
                                <h4 className="cw-dark-blue">Plainsboro, NJ</h4>
                                <p>Coming Soon!</p> 
                                <p>PLAINSBORO, NJ</p>
                                <p><span><i className="fa fa-phone"></i></span> <a href="tel:">Coming Soon</a></p>
                                {/* <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:carmel@thecodewiz.com">carmel@thecodewiz.com</a></p> */}
                                {/* <Button color="light-blue" link="/carmel-in" size="small">VISIT WEBSITE</Button>  */}
                            </div>
                            
                        </div>
                        <br />
                        <br />
                        <h4 className="bungee cw-dark-blue">don't see a code wiz in your location? Would you like to bring one to your location? Click <a className="cw-orange" href="https://codewizfranchise.com/#contact-us-form-franchise/?codewiz-website-location-page">here</a> for more info.</h4>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}
export default LocationsPage;