import React, { useState, useEffect } from "react";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow
  } from "react-google-maps";
import mapStyles from './maps-style';
//import * as locationData from './locations.json';

const locationData = [
    {
        "location": "Arlington MA",
        "address": "",
        "lat": 42.4153739, 
        "long": -71.1564428,
        "website": '/arlington-ma',
        "phone" : "(781)205-9865"
    },
    // {   "location": "Jersey City, NJ",
    //     "address": "357 3rd Street  Jersey City  NJ, 07302",
    //     "lat": 40.723620,
    //     "long": -74.050610,
    //     "website": '/jerseycity-nj',
    //     "phone" : "(201)589-0199"
    // },
    {
        "location": "Reading, MA",
        "address": "347 Main St Reading  MA, 01867", 
        "lat": 42.5257,
        "long": -71.0953,
        "website": '/reading-ma',
        "phone" : "(781)205-9865"
    },
    {
        "location": "Westborough, MA",
        "address": "30 Lyman St Westborough MA, 01581", 
        "lat": 42.2830981,
        "long": -71.6416492,
        "website": '/westborough-ma',
        "phone" : "(508)217-4562"
    },
    {
        "location": "Westford, MA",
        "address": "175 Littleton Road Unit A Westford MA, 01886", 
        "lat": 42.5677717,
        "long": -71.423118,
        "website": '/westford-ma',
        "phone": "(978)496-1053"
    },
    {
        "location": "Medford MA",
        "address": "",
        "lat": 42.4248069, 
        "long": -71.1458345,
        "website": '/medford-ma',
        "phone" : "(781)205-9865"
    },
    {
        "location": "Nashua, NH",
        "address": "505 W Hollis St, Suite 111, Nashua, NH 03062", 
        "lat": 42.746806,
        "long": -71.5026647,
        "website": '/nashua-nh',
        "phone" : "(781)205-9865"
    },
    {
        "location": "Needham, MA",
        "address": "333 Chestnut St.", 
        "lat": 42.2745154,
        "long": -71.2382726,
        "website": '/needham-ma',
        "phone": "(339)777-6090"
    },
    {
        "location": "Lawrenceville, GA",
        "address": "3320-3370 Sugarloaf Pkwy, Ste G2", 
        "lat": 33.9200849,
        "long": -84.0174852,
        "website": '/lawrenceville-ga',
        "phone": "(470) 260-1358"
    },
    {
        "location": "Plano, TX",
        "address": "6905 Coit Rd #104", 
        "lat": 33.0699851,
        "long": -96.7749121,
        "website": '/plano-tx',
        "phone": "(469) 824-3463"
    },
    {
        "location": "St. Johns, FL",
        "address": "530 State Road 13 Unit #5", 
        "lat": 30.1169479,
        "long": -81.6294578,
        "website": '/stjohns-fl',
        "phone": "(904) 719-7490"
    },
    {
        "location": "Tierrasanta, CA",
        "address": "10425 Tierrasanta Blvd. Suite 110", 
        "lat": 32.82286, 
        "long": -117.1031430,
        "website": 'https://thecodewiz.com/tierrasanta-ca/',
        "phone": "858-264-3482"
    },
    {
        "location": "Oak Forest, TX",
        "address": "2015 W 34th Street Suite G", 
        "lat": 29.8167877, 
        "long": -95.4378166,
        "website": '/oakforest-tx',
        "phone": "(832) 924-5987"
    },
    {
        "location": "Durham, NC",
        "address": "7001 Fayetteville Rd #133", 
        "lat": 35.90065, 
        "long": -78.9396487,
        "website": '/durham-nc',
        "phone": "(984) 234-5603"
    },
    {
        "location": "Cypress, TX",
        "address": "16718 House & Hahl Road Suite I", 
        "lat": 29.9619603, 
        "long": -95.7119028,
        "website": '/cypress-tx',
        "phone": "(832) 653-9079"
    },
    // {
    //     "location": "Carmel, IN",
    //     "address": "Coming soon!!", 
    //     "lat": 39.9633059, 
    //     "long": -86.2024241,
    //     // "website": '/carmel-in',
    //     "website": '/code-wiz-locations',
    //     "phone": "TBD"
    // },
    {
        "location": "FishHawk Ranch, FL",
        "address": "16132 Churchview Dr Suite 105", 
        "lat": 27.8421972, 
        "long": -82.2176855,
        // "website": '/tampa-fl',
        "website": '/fishhawk-fl',
        "phone": "(813) 820-6583"
    },
    {
        "location": "Katy, TX",
        "address": "1315 West Grand Parkway S, STE 111", 
        "lat": 29.760938, 
        "long": -95.7749901,
        "website": '/katy-tx',
        "phone": "(346) 483-3736"
    },
    {
        "location": "Keller East, TX",
        "address": "790 S Main St, #409", 
        "lat": 32.9210884, 
        "long": -97.257276,
        "website": '/kellereast-tx',
        "phone": "(817) 380-1352"
    },
    {
        "location": "Colleyville, TX",
        "address": "3930 Glade Road, Suite 128", 
        "lat": 32.8825386,
        "long": -97.1034216,
        "website": '/colleyville-tx',
        "phone": "(817) 696-9794"
    },
    {
        "location": "Rutherford, NJ",
        "address": "310 Union Avenue", 
        "lat": 40.8363898, 
        "long": -74.11326,
        "website": '/rutherford-nj',
        "phone": "(201) 231-7576"
    },
    {
        "location": "Quincy, MA",
        "address": "275 Hancock St #206", 
        "lat": 42.2767806, 
        "long": -71.0296262,
        "website": '/quincy-ma',
        "phone": "(617) 934-3669"
    },
    {
        "location": "Owasso, OK",
        "address": "12500 East 86th St N Suite 102", 
        "lat": 36.2778737, 
        "long": -95.8340482,
        "website": '/owasso-ok',
        "phone": "(918) 212-5940"
    }
    // {
    //     "location": "Chandler, AZ",
    //     "address": "", 
    //     "lat": 42.2588976, 
    //     "long": -71.097896,
    //     "website": '/chandler-az',
    //     "phone": ""
    // }  
    
];   

function Map(props) {
    const [selectedloc, setSelectedLoc] = useState(null);
    //const locationData = props.data;

    useEffect(() => {
        const listener = e => {
        if (e.key === "Escape") {
            setSelectedLoc(null);
        }
        };
        window.addEventListener("keydown", listener);

        return () => {
        window.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <GoogleMap 
            defaultZoom={4} 
            defaultCenter={{ lat: 42.0875908, lng: -90.5051201 }}
            defaultOptions={{ styles: mapStyles }}
        >
            {locationData.map((cwf) => (
                // console.log(cwf)
                <Marker 
                    key={cwf.location} 
                    position={{
                        lat:cwf.lat, lng: cwf.long
                    }}
                    onClick={() => {
                        setSelectedLoc(cwf);
                    }}
                    // icon={{
                    //     url: `/map-pointer-logo.svg`,
                    //     scaledSize: new window.google.maps.Size(25, 25)
                    // }}
                />
            ))}

            {selectedloc && (
                <InfoWindow
                onCloseClick={() => {
                    setSelectedLoc(null);
                }}
                position={{
                    lat: selectedloc.lat, 
                    lng: selectedloc.long
                }}
                >
                <div>
                    <h4>{selectedloc.location}</h4>
                    <p>{selectedloc.address}</p>
                    <p><a className="map-phone" href={`tel:${selectedloc.phone}`}>{selectedloc.phone}</a></p>
                    <a className="btn orange-button map-website-link" href={selectedloc.website}>VISIT WEBSITE</a>
                </div>
                </InfoWindow>
            )}
        </GoogleMap>

    );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

const GoogleMapComponent = () => (
    // <div style={{ width: "100vw", height: "70vh" }}>
    //   <MapWrapped
    //     googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBLnSUy_FY-ctwsRcEudRoPyNZjwDnEym0`}
    //     loadingElement={<div style={{ height: `100%` }} />}
    //     containerElement={<div style={{ height: `100%` }} />}
    //     mapElement={<div style={{ height: `100%` }} />}
    //   />
    // </div>
    <div style={{ width: "100vw", height: "70vh" }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAaSnEDJ_IvYbC8oYdnYSAj3RP3PUveUjg`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
);

export default GoogleMapComponent;